import { httpClient } from "../shared/httpClient";
import { IConfirmUserResponse, IDashboardResponse, IUserQuery, IUsersNewVm, IUsersResponse, IUsersSearchVm, ICreateUserRequest, ICreateUserResponse, IDeleteUserResponse, IDeactivateUserResponse, IEditUserVmResponse, IResultBase, IPasswordResetResult, DomainEvents, IResultWithPayloadBase, IUser, IInternalUserResponse, IUsersNewVmOptions, IActivateUserResponse, BulkAssignModes, IApprovableQueryResultData } from "../shared/models";
import { IUserGroupUpdate } from "./GroupSelectionPanel";

class UserService {
  async getAll(query: IUserQuery) {
    let response = await httpClient.post<IUsersResponse>("/api/users/queries", query);
    return response.data;
  }

  async getSearchVm() {
    let response = await httpClient.post<IUsersSearchVm>("/api/users/vms/search");
    return response.data;
  }

  async getEditVm(userId: string): Promise<IEditUserVmResponse> {
    let response = await httpClient.get<IEditUserVmResponse>(`/api/users/${userId}`);
    return response.data;
  }

  async getNewUserVm(options: IUsersNewVmOptions = null) {
    options = options ?? {};
    let response = await httpClient.post<IUsersNewVm>("/api/users/vms/new", {});
    return response.data;
  }

  async getDashboard() {
    let response = await httpClient.get<IDashboardResponse>("/api/dashboard");
    return response.data;
  }

  async confirmUser(userId: string, comment: string) {
    let response = await httpClient.put<IConfirmUserResponse>(`/api/users/${userId}/confirm`, { userId, comment });
    return response.data;
  }
  async revalidateUser(userId: string) {
    let response = await httpClient.put<IConfirmUserResponse>(`/api/users/${userId}/revalidate`);
    return response.data;
  }

  async deactivateUser(userId: string) {
    let response = await httpClient.put<IDeactivateUserResponse>(`/api/users/${userId}/deactivations`);
    return response.data;
  }

  async activateUser(userId: string) {
    let response = await httpClient.put<IActivateUserResponse>(`/api/users/${userId}/activations`);
    return response.data;
  }

  async deleteUser(userId: string) {
    let response = await httpClient.delete<IDeleteUserResponse>(`/api/users/${userId}`);
    return response.data;
  }

  async updateGroupAssigments(update :IUserGroupUpdate[], bulkAssignMode : BulkAssignModes = BulkAssignModes.None) {
    let response = await httpClient.post<IResultBase>(`/api/v2/users/groups`, {userGroupAssignments: update, bulkAssignMode});
    return response.data;
  }

  async isEmailAvailable(email: string): Promise<boolean> {
    if (!validateEmail(email)) {
      return false;
    }
    let response = await httpClient.post(`/api/users/validation/email`, { email });
    return response.data.isAvailable;
  }

  async createUser(userData: ICreateUserRequest): Promise<ICreateUserResponse> {
    let response = await httpClient.post<ICreateUserResponse>(`/api/users`, userData);
    return response.data;
  }

  async resetPassword(userId: string): Promise<IPasswordResetResult> {
    let response = await httpClient.post<IPasswordResetResult>(`/api/users/${userId}/passwordresets`);
    return response.data;
  }

  async changeAbbreviation(userId: string, abbreviation: string): Promise<IResultBase> {
    let response = await httpClient.post<IResultBase>(`/api/users/${userId}/updates`, toAbbreviationUpdate(userId, abbreviation));
    return response.data;
  }

  async sendInvitation(userId: string): Promise<IResultBase> {
    let response = await httpClient.post<IResultBase>(`/api/users/${userId}/invitations`);
    return response.data;
  }

  async getConfirmationGrants(userId: string): Promise<DomainEvents[]> {
    let response = await httpClient.get<IResultWithPayloadBase<DomainEvents[]>>(`/api/users/${userId}/confirmationgrants`);
    return response.data.data;
  }

  async getApprovableUsersByMe(): Promise<IApprovableQueryResultData> {
    let response = await httpClient.get<IResultWithPayloadBase<IApprovableQueryResultData>>(`/api/me/approvableusers`);
    return response.data.data;
  }

  async getApprovableUsersByMeCount(): Promise<number> {
    let response = await httpClient.get<number>(`/api/me/approvableusers/count`);
    return response.data;
  }

  async getInternalUserData(email: string): Promise<IInternalUserResponse> {
    let response = await httpClient.post(`/api/users/vms/search/internaluser`, { email: email });
    return response.data;
  }
}

function validateEmail(email: string) {
  if (!email) {
    return false;
  }
  var re = /\S+@\S+\.\S+/;
  return re.test(email);
}

export const userService = new UserService();

function toAbbreviationUpdate(userId: string, abbreviation: string) {
  return {
    userId: userId,
    updates: [{
      abbreviation,
      type: "UserAbbreviationUpdate"
    }]
  };
}