import { Icon, MessageBar, MessageBarType } from "@fluentui/react";
import React, { } from "react";
import _ from "lodash";
import classNames from "classnames";
import styles from "./ServiceDisplay.module.css";
import { IGroup } from "../shared/models";
import { useTranslation } from "react-i18next";

export const ServiceDisplay: React.FC<{ groups: IGroup[] }> = ({ groups }) => {
    let [t] = useTranslation();
    let services = evaluateGroups(groups);

    return (
        <div>
            <MessageBar messageBarType={MessageBarType.info}>{t("serviceDisplay.info")}</MessageBar>
            <div style={{ marginTop: "1rem" }} className={styles.container}>
                <ServiceDisplayItem name="Facility Log" iconName="Manufacturing" available={services.facilityLog} />
                <ServiceDisplayItem name="Service Manager" iconName="Telemarketer" available={services.serviceManager} />
                <ServiceDisplayItem name="Service Request" iconName="MailForward" available={services.serviceRequest} />
                <ServiceDisplayItem name="Access Guard" iconName="Lock" available={services.accessGuard} />
                <ServiceDisplayItem name="Documentation" iconName="BookAnswers" available={services.documentation} />
                <ServiceDisplayItem name="Spare Parts" iconName="Robot" available={services.spareParts} />
            </div>
        </div>
    )
}

const ServiceDisplayItem: React.FC<{ name: string, iconName: string, available: boolean }> = ({ name, iconName, available }) => {
    return (<div className={classNames(styles.itemContainer, available && styles.available)}>
        <div className={styles.indicator}>{available && <Icon iconName={"CheckMark"} />} </div>
        <div className={styles.name}>{name}</div>
        <div className={styles.icon}><Icon iconName={iconName} /></div>
    </div>);
}


function evaluateGroups(groups: IGroup[]) {
    let result = {
        facilityLog: false,
        serviceManager: false,
        serviceRequest: false,
        accessGuard: false,
        documentation: false,
        spareParts: false,
    }

    if (!groups || groups.length === 0) {
        return result;
    }

    for (const group of groups) {
        switch (group.displayName) {
            case KnownGroups.CUSTOMER_SUPPORT_READ:
                result.facilityLog = true;
                result.serviceManager = true;
                break;
            case KnownGroups.CUSTOMER_SUPPORT_CONTRIBUTE:
                result.facilityLog = true;
                result.serviceManager = true;
                result.serviceRequest = true;
                break;
            case KnownGroups.CUSTOMER_SUPPORT_ACCESS_GUARD:
                result.accessGuard = true;
                break;
            case KnownGroups.CUSTOMER_SUPPORT_CONFIG_ACCESS_GUARD:
                result.accessGuard = true;
                break;
            case KnownGroups.CUSTOMER_PARTS:
            case KnownGroups.B_PARTS:
                result.spareParts = true;
                break;
            case KnownGroups.CUSTOMER_DOCU:
            case KnownGroups.B_DOCU:
                result.documentation = true;
                break;
            case KnownGroups.CUSTOMER_ADMIN:
                result.facilityLog = true;
                result.serviceManager = true;
                result.serviceRequest = true;
                result.documentation = true;
                result.spareParts = true;
                break;
            case KnownGroups.CUSTOMER_MONITOR:
                result.facilityLog = true;
                result.serviceManager = true;
                result.documentation = true;
                result.spareParts = true;
                break;
        }

    }






    return result;
}

const KnownGroups = {
    CUSTOMER_SUPPORT_READ: "CUSTOMER_SUPPORT_READ",
    CUSTOMER_SUPPORT_CONTRIBUTE: "CUSTOMER_SUPPORT_CONTRIBUTE",
    CUSTOMER_SUPPORT_ACCESS_GUARD: "CUSTOMER_SUPPORT_ACCESS_GUARD",
    CUSTOMER_SUPPORT_CONFIG_ACCESS_GUARD: "CUSTOMER_SUPPORT_CONFIG_ACCESS_GUARD",
    CUSTOMER_PARTS: "CUSTOMER_PARTS",
    CUSTOMER_DOCU: "CUSTOMER_DOCU",
    CUSTOMER_MAINTENANCE_READ: "CUSTOMER_MAINTENANCE_READ",
    CUSTOMER_MAINTENANCE_CONTRIBUTE: "CUSTOMER_MAINTENANCE_CONTRIBUTE",
    CUSTOMER_ADMIN: "CUSTOMER_ADMIN",
    CUSTOMER_MONITOR: "CUSTOMER_MONITOR",
    CUSTOMER_ADMIN_SECURITY_CONFIG: "CUSTOMER_ADMIN_SECURITY_CONFIG",
    B_PARTS: "B_PARTS",
    B_DOCU: "B_DOCU",
}