import { ActionButton, DefaultButton, ITag, Stack } from "@fluentui/react";
import _ from "lodash";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import useSWR from "swr";
import { Card } from "../components/Card";
import { getFunctionalPlaceTags, getGroupTags, getUserTags } from "../shared/CommonUi";
import { ContentWrapper } from "../shared/ContentWrapper";
import { CustomPicker } from "../shared/CustomPicker";
import { ProgressNotification } from "../shared/ProgressNotification";
import { getPortalPermissions } from "../shared/authService";
import { BulkAssignModes, createEmptyAllowedGroups, getForGroupDisplay } from "../shared/models";
import { GroupSelectionPanel, IUserGroupUpdate } from "../users/GroupSelectionPanel";
import { userService } from "../users/userServices";
import styles from './PermissionsBulkAssign.module.css';
import { permissionsService } from "./permissionsService";
import { GroupDisplay } from "../users/GroupDisplay";
export const PermissionsBulkAssign: React.FC<{}> = () => {
    const [t] = useTranslation();
    let { data: vm, mutate } = useSWR(`/api/v2/permissions/bulk`, () => permissionsService.getBulkAssignOptions());
    let permissions = useMemo(() => getPortalPermissions(), []);
    let userTags = useMemo(() => getUserTags(vm?.users), [vm]);
    const [panelVisibleForId, setPanelVisibleForId] = useState<number>(-1);
    const [selectedGroups, setSelectedGroups] = useState<{ [key: string]: string[] }>({});
    const [selectedUsers, setSelectedUsers] = useState<{ [key: string]: ITag[] }>({});
    const [selectedFps, setSelectedFps] = useState<{ [key: string]: ITag[] }>({});
    
    const emptyAllowedGroups = useMemo(() => createEmptyAllowedGroups(),[]);
    const clearSelections = () => {
        setSelectedFps({});
        setSelectedUsers({});
        setSelectedGroups({});
    }
    const collectAssignments = (idx: number | string): IUserGroupUpdate[] => {
        const update = [] as IUserGroupUpdate[];
        selectedUsers[idx].forEach(u => {

            const fpDic: { [fpId: string]: string[] } = {};
            selectedFps[idx].forEach(fp => {
                fpDic[fp.key] = selectedGroups[idx];
            });

            update.push({
                userId: u.key as string,
                commonGroupIds: [],
                functionalPlaceGroupIds: fpDic
            });
        });
        return update;
    }
    const assignGroups = async (idx: number | string): Promise<void> => {
        let notification = ProgressNotification.start(t("editUser.infoMessages.groupAssignmentsUpdating"));
        const updates = collectAssignments(idx);
        await userService.updateGroupAssigments(updates, BulkAssignModes.Assign);
        notification.success(t("editUser.infoMessages.groupAssignmentsUpdateSucceeded"));
        clearSelections();
    }
    const removeGroups = async (idx: number | string): Promise<void> => {
        let notification = ProgressNotification.start(t("editUser.infoMessages.groupAssignmentsUpdating"));

        const updates = collectAssignments(idx);
        await userService.updateGroupAssigments(updates, BulkAssignModes.Remove);
        notification.success(t("editUser.infoMessages.groupAssignmentsUpdateSucceeded"));
        clearSelections();

    }


    const nullOrEmpty = (value : null | undefined | any[] ) => {
        if(value == null ||value == undefined) return true;
        return value?.length == 0;
    }
    const anyInputNullOrEmpty = (idx:number) : boolean => nullOrEmpty(selectedFps[idx]) || nullOrEmpty(selectedGroups[idx]) || nullOrEmpty(selectedUsers[idx]);

    

    return vm ? (
        <ContentWrapper>
            <h1>{t("bulkAssign.title")}</h1>

            {vm.options.map((option, idx) => <div>
                <Card className={styles.assignmentCard}>
                    <div>
                        {/* <p>Access Level - <b>{option.contractType}</b></p>
                        <p>Assign Groups:</p> */}
                        <Stack>
                            <Stack.Item>
                                <DefaultButton onClick={() => setPanelVisibleForId(idx)}>{t("bulkAssign.select")}</DefaultButton>
                                <GroupDisplay groups={getForGroupDisplay(vm?.options[idx].allowedGroups,selectedGroups[idx]) ?? []} />
                            </Stack.Item>
                            <Stack.Item>
                                <CustomPicker label={t("common.users")} tags={userTags} value={selectedUsers[idx] ?? []} onSelectItems={items => {
                                    var clone = _.cloneDeep(selectedUsers);
                                    clone[idx] = items;
                                    setSelectedUsers(clone);
                                }}
                                />
                            </Stack.Item>
                            <Stack.Item>
                                <CustomPicker label={t("customers.tableHeaders.functionalPlaces")} tags={getFunctionalPlaceTags(option.functionalPlaces)} value={selectedFps[idx] ?? []} onSelectItems={items => {
                                    var clone = _.cloneDeep(selectedFps);
                                    clone[idx] = items;
                                    setSelectedFps(clone);
                                }}
                                />
                            </Stack.Item>
                        </Stack>
                        <Stack horizontal >
                            <Stack.Item>
                                <DefaultButton disabled={anyInputNullOrEmpty(idx)} style={{ marginTop: "1rem", marginRight: "1rem" }} onClick={() => { assignGroups(idx) }}>{t("bulkAssign.assign")}</DefaultButton>
                            </Stack.Item>
                            <Stack.Item>
                                <DefaultButton disabled={anyInputNullOrEmpty(idx)} style={{ marginTop: "1rem" }} onClick={() => { removeGroups(idx) }}>{t("bulkAssign.remove")}</DefaultButton>
                            </Stack.Item>
                        </Stack>

                    </div>
                </Card>
                <GroupSelectionPanel
                    availableGroups={panelVisibleForId === -1 ? emptyAllowedGroups : vm.options[idx].allowedGroups}
                    defaultSelectedGroups={getForGroupDisplay(vm?.options[idx].allowedGroups,selectedGroups[idx]) ?? []}
                    isVisible={panelVisibleForId !== -1}
                    onDismiss={() => setPanelVisibleForId(-1)}
                    onChange={items => {
                        var clone = _.cloneDeep(selectedGroups);
                        clone[idx] = items[0].commonGroupIds;
                        setSelectedGroups(clone);
                        setPanelVisibleForId(-1);
                    }}
                    functionalPlacePermissions={[]}
                    userId={""}
                />
            </div>
            )}
        </ContentWrapper>
    ) :
        <ContentWrapper><div>Loading...</div ></ContentWrapper>


}






