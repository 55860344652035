import { HubConnection, HubConnectionBuilder } from "@microsoft/signalr";
import { concat, debounceTime, filter, map, mergeMap, of, Subject } from "rxjs";
import { userService } from "../users/userServices";
class NotificationService {

    private notificationHubConnection: HubConnection;
    private subject: Subject<NotificationEvent> = new Subject<NotificationEvent>();

    async initialize() {

        this.notificationHubConnection = new HubConnectionBuilder()
            .withUrl("/hubs/notificationHub")
            .withAutomaticReconnect()
            .build();

        this.notificationHubConnection.on("UserUpdated", () => {
            this.subject.next(new UserChanged());
        });

        this.notificationHubConnection.on("ActivitiesUpdated", () => {
            this.subject.next(new ActivitiesUpdated());
        });

        this.notificationHubConnection.on("SpecificUserUpdated", (userId) => {
            this.subject.next(new SpecificUserUpdated(userId));
        });
        this.notificationHubConnection.onclose(() => {
            console.log("Connection Closed");
        });
        document.addEventListener("visibilitychange", () => {
            if (!document.hidden && this.notificationHubConnection.state === "Disconnected") {
                this.startConnection();
            }
        });

        window.addEventListener('online', () => this.startConnection());


        await this.startConnection();
    }

    private async startConnection() {
        try {
            if (this.notificationHubConnection.state === "Disconnected") {
                await this.notificationHubConnection.start();
                console.log("SignalR started");
            }
        }
        catch (e) {
            console.log(e);
            setTimeout(() => this.startConnection(), 5000);
        }
    }

    public getActivityUpdates() {
        return this.subject.pipe(filter(ev => ev instanceof ActivitiesUpdated));
    }

    public getUserUpdates() {
        return this.subject.pipe(filter(ev => ev instanceof UserChanged));
    }
    public getSpecificUserUpdates(userId:string) {
        return this.subject.pipe(filter(ev => ev instanceof SpecificUserUpdated && ev.userId === userId));
    }
    public getOpenApprovalUpdates() {
        let $debouncedEvents = this.subject.pipe(
            filter(ev => ev instanceof UserChanged),
            debounceTime(500),
            map(_ => 1));

        let $approvalUpdates = concat(of(1), $debouncedEvents).pipe(
            mergeMap(async _ => await userService.getApprovableUsersByMeCount())
        );

        return $approvalUpdates;
    }
}

export const notificationService = new NotificationService();

class NotificationEvent { }
export class UserChanged extends NotificationEvent { }
export class ActivitiesUpdated extends NotificationEvent { }
export class SpecificUserUpdated extends NotificationEvent {
    constructor(userId:string){
        super()
        this.userId = userId
    }
    public userId:string;
 }