import React from "react";
import { IUser } from "../shared/models";
import { useTranslation } from "react-i18next";
import { UserInfo } from "../shared/UserInfo";
import { DefaultButton, IStackTokens, MessageBar, Panel, PanelType, PrimaryButton, Stack } from "@fluentui/react";
import { ProgressNotification } from "./ProgressNotification";
import { userService } from "../users/userServices";

const defaultTokens: IStackTokens = { childrenGap: 20 };

export const DeactivateUserPanel: React.FC<{ isVisible: boolean, deactivateUser: IUser, onDismiss: () => void, onActionStarting: () => void, onActionCompleted?: () => void }> = ({ isVisible, deactivateUser, onDismiss, onActionStarting, onActionCompleted }) => {
    let [t] = useTranslation();
    let onConfirmUser = async (user: IUser) => {
        let formatData = { name: user.displayName };
        let notification = ProgressNotification.start(t("deactivateUserPanel.notifications.inProgress", formatData));
        onActionStarting();
        let result = await userService.deactivateUser(user.id);
        if (result.success) {
            notification.success(t("deactivateUserPanel.notifications.success", formatData));
        }
        else {
            notification.error(t("deactivateUserPanel.notifications.error", formatData));
        }
        if (onActionCompleted) {
            onActionCompleted();
        }
    };

    return (<Panel
        type={PanelType.medium}
        isLightDismiss
        isOpen={isVisible}
        onDismiss={onDismiss}
        closeButtonAriaLabel={t("common.close")}
        headerText={t("deactivateUserPanel.headerText")}
    >
        <p>{t("deactivateUserPanel.confirmText")}</p>
        <UserInfo style={{ margin: "1rem 0" }} user={deactivateUser} />
        <MessageBar>{t("deactivateUserPanel.confirmDescription")}</MessageBar>
        <Stack style={{ marginTop: "1rem", marginBottom: "1rem" }} horizontal tokens={defaultTokens}>
            <PrimaryButton onClick={() => onConfirmUser(deactivateUser)} >{t("deactivateUserPanel.confirm")}</PrimaryButton>
            <DefaultButton onClick={onDismiss}>{t("deactivateUserPanel.cancel")}</DefaultButton>
        </Stack>
    </Panel>);
}