import React, { } from "react";
import { IUser } from "../shared/models";
import { useTranslation } from "react-i18next";
import { UserInfo } from "../shared/UserInfo";
import { DefaultButton, IStackTokens, MessageBar, MessageBarType, Panel, PanelType, PrimaryButton, Stack } from "@fluentui/react";
import { ProgressNotification } from "./ProgressNotification";
import { userService } from "../users/userServices";

const defaultTokens: IStackTokens = { childrenGap: 20 };

export const DeleteUserPanel: React.FC<{ isVisible: boolean, deleteUser: IUser, onDismiss: () => void, onActionStarting: () => void, onActionCompleted?: () => void }> = ({ isVisible, deleteUser, onDismiss, onActionStarting, onActionCompleted }) => {
    let [t] = useTranslation();
    let onDeleteUser = async (user: IUser) => {
        let formatData = { name: user.displayName };
        let notification = ProgressNotification.start(t("deleteUserPanel.notifications.inProgress", formatData));
        onActionStarting();
        let result = await userService.deleteUser(user.id);

        if (result.success) {
            notification.success(t("deleteUserPanel.notifications.success", formatData));
        }
        else {
            notification.error(t("deleteUserPanel.notifications.error", formatData));
        }
        if (onActionCompleted) {
            onActionCompleted();
        }
    }

    return (<Panel
        type={PanelType.medium}
        isLightDismiss
        isOpen={isVisible}
        onDismiss={onDismiss}
        closeButtonAriaLabel={t("common.close")}
        headerText={t("deleteUserPanel.headerText")}
    >
        <p>{t("deleteUserPanel.confirmText")}</p>
        <UserInfo style={{ margin: "1rem 0" }} user={deleteUser} />
        <MessageBar messageBarType={MessageBarType.severeWarning}>{t("deleteUserPanel.confirmDescription")}</MessageBar>
        <Stack style={{ marginTop: "1rem", marginBottom: "1rem" }} horizontal tokens={defaultTokens}>
            <PrimaryButton onClick={() => onDeleteUser(deleteUser)} >{t("deleteUserPanel.confirm")}</PrimaryButton>
            <DefaultButton onClick={onDismiss}>{t("deleteUserPanel.cancel")}</DefaultButton>
        </Stack>
    </Panel>);
}