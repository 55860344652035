import { Configuration, PopupRequest } from "@azure/msal-browser";
import { IConfig } from "./models";


export function getMsalConfig(config: IConfig): Configuration {
    let { b2cPolicies } = config;
    return {
        auth: {
            clientId: config.client.clientId,
            authority: b2cPolicies.authorities.signUpSignIn.authority,
            redirectUri: getRedirectUrl(),
            postLogoutRedirectUri: "/",
            knownAuthorities: [b2cPolicies.authorityDomain]
        },
        cache: {
            cacheLocation: "sessionStorage",
            storeAuthStateInCookie: true,
        }
    };
}

export const getLoginRequest: (config: IConfig) => PopupRequest = (config) => {
    return ({
        scopes: config.client.scopes ?? ["openid", "offline_access"]
    });
};

export const getApiLoginRequest: (config: IConfig) => PopupRequest = (config) => {
    return ({
        scopes: config.client.scopes ?? ["openid", "offline_access"]
    });
};

function getRedirectUrl() {
    return `${window.location.protocol}//${window.location.hostname}${window.location.port ? ":" + window.location.port : ""}/`;
}