import { Icon, MessageBar, MessageBarType } from "@fluentui/react";
import React, { } from "react";
import _ from "lodash";
import classNames from "classnames";
import styles from "./GroupDisplay.module.css";
import { IGroup } from "../shared/models";
import { groupDescription } from "../shared/CommonUi";
import { useTranslation } from "react-i18next";


export const GroupDisplay: React.FC<{ groups: IGroup[] }> = ({ groups }) => {

    return (<div className={styles.container}>
        {groups.map(g => <GroupItem key={g.id} group={g} />)}
    </div>);
}

const GroupItem: React.FC<{ group: IGroup }> = ({ group }) => {
    let [t] = useTranslation();
    return (<div className={styles.itemContainer}>
        <div className={styles.icon}>
            <Icon iconName="Group" />
        </div>
        <div className={styles.nameContainer}>
            <div className={styles.name}>
                {group.displayName}
            </div>
            <div className={styles.description}>
                {groupDescription(t, group)}
            </div>

        </div>
    </div>);
}