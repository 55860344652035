import { DefaultButton, Panel, PanelType, PrimaryButton, Stack } from "@fluentui/react";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IAllowedGroups, IFunctionalPlaceVm, IGroup } from "../shared/models";
import { GroupSelection } from "./GroupSelection";
import { ServiceDisplay } from "./ServiceDisplay";

import { Card } from "../components/Card";
import { defaultTokens, ErrorLabel, formatFunctionalPlace } from "../shared/CommonUi";
import styles from "./GroupSelectionPanel.module.css";
import { getConfig } from "../shared/authService";
import { FeatureLocked } from "../shared/FeatureLocked";

export const GroupSelectionPanel: React.FC<IGroupSelectionPanelProps> = ({ availableGroups, defaultSelectedGroups, isVisible, onDismiss, onChange, functionalPlacePermissions, userId }) => {
    const config = getConfig();

    let [selectedGroups, setSelectedGroups] = useState(defaultSelectedGroups ?? []);
    let [t] = useTranslation();
    useEffect(() => setSelectedGroups(defaultSelectedGroups), [defaultSelectedGroups, isVisible]);
    useEffect(() => setFpGroups(getDefaultSelected(functionalPlacePermissions)), [functionalPlacePermissions, isVisible]);


    const getDefaultSelected = (vms?: IFunctionalPlaceVm[]): { [key: string]: IGroup[] } => {
        const result = {} as { [key: string]: IGroup[] }
        if (vms == null) return result;
        vms.forEach(vm => { result[vm.functionalPlace.id] = vm.permissions })
        return result;
    }

    let [fpGroups, setFpGroups] = useState<{ [key: string]: IGroup[] }>({});

    let errorMessage = selectedGroups.length === 0 && Object.values(fpGroups).every(group => group.length === 0) ? t("validation.groupsRequired") : null;

    let renderFooter = useCallback(() => {

        const toDto = (groups: { [key: string]: IGroup[] }) => {

            const res = {} as { [key: string]: string[] }
            for (let x in groups) {
                res[x] = groups[x].map(g => g.id)
            }
            return res;
        }


        return (
            <div className={styles.actionsContainer}>
                <div className={styles.spacer}></div>
                <Stack horizontal tokens={defaultTokens}>
                    <PrimaryButton disabled={!!errorMessage} onClick={() => onChange([{
                        userId: userId,
                        commonGroupIds: selectedGroups.map(g => g.id),
                        functionalPlaceGroupIds: toDto(fpGroups)

                    }])}>{t("common.ok")}</PrimaryButton> <DefaultButton onClick={onDismiss}>{t("common.cancel")}</DefaultButton>
                </Stack>
            </div>);
    }, [onDismiss, errorMessage, selectedGroups, fpGroups]);

    return (<Panel isOpen={isVisible} type={PanelType.large} isLightDismiss onDismiss={onDismiss} headerText={t("groupSelectionPanel.headerText")} isFooterAtBottom onRenderFooterContent={renderFooter}>
        <Stack tokens={defaultTokens}>
            <div></div>
            <Card title={t("common.groups")}>
                <ErrorLabel message={errorMessage} />
                <GroupSelection availableGroups={availableGroups} selectedGroups={selectedGroups} onSelectGroups={(groups) => { setSelectedGroups(groups) }} />
            </Card>
            <FeatureLocked config={config} feature="enableExtendedPermissionModel">
                {functionalPlacePermissions.length > 0 &&
                    <Card title={t("groupSelectionPanel.functionalPlaces")} collapsable>
                        {functionalPlacePermissions.map(f => {
                            return <Card collapsable title={formatFunctionalPlace(f.functionalPlace)} defaultOpen={false}>
                                <GroupSelection availableGroups={f.allowedGroups} selectedGroups={fpGroups[f.functionalPlace.id]}
                                    onSelectGroups={(groups) => {
                                        let clone = { ...fpGroups };
                                        clone[f.functionalPlace.id] = groups
                                        setFpGroups(clone)
                                    }
                                    } />
                            </Card>
                        }
                        )}
                    </Card>
                }
            </FeatureLocked>
            <Card title={t("common.services")}>
                <ServiceDisplay groups={selectedGroups} />
            </Card>
        </Stack>
    </Panel>)
}
interface IGroupSelectionPanelProps {
    isVisible: boolean,
    availableGroups: IAllowedGroups,
    defaultSelectedGroups: IGroup[],

    functionalPlacePermissions: IFunctionalPlaceVm[]
    userId: string;
    onChange: (groupUpdate: IUserGroupUpdate[]) => void | Promise<void>
    onDismiss: () => void,
}
export interface IUserGroupUpdate {
    userId: string,
    commonGroupIds: string[]
    functionalPlaceGroupIds: { [fpId: string]: string[] }
}