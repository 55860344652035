import React from "react";
import classNames from "classnames";

import styles from "./Card.module.css";
import { ActionButton, Icon, IconButton } from "@fluentui/react";
import { useToggle } from "react-use";

export const Card: React.FC<ICardProps>
    = ({ title, className, contentClassName, children, margin, actionProps, collapsable = false, defaultOpen = true }) => {
        const [open, toogle] = useToggle(defaultOpen);

        return <div style={{ margin }} className={classNames(styles.card, className)}>
            {title && (<div className={styles.header}>
                {title}
                {actionProps && <ActionButton iconProps={{ iconName: actionProps.iconName }} onClick={actionProps.onClick} />}
                {collapsable && <IconButton onClick={toogle} > <Icon className={open ? styles.rotateDown : styles.rotateRight} iconName={open ? "ChevronDown" : "ChevronRight"} /></IconButton>}
            </div>)}
            <div className={classNames(styles.content, contentClassName, collapsable ? open ? styles.collapsible : styles.collapsed : "")}>
                {children}
            </div>
        </div>;
    };

export interface ICardProps {
    title?: string,
    className?: string,
    contentClassName?: string,
    margin?: string,
    actionProps?: { iconName: string, onClick: () => void },
    children: React.ReactNode,
    collapsable?: boolean,
    defaultOpen?: boolean,
}
