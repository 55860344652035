import { Breadcrumb, CommandBar, DefaultButton, Dropdown, IBreadcrumbItem, IColumn, ICommandBarItemProps, Icon, IContextualMenuItem, IContextualMenuProps, IDropdownOption, IStackTokens, ITag, precisionRound, Stack, TextField } from "@fluentui/react";
import { TFunction } from "i18next";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import useSWR from "swr";
import { Card } from "../components/Card";
import { emptyUserQuery, IPortalPermissions, IUser, IUserQuery, IUsersResponse, UserSortableProperties, UserType } from "../shared/models";
import { userService } from "./userServices";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { History } from "history";
import { canBeConfirmedByCurrentUser, getPortalPermissions } from "../shared/authService";
import _, { debounce } from "lodash";
import { CustomPicker } from "../shared/CustomPicker";
import { DataList } from "../shared/DataList";
import { addIcon, confirmIcon, deactivateIcon, deleteIcon, getCustomerTags, getDefaultUserColumns, getGroupTags, getNewSorting, getUserTypeTags, resetFilter, StatusIconOptions } from "../shared/CommonUi";
import { ConfirmUserPanel } from "../shared/ConfirmUserPanel";
import { DeleteUserPanel } from "../shared/DeleteUserPanel";
import { DeactivateUserPanel } from "../shared/DeactivateUserPanel";
import styles from "./Users.module.css";
import commonStyles from "../shared/common.module.css";
import { notificationService } from "../shared/notificationService";

const defaultTokens: IStackTokens = { childrenGap: 20 };
const cardTokens: IStackTokens = { childrenGap: 50 };
const editIcon = { iconName: "Edit" };

const executeSearch = debounce(async (query: IUserQuery, beforeExecute: () => void, callback: (response: IUsersResponse) => void) => {
    beforeExecute();
    let result = await userService.getAll(query);
    callback(result);
}, 500);

export const Users: React.FC = () => {
    let [t] = useTranslation();
    let navigate = useNavigate();
    let permissions = useMemo(() => getPortalPermissions(), []);
    let [confirmUser, setConfirmUser] = useState<IUser>();
    let [deleteUser, setDeleteUser] = useState<IUser>();
    let [deactivateUser, setDeactivateUser] = useState<IUser>();
    let [query, setQuery] = useState(emptyUserQuery());
    let [selectedGroups, setSelectedGroups] = useState<ITag[]>();
    let [selectedCustomers, setSelectedCustomers] = useState<ITag[]>();
    let [selectedUserTypes, setSelectedUserTypes] = useState<ITag[]>();
    let [loading, setLoading] = useState<boolean>(false);

    let queryWith = useCallback((queryPart?: Partial<IUserQuery>): void => {
        let newQuery = Object.assign({}, query, queryPart ?? {});
        setQuery(newQuery);
    }, [query])
    let { data: searchVm } = useSWR("/users/vms/search", () => userService.getSearchVm());

    let [usersResponse, setUsersResponse] = useState<IUsersResponse>(null);
    let allowedGroupTags = useMemo(() => getGroupTags(searchVm?.allowedGroups), [searchVm]);
    let allowedCustomerTags = useMemo(() => getCustomerTags(searchVm?.allowedCustomers), [searchVm]);
    let allowedUserTypesTags = useMemo(() => getUserTypeTags(searchVm?.allowedUserTypes, t), [searchVm]);

    let statusOptions: IDropdownOption[] = useMemo(() => {
        return [
            { key: "all", text: t("common.all"), data: { icon: "ViewAll" } },
            { key: "confirmed", text: t("common.confirmed"), data: { icon: StatusIconOptions.confirmed.name, iconColor: StatusIconOptions.confirmed.color } },
            { key: "unconfirmed", text: t("common.unconfirmed"), data: { icon: StatusIconOptions.unconfirmed.name, iconColor: StatusIconOptions.unconfirmed.color } },
            { key: "deactivated", text: t("common.deactivated"), data: { icon: StatusIconOptions.deactivated.name, iconColor: StatusIconOptions.deactivated.color } },
        ];
    }, [t])

    useEffect(() => {
        let subscription = notificationService.getUserUpdates().subscribe(() => queryWith());
        return () => subscription.unsubscribe();
    }, []);

    useEffect(() => {
        executeSearch(
            query,
            () => { setLoading(true) },
            response => {
                setUsersResponse(response);
                setLoading(false)
            });
    }, [query]);

    let usersColumns = useMemo(() => getUsersColumns(t, permissions, (action, user) => {
        switch (action) {
            case "confirm":
                setConfirmUser(user);
                break;
            case "delete":
                setDeleteUser(user);
                break;
            case "deactivate":
                setDeactivateUser(user);
                break;
            case "edit":
                navigate(`/users/${user.id}`);
                break;
            default:
                console.log(`${action} on ${user.displayName} has no handler.`)
                break;
        }
    }, query, queryWith), [t, permissions, query, queryWith, navigate]);

    let commands = useMemo(() => getCommands(t, navigate, permissions, () => {
        setSelectedGroups([]);
        setSelectedCustomers([]);
        setQuery(emptyUserQuery());
        setSelectedUserTypes([]);
    }), [t, navigate, permissions, setQuery]);

    let breadcrumbs = useMemo(() => getBreadcrumbs(t), [t]);

    return (
        <div className={styles.content}>
            <Breadcrumb className={commonStyles.breadcrumb} style={{ marginBottom: 20 }} items={breadcrumbs} />
            <Card className={styles.commandCard}>
                <CommandBar items={commands}></CommandBar>
            </Card>

            <Card className={styles.filterCard} contentClassName={styles.filterCard_content}>
                <TextField label={t("users.filters.text")} onChange={(ev, text) => queryWith({ text })} value={query.text} disabled={loading} />

                {searchVm?.canFilterByGroups && (<div style={{ gridRow: 2 }}>
                    <CustomPicker disabled={loading} label={t("users.filters.groups")} tags={allowedGroupTags} value={selectedGroups} onSelectItems={items => {
                        setSelectedGroups(items);
                        queryWith({ groupIds: items.map(i => i.key as string) });

                    }} />
                </div>)}
                {searchVm?.canFilterByCustomers && (<div style={{ gridRow: 2 }}>
                    <CustomPicker disabled={loading} label={t("users.filters.customers")} tags={allowedCustomerTags} value={selectedCustomers} onSelectItems={items => {
                        setSelectedCustomers(items);
                        queryWith({ customerIds: items.map(i => i.key as string) });
                    }} />
                </div>)}
                <div style={{ gridRow: 3 }}>
                    <Dropdown disabled={loading} label={t("common.status")} options={statusOptions} onRenderTitle={onRenderTitle} onRenderOption={onRenderOption} selectedKey={query.status} onChange={(_, option) => queryWith({ status: option?.key.toString() })} />
                </div>
                {searchVm?.canFilterByUserTypes && (<div style={{ gridRow: 3 }}>
                    <CustomPicker disabled={loading} limit={1} label={t("defaultUserTable.tableHeaders.userType")} tags={allowedUserTypesTags} value={selectedUserTypes} onSelectItems={items => {
                        setSelectedUserTypes(items);
                        queryWith({ userType: (items && items.length >= 1 && items[0].key as UserType) || null });
                    }} />
                </div>)}


            </Card>
            <Card title={t("common.users")} className={styles.resultCard}>
                <DataList columns={usersColumns} items={usersResponse?.users} disabled={loading}/>
                <ConfirmUserPanel isVisible={confirmUser != null} confirmUser={confirmUser} onDismiss={() => setConfirmUser(null)} onActionStarting={() => setConfirmUser(null)} onActionCompleted={() => queryWith({})} />
                <DeleteUserPanel isVisible={deleteUser != null} deleteUser={deleteUser} onDismiss={() => setDeleteUser(null)} onActionStarting={() => setDeleteUser(null)} onActionCompleted={() => queryWith({})} />
                <DeactivateUserPanel isVisible={deactivateUser != null} deactivateUser={deactivateUser} onDismiss={() => setDeactivateUser(null)} onActionStarting={() => setDeactivateUser(null)} onActionCompleted={() => queryWith({})} />
            </Card>
        </div>
    );
};

const iconStyles = { marginRight: "8px" }
const onRenderTitle = (options: IDropdownOption[]): JSX.Element => {
    const option = options[0];
    let styles = Object.assign({}, iconStyles, { color: option?.data?.iconColor });
    return (
        <Stack horizontal verticalAlign={"center"}>
            {option.data && option.data.icon && (
                <Icon style={styles} iconName={option.data.icon} aria-hidden="true" title={option.data.icon} />
            )}
            <span>{option.text}</span>
        </Stack>
    );
};

const onRenderOption = (option: IDropdownOption): JSX.Element => {
    let styles = Object.assign({}, iconStyles, { color: option?.data?.iconColor });

    return (
        <Stack horizontal verticalAlign={"center"}>
            {option.data && option.data.icon && (
                <Icon style={styles} iconName={option.data.icon} aria-hidden="true" title={option.data.icon} />
            )}
            <span>{option.text}</span>
        </Stack>
    );
};

function getUsersColumns(t: TFunction, permissions: IPortalPermissions, onClick: (action: string, user: IUser) => void, query: IUserQuery, queryWith: (queryPart?: Partial<IUserQuery>) => void): IColumn[] {

    let sortableColumns: UserSortableProperties[] = ["CustomerName", "DisplayName", "Mail"];
    let userColumns = getDefaultUserColumns(t, permissions);
    userColumns.forEach(c => {
        if (sortableColumns.includes(c.key as any)) {
            c.isSorted = query.sorting.property === c.key;
            c.isSortedDescending = query.sorting.isDescending;
            c.onColumnClick = (_, c) => queryWith({ sorting: getNewSorting(query.sorting, c) })
        }
    })

    return [
        ...userColumns,
        {
            key: "actions",
            name: t("defaultUserTable.tableHeaders.actions"),
            minWidth: 220,
            onRender: (item: IUser) => (
                <Stack horizontal tokens={defaultTokens}>
                    <DefaultButton iconProps={editIcon} onClick={() => { onClick("edit", item) }} split menuProps={getUserMenuProps(t, item, permissions, action => onClick(action, item))}>{permissions.editUser ? t("common.edit") : t("common.details")}</DefaultButton>
                </Stack>
            ),
        },
    ];
}

function getUserMenuProps(t: TFunction, user: IUser, permissions: IPortalPermissions, onClick: (action: string) => void) {

    let items: IContextualMenuItem[] = [];

    if (canBeConfirmedByCurrentUser(user, permissions)) {
        items.push({
            key: "confirm",
            text: t("common.confirm"),
            iconProps: confirmIcon,
            onClick: () => onClick("confirm")
        })
    }

    if (!user.isDeactivated && permissions.deactivateUser) {
        items.push({
            key: "deactivate",
            text: t("common.deactivate"),
            iconProps: deactivateIcon,
            onClick: () => onClick("deactivate")
        })
    }

    if (permissions.deleteUser) {
        items.push({
            key: "delete",
            text: t("common.delete"),
            iconProps: deleteIcon,
            onClick: () => onClick("delete")
        });
    }

    let menuProps: IContextualMenuProps = {
        items: items,
    };

    return menuProps;
}

function getBreadcrumbs(t: TFunction): IBreadcrumbItem[] {
    return [
        {
            text: t("users.breadcrumb.1"),
            key: "1"
        }
    ];
}

function getCommands(t: TFunction, navigate: NavigateFunction, permissions: IPortalPermissions, onResetFilter: () => void): ICommandBarItemProps[] {
    let result: ICommandBarItemProps[] = [];
    result.push({
        key: "reset",
        text: t("users.commands.resetSearch"),
        iconProps: resetFilter,
        onClick: onResetFilter

    });
    if (permissions.createInternalUser) {
        result.push({
            key: "newInternalUser",
            text: t("users.commands.newInternalUser"),
            iconProps: addIcon,
            onClick: () => navigate("/users/newinternal"),
        });
    }
    if (permissions.createAdminUser) {
        result.push({
            key: "newAdminUser",
            text: t("users.commands.newAdminUser"),
            iconProps: addIcon,
            onClick: () => navigate("/users/new?isCustomerAdmin=true"),
        });
    }

    if (permissions.createUser) {
        result.push({
            key: "newUser",
            text: t("users.commands.newUser"),
            iconProps: addIcon,
            onClick: () => navigate("/users/new"),
        });
    }

    return result;
}