import { Breadcrumb, CommandBar, DefaultButton, IBreadcrumbItem, IColumn, ICommandBarItemProps, ITag, Stack, TextField } from "@fluentui/react";
import { TFunction } from "i18next";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import useSWR from "swr";
import { Card } from "../components/Card";
import { DomainEvents, emptyActivityQuery, IActivityQuery, IActivityResponse, IActivitySearchVmResponse, IPortalPermissions, IUserDomainEvent } from "../shared/models";
import { useNavigate } from "react-router-dom";
import { getPortalPermissions } from "../shared/authService";
import _, { debounce } from "lodash";
import { DataList } from "../shared/DataList";
import { defaultTokens, formatDateWithTime, formatDomainEventCustomer, formatDomainEventPerformedBy, formatDomainEventType, formatDomainEventUserName, getCustomerTags, getIdentityTags, resetFilter } from "../shared/CommonUi";
import { activityService } from "./activityService";
import commonStyles from "../shared/common.module.css";
import styles from "./Activities.module.css";
import { CustomPicker } from "../shared/CustomPicker";

const editIcon = { iconName: "Edit" };
function getTypeTags(vm: IActivitySearchVmResponse, t: TFunction): ITag[] {
    let unordered = _.map(vm?.activityTypes, type => ({ key: type, name: t(`common.domainEventTypes.${type}`) }));
    return _.orderBy(unordered, t => t.name) as ITag[];
}

const executeSearch = debounce(async (query: IActivityQuery, callback: (response: IActivityResponse) => void) => {
    let result = await activityService.getAll(query);
    callback(result);
}, 500);

export const Activities: React.FC = () => {
    let [t] = useTranslation();
    let navigate = useNavigate();
    let permissions = useMemo(() => getPortalPermissions(), []);
    let [selectedCustomers, setSelectedCustomers] = useState<ITag[]>();
    let [selectedPerformedBy, setSelectedPerformedBy] = useState<ITag[]>();
    let [selectedUsers, setSelectedUsers] = useState<ITag[]>();
    let [selectedTypes, setSelectedTypes] = useState<ITag[]>();
    let [query, setQuery] = useState(emptyActivityQuery());
    let queryWith = (queryPart: Partial<IActivityQuery>): void => {
        let newQuery = Object.assign({}, query, queryPart);
        setQuery(newQuery);
    }
    let { data: searchVm } = useSWR("/activities/vms/search", () => activityService.getSearchVm());
    let [activitiesResponse, setActivitiesResponse] = useState<IActivityResponse>(null);
    let allowedCustomerTags = useMemo(() => getCustomerTags(searchVm?.allowedCustomers), [searchVm]);
    let allowedPerformedByTags = useMemo(() => getIdentityTags(searchVm?.allowedPerformedBy), [searchVm]);
    let allowedUserTags = useMemo(() => getIdentityTags(searchVm?.allowedUsers), [searchVm]);
    let allowedTypeTags = useMemo(() => getTypeTags(searchVm, t), [searchVm, t]);
    let commands = getCommands(t, () => {
        setSelectedCustomers([]);
        setSelectedPerformedBy([]);
        setSelectedUsers([]);
        setSelectedTypes([]);
        return queryWith(emptyActivityQuery());
    });

    useEffect(() => {
        executeSearch(query, response => setActivitiesResponse(response));
    }, [query]);
    let breadcrumbs = useMemo(() => getBreadcrumbs(t), [t]);

    let columns = useMemo(() => getColumns(t, permissions, (action, ev) => {
        switch (action) {
            case "edit":
                navigate(`/users/${ev.aggregateId}`);
                break;
        }
    }), [t, permissions, navigate]);

    return (
        <div className={styles.content}>
            <Breadcrumb className={commonStyles.breadcrumb} style={{ marginBottom: 20 }} items={breadcrumbs} />
            <Card className={styles.commandCard}>
                <CommandBar items={commands}></CommandBar>
            </Card>
            <Card className={styles.filterCard} contentClassName={styles.filterCard_content}>
                <div style={{ gridRow: 1 }}>
                    <TextField label={t("activities.filters.text")} onChange={(ev, text) => queryWith({ text })} value={query.text} />
                </div>
                <div style={{ gridRow: 1 }}>
                    <CustomPicker limit={1} label={t("activities.filters.performedBy")} tags={allowedPerformedByTags} value={selectedPerformedBy} onSelectItems={items => {
                        setSelectedPerformedBy(items);
                        queryWith({ performedById: items.map(i => i.key as string)[0] });
                    }} />
                </div>
                <div style={{ gridRow: 2 }}>
                    <CustomPicker limit={1} label={t("activities.filters.users")} tags={allowedUserTags} value={selectedUsers} onSelectItems={items => {
                        setSelectedUsers(items);
                        queryWith({ userId: items.map(i => i.key as string)[0] });
                    }} />
                </div>
                {searchVm?.canFilterByCustomers && (<div style={{ gridRow: 2 }}>
                    <CustomPicker limit={1} label={t("activities.filters.customers")} tags={allowedCustomerTags} value={selectedCustomers} onSelectItems={items => {
                        setSelectedCustomers(items);
                        queryWith({ customerId: items.map(i => i.key as string)[0] });
                    }} />
                </div>)}
                <div style={{ gridRow: 3 }}>
                    <CustomPicker limit={1} label={t("activities.filters.types")} tags={allowedTypeTags} value={selectedTypes} onSelectItems={items => {
                        setSelectedTypes(items);
                        queryWith({ type: items.map(i => i.key as string)[0] });
                    }} />
                </div>
            </Card>
            <Card className={styles.resultCard}>
                <DataList columns={columns} items={activitiesResponse?.activities} />
            </Card>
        </div>
    );
};

function getColumns(t: TFunction, permissions: IPortalPermissions, onClick: (action: string, item: DomainEvents) => void): IColumn[] {
    let columns: IColumn[] = [];
    columns.push({
        key: "timestamp",
        name: t("activities.tableHeaders.timestamp"),
        minWidth: 100,
        maxWidth: 100,
        onRender: (item: DomainEvents) => formatDateWithTime(item.timestamp),
    });

    columns.push({
        key: "type",
        name: t("activities.tableHeaders.type"),
        minWidth: 200,
        maxWidth: 200,
        onRender: (item: DomainEvents) => formatDomainEventType(t, item.type),
    });

    columns.push({
        key: "user",
        name: t("activities.tableHeaders.user"),
        minWidth: 250,
        maxWidth: 250,
        onRender: (item: IUserDomainEvent) => formatDomainEventUserName(item),
    });

    if (permissions.readCustomers) {
        columns.push({
            key: "customer",
            name: t("activities.tableHeaders.customer"),
            minWidth: 150,
            maxWidth: 150,
            onRender: (item: DomainEvents) => formatDomainEventCustomer(item),
        });
    }

    columns.push({
        key: "performedBy",
        name: t("activities.tableHeaders.performedBy"),
        minWidth: 250,
        maxWidth: 250,
        onRender: (item: DomainEvents) => formatDomainEventPerformedBy(item),
    });

    columns.push({
        key: "actions",
        name: t("defaultUserTable.tableHeaders.actions"),
        minWidth: 220,
        onRender: (item: any) => (
            <Stack horizontal tokens={defaultTokens}>
                <DefaultButton iconProps={editIcon} onClick={() => { onClick("edit", item) }}>{permissions.editUser ? t("common.edit") : t("common.details")}</DefaultButton>
            </Stack>
        ),
    })
    return columns;
}

function getBreadcrumbs(t: TFunction): IBreadcrumbItem[] {
    return [
        {
            text: t("activities.breadcrumb.1"),
            key: "1"
        }
    ];
}

function getCommands(t: TFunction, onResetFilter: () => void): ICommandBarItemProps[] {
    let result: ICommandBarItemProps[] = [];
    result.push({
        key: "reset",
        text: t("users.commands.resetSearch"),
        iconProps: resetFilter,
        onClick: onResetFilter

    });

    return result;
}