import { Breadcrumb, CommandBar, IBreadcrumbItem, ICommandBarItemProps, MessageBar, MessageBarType, PrimaryButton, Stack, TextField } from "@fluentui/react";
import { Formik, FormikHelpers, FormikProps, getIn } from "formik";
import React, { useEffect, useMemo, useState } from "react";
import {NavigateFunction, useNavigate} from "react-router-dom";
import { Card } from "../components/Card";
import { FormCustomPicker, FormTextField, FormToggle } from "../components/FormComponents";
import { TFunction } from "i18next";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import useSWR from "swr";
import { userService } from "./userServices";
import { ICreateUserDataVm, IUser, IUsersNewVm, createEmptyAllowedGroups, toCreateUserRequest } from "../shared/models";
import { ProgressNotification } from "../shared/ProgressNotification";
import { activityService } from "../activities/activityService";
import _ from "lodash";
import { cardTokens, ErrorLabel, getCustomerTags } from "../shared/CommonUi";
import { ActivitiesDisplay } from "../shared/ActivitiesDisplay";
import styles from "./CreateInternalUser.module.css";
import commonStyles from "../shared/common.module.css";
import { GroupSelection } from "./GroupSelection";
import { ServiceDisplay } from "./ServiceDisplay";
import { getPortalPermissions } from "../shared/authService";

export const CreateInternalUser: React.FC = () => {
    let navigate = useNavigate();
    let [t] = useTranslation();
    let permissions = useMemo(() => getPortalPermissions(), []);

    let [initialValues, setInitialValues] = useState(getInitialValues());
    let [internalEMail, setInternalEMail] = useState<string>();
    let [internalEMailValid, setInternalEMailValid] = useState(false);
    let [notFoundError, setNotFoundError] = useState(false);

    let mailValidator = useMemo(() => yup.string().required().email(), []);

    useEffect(() => {
        let isValid = mailValidator.isValidSync(internalEMail);
        setInternalEMailValid(isValid);
        setNotFoundError(false);
    }, [internalEMail]);

    let { data: newVm } = useSWR("/users/vms/new", () => userService.getNewUserVm());
    let { data: userCreatedEvents, mutate } = useSWR("/activities/me/lastcreatedusers", () => activityService.getMyLastCreatedUsers());

    let availableCustomers = useMemo(() => getCustomerTags(newVm?.allowedCustomers), [newVm]);
    let mustPickCustomer = permissions.readCustomers && availableCustomers.length > 0;

    let breadcrumbs = useMemo(() => getBreadcrumbs(t), [t]);
    let validationScheme = useMemo(() => getValidationScheme(t, mustPickCustomer), [t, mustPickCustomer]);
    let availableGroups = useMemo(() => newVm?.allowedGroups ?? createEmptyAllowedGroups(), [newVm]);

    let saveHandler = async (values: ICreateUserDataVm, props: FormikHelpers<ICreateUserDataVm>) => {
        let notification = ProgressNotification.start(t("createInternalUser.infoMessages.userCreating"));
        let result = await userService.createUser(toCreateUserRequest(values));

        if (result.success) {
            notification.success(t("createInternalUser.infoMessages.userCreatedSuccess"));
        }
        else {
            notification.error(t("createInternalUser.infoMessages.userCreationFailed"));
        }

        mutate();

        props.resetForm();
        setInitialValues(getInitialValues());
        setInternalEMail(null);
    }

    let searchInternalUserHandler = async () => {
        setNotFoundError(false);
        let result = await userService.getInternalUserData(internalEMail);
        if (result.success) {
            setInitialValues(getInitialValuesFromUser(result.user));
        }
        else {
            setNotFoundError(true);
        }
    };

    return (<div className={styles.content}>
        <Formik initialValues={initialValues} validationSchema={validationScheme} onSubmit={saveHandler} validateOnMount enableReinitialize>
            {
                (formik) => {
                    let { values, setFieldValue } = formik;
                    let commands = getCommands(t, navigate, formik);
                    let infoText = getInfoText(newVm, t, formik);
                    return (
                        <>
                            <Breadcrumb className={commonStyles.breadcrumb} style={{ marginBottom: 20 }} items={breadcrumbs} />
                            <Card className={styles.commandCard}>
                                <CommandBar items={commands}></CommandBar>
                            </Card>
                            <div className={styles.formContent}>
                                <Stack tokens={cardTokens}>
                                    <Card title={t("createInternalUser.internalUserMail")}>
                                        <div className={styles.formWrapper}>
                                            <div>
                                                <TextField value={internalEMail} onChange={(_, text) => setInternalEMail(text)} label={t("createInternalUser.mail")} />
                                                <PrimaryButton disabled={!internalEMailValid} onClick={searchInternalUserHandler} style={{ marginTop: "1rem" }}>{t("createInternalUser.search")}</PrimaryButton>
                                                <div className={styles.federatedError}>
                                                    {notFoundError && (<MessageBar messageBarType={MessageBarType.error}>{t("createInternalUser.federatedUserNotFoundError")}</MessageBar>)}
                                                </div>
                                            </div>
                                        </div>
                                    </Card>
                                    <Card title={t("createInternalUser.basics")}>
                                        <div className={styles.formWrapper}>
                                            <div>
                                                <FormTextField disabled {...formik} name="givenName" label={t("createInternalUser.givenName")} />
                                                <FormTextField disabled {...formik} name="surname" label={t("createInternalUser.surname")} />
                                                <FormTextField disabled {...formik} name="email" label={t("createInternalUser.mail")} />
                                                {permissions.readUserAbbreviation && (<FormTextField {...formik} name="userAbbreviation" label={t("createInternalUser.userAbbreviation")} />)}
                                                {mustPickCustomer && (
                                                    <FormCustomPicker {...formik} name="customers" label={t("createInternalUser.customer")} tags={availableCustomers} itemLimit={1} />
                                                )}
                                            </div>

                                        </div>
                                    </Card>
                                    <Card title={t("common.confirmations")}>
                                        <div style={{ marginTop: "1rem" }}>
                                            <MessageBar messageBarType={MessageBarType.warning} >{infoText}</MessageBar>
                                        </div>
                                        <div>
                                            {newVm?.canAutoConfirm && (
                                                <>
                                                    <FormToggle {...formik} name="confirm" label={t("createInternalUser.confirm")} />
                                                    <FormTextField {...formik} name="confirmComment" rows={3} label={t("createInternalUser.confirmComment")} disabled={!values.confirm} />
                                                </>)}
                                        </div>
                                    </Card>
                                    <Card title={t("common.groups")}>
                                        <GroupError formik={formik} />
                                        <GroupSelection availableGroups={availableGroups} selectedGroups={values.groups} onSelectGroups={g => {
                                            setFieldValue("groups", g, true);
                                        }} />
                                    </Card>
                                    <Card title={t("common.services")}>
                                        <ServiceDisplay groups={values.groups} />
                                    </Card>
                                    <Card title={t("createInternalUser.lastCreated")}>
                                        <ActivitiesDisplay events={userCreatedEvents} />
                                    </Card>
                                </Stack>
                            </div>
                        </>
                    );
                }
            }
        </Formik>
    </div>);
};

const GroupError: React.FC<{ formik: FormikProps<any> }> = ({ formik }) => {
    let { errors, touched } = formik;
    let errorMessage = getIn(touched, "groups") && !!getIn(errors, "groups") && getIn(errors, "groups");
    return (<ErrorLabel message={errorMessage} />);
}


function getInfoText(vm: IUsersNewVm, t: TFunction, formik: FormikProps<ICreateUserDataVm>): string {
    if (!vm) {
        return "";
    }

    if (!vm.canAutoConfirm) {
        return t("createInternalUser.infoMessages.needsConfirmation");
    }

    let isConfirmed = getIn(formik.values, "isConfirmed") as boolean;
    if (isConfirmed) {
        return t("createInternalUser.infoMessages.isConfirmed");
    }

    return t("createInternalUser.infoMessages.needsConfirmation");
}

function getBreadcrumbs(t: TFunction): IBreadcrumbItem[] {
    return [
        {
            text: t(`createInternalUser.breadcrumb.1_user`),
            key: "1"
        }, {
            text: t(`createInternalUser.breadcrumb.2_new`),
            key: "2"
        }
    ];
}

function getCommands(t: TFunction, navigate: NavigateFunction, formik: FormikProps<ICreateUserDataVm>): ICommandBarItemProps[] {
    let { submitForm } = formik;
    return [
        {
            key: "back",
            text: t("common.cancel"),
            iconProps: { iconName: "Cancel" },
            onClick: () => navigate(-1),
        },
        {
            key: "save",
            text: t("common.save"),
            iconProps: { iconName: "Save" },
            onClick: () => { submitForm() },
        },
    ];
}

function getValidationScheme(t: TFunction, hasToPickCustomer: boolean) {

    let checkMailMemo = _.memoize(async (value: string) => {
        if (!value) {
            return false;
        }
        return userService.isEmailAvailable(value);
    })


    return yup.object().shape({
        surname: yup.string().required(t("validation.required")),
        givenName: yup.string().required(t("validation.required")),
        email: yup.string().email(t("validation.email")).required(t("validation.required")).test("checkEmailUnique", t("validation.emailNotAvailable"), checkMailMemo),
        groups: yup.mixed().when("isCustomerAdmin", { is: false, then: (e) => yup.array().min(1, t("validation.groupsRequired")).required(t("validation.groupsRequired")) }),

    })
}

function getInitialValues(): ICreateUserDataVm {
    return {
        surname: "",
        givenName: "",
        email: "",
        groups: [],
        customers: [] as any[],
        confirm: false,
        isCustomerAdmin: false,
        isFederatedUser: true,
        userAbbreviation: null,
        confirmComment: null,
    };
}

function getInitialValuesFromUser(user: IUser): ICreateUserDataVm {
    return {
        surname: user.surname,
        givenName: user.givenName,
        email: user.mail,
        groups: [],
        customers: [] as any[],
        confirm: false,
        isCustomerAdmin: false,
        isFederatedUser: true,
        userAbbreviation: null,
        confirmComment: null,
    };
}


