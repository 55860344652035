import {
    Breadcrumb,
    CommandBar,
    IBreadcrumbItem,
    ICommandBarItemProps,
    IconButton,
    MessageBar,
    MessageBarType,
    PrimaryButton,
    Stack,
    TextField
} from "@fluentui/react";
import { TFunction } from "i18next";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import useSWR from "swr";
import { Card } from "../components/Card";
import { ActivateUserPanel } from "../shared/ActivateUserPanel";
import { ActivitiesDisplay } from "../shared/ActivitiesDisplay";
import { backIcon, cardTokens, confirmIcon, deactivateIcon, deleteIcon, formatFunctionalPlace, resetPasswordIcon } from "../shared/CommonUi";
import { ConfirmUserPanel } from "../shared/ConfirmUserPanel";
import { DeactivateUserPanel } from "../shared/DeactivateUserPanel";
import { DeleteUserPanel } from "../shared/DeleteUserPanel";
import { EditUserAbbreviationPanel } from "../shared/EditUserAbbreviationPanel";
import { ProgressNotification } from "../shared/ProgressNotification";
import { ResetPasswordPanel } from "../shared/ResetPasswordPanel";
import { SendInvitationPanel } from "../shared/SendInvitationPanel";
import { canBeActivated, canBeConfirmedByCurrentUser, getConfig, getPortalPermissions } from "../shared/authService";
import commonStyles from "../shared/common.module.css";
import { IEditUserVmResponse, IFunctionalPlaceVm, IGroup, IPortalPermissions, IUser } from "../shared/models";
import { notificationService } from "../shared/notificationService";
import styles from "./EditUser.module.css";
import { GroupDisplay } from "./GroupDisplay";
import { GroupSelectionPanel, IUserGroupUpdate } from "./GroupSelectionPanel";
import { ServiceDisplay } from "./ServiceDisplay";
import { StatusMessages } from "./StatusMessages";
import { userService } from "./userServices";
import { FeatureLocked } from "../shared/FeatureLocked";

export const EditUser: React.FC = () => {
    let { userId } = useParams<{ userId: string }>();
    let { data: editVm, mutate } = useSWR(`/api/user/${userId}`, () => userService.getEditVm(userId));
    let navigate = useNavigate();
    let permissions = useMemo(() => getPortalPermissions(), []);
    let [t] = useTranslation();
    const config = getConfig();
    let [confirmUser, setConfirmUser] = useState<IUser>();
    let [deleteUser, setDeleteUser] = useState<IUser>();
    let [deactivateUser, setDeactivateUser] = useState<IUser>();
    let [activateUser, setActivateUser] = useState<IUser>();
    let [resetPasswordUser, setResetPasswordUser] = useState<IUser>();
    let [editUserAbbreviationUser, setEditUserAbbreviationUser] = useState<IUser>();
    let [groupSelectionMode, setGroupSelectionMode] = useState(false);
    let [sendInvitationUser, setSendInvitationUser] = useState<IUser>();
    let breadcrumbs = useMemo(() => getBreadcrumbs(t, editVm), [t, editVm]);
    let groups: IUserGroups = useMemo(() => { return { commonGroups: editVm?.user?.groups ?? [], functionalPlaceGroups: editVm?.functionalPlaces ?? [] } }, [editVm]);

    useEffect(() => {
        let subscription = notificationService.getSpecificUserUpdates(userId).subscribe(() => {
            return mutate();
        });
        return () => subscription.unsubscribe();
    }, [mutate, userId]);



    let onGroupsChange = async (groups: IUserGroupUpdate[]) => {
        let notification = ProgressNotification.start(t("editUser.infoMessages.groupAssignmentsUpdating"));
        setGroupSelectionMode(false);
        let result = await userService.updateGroupAssigments(groups);
        if (result.success) {
            notification.success(t("editUser.infoMessages.groupAssignmentsUpdateSucceeded"));
        } else {
            notification.error(t("editUser.infoMessages.groupAssignmentsUpdateFailed"));
        }
        mutate();
    };


    let commands = getCommands(t, editVm, permissions, (action: CommandActions) => {
        switch (action) {
            case "back":
                navigate(-1);
                break;
            case "confirm":
                setConfirmUser(editVm.user);
                break;
            case "deactivate":
                setDeactivateUser(editVm.user);
                break;
            case "activate":
                setActivateUser(editVm.user);
                break;
            case "delete":
                setDeleteUser(editVm.user);
                break;
            case "resetPassword":
                setResetPasswordUser(editVm.user);
                break;
            case "editUserAbbreviation":
                setEditUserAbbreviationUser(editVm.user);
                break;
            case "sendInvitation":
                setSendInvitationUser(editVm.user);
                break;
            default:
                break;
        }
    });

    return (<div className={styles.content}>
        <Breadcrumb className={commonStyles.breadcrumb} style={{ marginBottom: 20 }} items={breadcrumbs} />
        <Card className={styles.commandCard}>
            <CommandBar items={commands}></CommandBar>
        </Card>
        <div className={styles.formContent}>
            <Stack tokens={cardTokens}>
                <Card title={t("editUser.status")}>
                    <StatusMessages vm={editVm} />
                </Card>
                {editVm?.user && (<>
                    <Card title={t("editUser.basics")}>
                        <div className={styles.formWrapper}>
                            <div>
                                <TextField label={t("editUser.givenName")} readOnly value={editVm?.user.givenName} />
                                <TextField label={t("editUser.surname")} readOnly value={editVm?.user.surname} />
                                <TextField label={t("editUser.mail")} readOnly value={editVm?.user.mail} />
                                {(permissions.readUserAbbreviation || permissions.readUserDetails) && (<TextField name="userAbbreviation" readOnly label={t("createUser.userAbbreviation")} value={editVm?.user.userAbbreviation ?? ""} />)}
                                {(permissions.readCustomers || permissions.readUserDetails) && (<TextField label={t("editUser.customer")} readOnly
                                    value={`${editVm?.user.customerName ?? ""} ${editVm?.user.customerId ?? ""}`} />)}
                                <TextField label={t("editUser.id")} readOnly value={editVm?.user.id} />
                                <FeatureLocked config={config} feature={"ticketIdDisplay"}>
                                    <div className={styles.textWithButtonWrapper}>
                                        <TextField className={styles.flexGrow} label={"Ticket Id"} readOnly value={editVm?.user.externalTicketId} />
                                        {editVm?.user.externalTicketUrl && <IconButton iconProps={{ iconName: "NavigateExternalInline" }}
                                            onClick={() => { window.open(editVm?.user.externalTicketUrl, "_blank") }} />}
                                    </div>
                                </FeatureLocked>
                            </div>
                        </div>
                    </Card>
                    <Confirmations editVm={editVm} permissions={permissions} />
                    <Card title={t("common.groups")}>
                        <UserGroupsDisplay userGroups={groups} />
                        <div style={{ marginTop: "1rem" }}>
                            <PrimaryButton
                                disabled={!permissions.editUser}
                                onClick={() => setGroupSelectionMode(true)}>{t("editUser.editGroupAssignments")}</PrimaryButton>
                        </div>
                    </Card>
                    <Card title={t("common.services")}>
                        <ServiceDisplay groups={groups.commonGroups} />
                    </Card>

                </>)}
                {editVm?.restrictView ?
                    <></>
                    :
                    <Card title={t("editUser.activity")}>
                        <ActivitiesDisplay events={editVm?.activities} />
                    </Card>
                }

            </Stack>
        </div>
        <ConfirmUserPanel isVisible={confirmUser != null} confirmUser={confirmUser}
            onDismiss={() => setConfirmUser(null)} onActionStarting={() => setConfirmUser(null)}
            onActionCompleted={() => mutate()} />
        <DeleteUserPanel isVisible={deleteUser != null} deleteUser={deleteUser} onDismiss={() => setDeleteUser(null)}
            onActionStarting={() => setDeleteUser(null)} onActionCompleted={() => mutate()} />
        <DeactivateUserPanel isVisible={deactivateUser != null} deactivateUser={deactivateUser}
            onDismiss={() => setDeactivateUser(null)} onActionStarting={() => setDeactivateUser(null)}
            onActionCompleted={() => mutate()} />
        <ActivateUserPanel isVisible={activateUser != null} activateUser={activateUser}
            onDismiss={() => setActivateUser(null)} onActionStarting={() => setActivateUser(null)}
            onActionCompleted={() => mutate()} />
        <GroupSelectionPanel availableGroups={editVm?.allowedGroups} defaultSelectedGroups={groups.commonGroups}
            isVisible={groupSelectionMode} onDismiss={() => setGroupSelectionMode(false)}
            onChange={onGroupsChange}
            functionalPlacePermissions={editVm?.functionalPlaces ?? []}
            userId={userId}
        />
        <ResetPasswordPanel isVisible={resetPasswordUser != null} user={resetPasswordUser}
            onDismiss={() => setResetPasswordUser(null)}
            onActionStarting={() => setResetPasswordUser(null)} onActionCompleted={() => mutate()} />
        <EditUserAbbreviationPanel isVisible={editUserAbbreviationUser != null} user={editUserAbbreviationUser}
            onDismiss={() => setEditUserAbbreviationUser(null)}
            onActionStarting={() => setEditUserAbbreviationUser(null)} onActionCompleted={() => mutate()} />
        <SendInvitationPanel isVisible={sendInvitationUser != null} user={sendInvitationUser}
            onDismiss={() => setSendInvitationUser(null)}
            onActionStarting={() => setSendInvitationUser(null)} onActionCompleted={() => mutate()} />
    </div>);
};

function getBreadcrumbs(t: TFunction, vm: IEditUserVmResponse): IBreadcrumbItem[] {
    if (!vm) {
        return [];
    }
    let display = vm?.user?.displayName ?? t("common.unknown");

    return [
        {
            text: t(`createUser.breadcrumb.1_user`),
            key: "1"
        }, {
            text: display,
            key: "2"
        }
    ];
}


type CommandActions = "back" | "delete" | "confirm" | "activate" | "deactivate" | "resetPassword" | "editUserAbbreviation" | "sendInvitation";

function getCommands(t: TFunction, editVm: IEditUserVmResponse, permissions: IPortalPermissions, onClick: (action: CommandActions) => void): ICommandBarItemProps[] {
    let items: ICommandBarItemProps[] = [{
        key: "back",
        text: t("common.back"),
        iconProps: backIcon,
        onClick: () => onClick("back"),
    }];

    if (!editVm?.user || editVm?.restrictView) {
        return items;
    }

    if (canBeConfirmedByCurrentUser(editVm.user, permissions)) {
        items.push({
            key: "confirm",
            text: t("common.confirm"),
            iconProps: confirmIcon,
            onClick: () => onClick("confirm"),
        });
    }

    if (canBeActivated(editVm.user, permissions)) {
        items.push({
            key: "activate",
            text: t("common.activate"),
            iconProps: confirmIcon,
            onClick: () => onClick("activate"),
        });
    }

    if (!editVm.user.isDeactivated && permissions.deactivateUser) {
        items.push({
            key: "deactivate",
            text: t("common.deactivate"),
            iconProps: deactivateIcon,
            onClick: () => onClick("deactivate"),
        });
    }

    if (editVm.user.isPasswordResetAvailable && permissions.resetUserPassword && permissions.editUser) {
        items.push({
            key: "resetPassword",
            text: t("common.resetPassword"),
            iconProps: resetPasswordIcon,
            onClick: () => onClick("resetPassword"),
        });
    }

    if (editVm.user.isSendInvitationAvailable && permissions.editUser) {
        items.push({
            key: "sendInvitation",
            text: t("common.sendInvitation"),
            iconProps: resetPasswordIcon,
            onClick: () => onClick("sendInvitation"),
        });
    }

    if (permissions.editUserAbbreviation) {
        items.push({
            key: "editUserAbbreviation",
            text: t("editUser.actions.editUserAbbreviation"),
            iconProps: resetPasswordIcon,
            onClick: () => onClick("editUserAbbreviation"),
        });
    }

    if (permissions.deleteUser) {
        items.push({
            key: "delete",
            text: t("common.delete"),
            iconProps: deleteIcon,
            onClick: () => onClick("delete"),
        });
    }

    return items;
}

const Confirmations: React.FC<{ editVm: IEditUserVmResponse, permissions: IPortalPermissions }> = ({ editVm, permissions }) => {
    let [t] = useTranslation();

    if (editVm?.user == null) {
        return null;
    }
    let hasConfirmations = editVm?.confirmationEvents?.length > 0;
    return (<Card title={t("common.confirmations")}>
        {getMessageBar(editVm, t)}
        {hasConfirmations && permissions.confirmUser && (<div style={{ marginTop: "1rem" }}>
            <ActivitiesDisplay events={editVm?.confirmationEvents} />
        </div>)}
    </Card>);
}


function getMessageBar(editVm: IEditUserVmResponse, t: TFunction) {
    if (editVm.user.isConfirmed) {
        return <MessageBar messageBarType={MessageBarType.success} >{t("editUser.confirmation.completed")}</MessageBar>
    }

    return <MessageBar messageBarType={MessageBarType.warning} >{t("editUser.confirmation.missingGrants", { amount: editVm.user.approvalInfo.missingConfirmations })}</MessageBar>
}
interface IUserGroups {
    commonGroups?: IGroup[];
    functionalPlaceGroups?: IFunctionalPlaceVm[];
}

const UserGroupsDisplay: React.FC<{ userGroups: IUserGroups }> = ({ userGroups }) => {
    const config = getConfig();

    return <>
        <p><b>Common</b></p>
        <GroupDisplay groups={userGroups.commonGroups} />
        <FeatureLocked config={config} feature="enableExtendedPermissionModel">
            {
                userGroups.functionalPlaceGroups?.map(fp => fp.permissions.length > 0 ? <>
                    <p><b>{formatFunctionalPlace(fp.functionalPlace)}</b></p>
                    <GroupDisplay groups={fp.permissions} />
                </> : <></>)
            }
        </FeatureLocked>
    </>

}