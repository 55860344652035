
import { createTheme } from '@fluentui/react/lib/Theme';

export const myTheme = createTheme({
    palette: {
        themePrimary: '#555e62',
        themeLighterAlt: '#f7f8f9',
        themeLighter: '#e1e4e6',
        themeLight: '#c7cdd0',
        themeTertiary: '#949ca0',
        themeSecondary: '#677074',
        themeDarkAlt: '#4c5457',
        themeDark: '#40474a',
        themeDarker: '#2f3436',
        neutralLighterAlt: '#faf9f8',
        neutralLighter: '#f3f2f1',
        neutralLight: '#edebe9',
        neutralQuaternaryAlt: '#e1dfdd',
        neutralQuaternary: '#d0d0d0',
        neutralTertiaryAlt: '#c8c6c4',
        neutralTertiary: '#a19f9d',
        neutralSecondary: '#605e5c',
        neutralPrimaryAlt: '#3b3a39',
        neutralPrimary: '#323130',
        neutralDark: '#201f1e',
        black: '#000000',
        white: '#ffffff',
    }
});
