import React, { CSSProperties, useMemo } from "react";
import { IBasePickerSuggestionsProps, IInputProps, ITag, Label, TagPicker, TextField, Toggle } from "@fluentui/react";
import { connect, FormikProps, getIn } from "formik";
import { reach } from "yup";
import { useId } from "@fluentui/react-hooks";
import _ from "lodash";
import styles from "./FormComponents.module.css";

interface IFieldProps {
    name: string;
    label?: string;
    disabled?: boolean;
    formClassName?: string;
    required?: boolean;
    text?: string;
    style?: CSSProperties;
    dataAutomationId?: string;
    description?: string;
}

type DefaultFormFieldProps = { formik?: any, rows?: number } & Partial<FormikProps<any>> & IFieldProps;

const FormTextFieldRaw: React.FC<DefaultFormFieldProps> = React.memo(
    (props) => {
        let { label, name, disabled, values, setFieldValue, setFieldTouched, dataAutomationId, touched, errors, rows } = props;
        let providedValue = getIn(values, name);
        let isRequired = getIsFieldRequired(props);
        let errorMessage = getErrorMessage(props);
        return <>
            <TextField rows={rows} multiline={rows > 1} label={label} required={isRequired} value={providedValue} disabled={disabled}
                onChange={(_, v) => setFieldValue(name, v)} onBlur={() => setFieldTouched(name)} />
            {errorMessage && (<p className={styles.errorMessage}><span>{errorMessage}</span></p>)}
        </>
    }
);

export const FormTextField = connect(FormTextFieldRaw);

const FormCustomPickerRaw: React.FC<{ label: string, tags: ITag[], itemLimit?: number, suggestionProps?: IBasePickerSuggestionsProps } & DefaultFormFieldProps> = (props) => {
    let { tags, label, name, disabled, values, setFieldValue, setFieldTouched, dataAutomationId, touched, errors, suggestionProps, itemLimit } = props;
    tags = tags ?? [];
    let pickerId = useId("pickerId");
    let providedValue = getIn(values, name);
    let inputProps = useMemo(() => {
        const inputProps: IInputProps = {
            id: pickerId,
            onBlur: (ev: React.FocusEvent<HTMLInputElement>) => setFieldTouched(name)
        };
        return inputProps;
    }, [pickerId, setFieldTouched, name])
    let errorMessage = getErrorMessage(props);

    return (
        <>
            <Label htmlFor={pickerId}>{label}</Label>
            <TagPicker
                disabled={disabled}
                selectedItems={providedValue}
                inputProps={inputProps}
                onEmptyResolveSuggestions={(selectedItems) => {
                    return _.difference(tags, selectedItems);
                }}
                onResolveSuggestions={(filter, selectedItems) => {
                    let available = _.difference(tags, selectedItems);
                    let filtered = _.filter(available, o => o.name.toLowerCase().includes(filter));
                    return filtered
                }}
                pickerSuggestionsProps={suggestionProps}
                onChange={items => setFieldValue(name, _.filter(tags, g => _.some(items, i => i.key === g.key)))}
                itemLimit={itemLimit}
            />
            {errorMessage && (<p className={styles.errorMessage}><span>{errorMessage}</span></p>)}
        </>)
}

export const FormCustomPicker = connect(FormCustomPickerRaw);

const FormToggleRaw: React.FC<{ label: string } & DefaultFormFieldProps> = (props) => {
    let { label, name, disabled, values, setFieldValue, setFieldTouched, dataAutomationId, touched, errors } = props;
    let providedValue = getIn(values, name);
    return <Toggle disabled={disabled} label={label} checked={providedValue} onBlur={() => setFieldTouched(name)} onChange={(ev, checked) => setFieldValue(name, checked)} />
}

export const FormToggle = connect(FormToggleRaw);

// ******************************************************
// Helpers
// ******************************************************

function getIsFieldRequired(props: DefaultFormFieldProps): boolean {
    let { name, formik, values } = props;
    const validationSchema = formik.validationSchema;
    let fieldValidationSchema;
    try {
        fieldValidationSchema = validationSchema ? (reach(validationSchema, name, values, values) as any) : false;
    } catch {
        fieldValidationSchema = false;
    }
    const resolvedSchema = fieldValidationSchema ? fieldValidationSchema.resolve({ value: values }) : false;
    const tests = resolvedSchema ? resolvedSchema.describe().tests : false;
    const isRequired = tests ? !!tests.find((test: any) => test.name === "required") : false;
    return isRequired;
}

function hasFeedback(props: DefaultFormFieldProps): boolean {
    let { name, touched, errors } = props;
    return getIn(touched, name) && !!getIn(errors, name);
}

function getValidation(touched: any, errors: any, name: string): "" | "error" | "success" | "warning" | "validating" {
    if (!getIn(touched, name)) {
        return "";
    }
    return getIn(errors, name) && "error";
}

function getErrorMessage(props: DefaultFormFieldProps): string {
    let { name, touched, errors } = props;
    return getIn(touched, name) && !!getIn(errors, name) && getIn(errors, name);
}
