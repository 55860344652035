import {
    Breadcrumb,
    CompoundButton,
    IBreadcrumbItem,
    MessageBar,
    MessageBarType,
    Stack
} from "@fluentui/react";
import { TFunction, t } from "i18next";
import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import useSWR from "swr";
import { activityService } from "../activities/activityService";
import { Card } from "../components/Card";
import { customerService } from "../customers/customerService";
import { reportingService } from "../reporting/reportingService";
import { ActivitiesDisplay } from "../shared/ActivitiesDisplay";
import { addIcon, cardTokens, dateForFileName, downloadFileBlob, groupIcon, reportIcon } from "../shared/CommonUi";
import { FunctionalPlaceInfoList } from "../shared/FunctionalPlaceInfo";
import { ProgressNotification } from "../shared/ProgressNotification";
import { getConfig, getPortalPermissions } from "../shared/authService";
import commonStyles from "../shared/common.module.css";
import { notificationService } from "../shared/notificationService";
import { userService } from "../users/userServices";
import { LongtermDeactivedUsersCard } from "./LongtermDeactivedUsersCard";
import styles from "./Overview.module.css";
import { UnconfirmedUsersCard } from "./UnconfirmedUsersCard";
import { UserRevalidateCard } from "./UserRevalidateCard";
import { FeatureLocked } from "../shared/FeatureLocked";

export const Overview: React.FC = () => {
    let { data: dashboardData, error, mutate } = useSWR("/api/dashboard", () => userService.getDashboard());
    let { data: customerData, error: customerError, mutate: mutateCustomer } = useSWR(`/api/v2/customers/me`, () => customerService.getMyCustomer());
    const config = getConfig();

    let permissions = useMemo(() => getPortalPermissions(), []);
    useEffect(() => {
        let subscription = notificationService.getUserUpdates().subscribe(() => {
            return mutate();
        });
        return () => subscription.unsubscribe();
    }, [mutate]);

    return (
        <div className={styles.content}>
            <Stack tokens={cardTokens}>
                <ActionsCard />
                {dashboardData?.usersToRevalidate.length > 0 && <MessageBar messageBarType={MessageBarType.warning}>{t("overview.mustRevalidateUsers")}</MessageBar>}
                <FeatureLocked config={config} feature="enableExtendedPermissionModel">
                    <FunctionalPlaceInfoList functionalPlaces={customerData?.customer?.functionalPlaces ?? []} />
                </FeatureLocked>
                <ActivitiesCard />
                {permissions.readApprovals && (<UnconfirmedUsersCard data={dashboardData} />)}
                {permissions.readLongtermDeactivated && (<LongtermDeactivedUsersCard data={dashboardData} />)}
                {permissions.readApprovals && (<UserRevalidateCard usersToRevalidate={dashboardData?.usersToRevalidate ?? []} />)}
            </Stack>
        </div >
    );
};

const ActionsCard: React.FC = () => {
    let navigate = useNavigate();
    let permissions = useMemo(() => getPortalPermissions(), []);
    let [t] = useTranslation();
    let breadcrumbs = useMemo(() => getBreadcrumbs(t), [t]);
    return (
        <>
            <Breadcrumb className={commonStyles.breadcrumb} style={{ marginBottom: 20 }} items={breadcrumbs} />
            <Card title={t("overview.actionsCard.title")}>
                <div className={styles.actionsContainer}>
                    {permissions.createInternalUser && (<CompoundButton iconProps={addIcon}
                        secondaryText={t("overview.actionsCard.newInternalUser.secondary")} onClick={() => navigate("/users/newinternal")}>
                        {t("overview.actionsCard.newInternalUser.primary")}
                    </CompoundButton>)}
                    {permissions.createAdminUser && (<CompoundButton iconProps={addIcon}
                        secondaryText={t("overview.actionsCard.newCustomerAdmin.secondary")} onClick={() => navigate("/users/new?isCustomerAdmin=true")}>
                        {t("overview.actionsCard.newCustomerAdmin.primary")}
                    </CompoundButton>)}
                    {permissions.createUser && (<CompoundButton iconProps={addIcon} secondaryText={t("overview.actionsCard.newUser.secondary")} onClick={() => navigate("/users/new")}>
                        {t("overview.actionsCard.newUser.primary")}
                    </CompoundButton>)}
                    {permissions.readUserDetails && (<CompoundButton iconProps={groupIcon}
                        secondaryText={t("overview.actionsCard.editUsers.secondary")} onClick={() => navigate(`/users`)}>
                        {t("overview.actionsCard.editUsers.primary")}
                    </CompoundButton>
                    )}
                    {permissions.createUserReport && (<CompoundButton iconProps={reportIcon}
                        secondaryText={t("overview.actionsCard.newUserReport.secondary")} onClick={async () => {
                            let notification = ProgressNotification.start(t("reports.notifications.inProgress"));
                            try {
                                let result = await reportingService.getUserReport();
                                notification.success(t("reports.notifications.inProgress"));
                                downloadFileBlob(result, `user_report_${dateForFileName()}.xlsx`);
                            }
                            catch {
                                notification.error(t("reports.notifications.failed"));
                            }
                        }}>
                        {t("overview.actionsCard.newUserReport.primary")}
                    </CompoundButton>
                    )}
                </div>
            </Card>
        </>);
}



const ActivitiesCard: React.FC = () => {
    let [t] = useTranslation();
    let { data: domainEvents, mutate } = useSWR("activities/latest", () => activityService.getLatest(), { refreshInterval: 60000 });
    useEffect(() => {
        let subscription = notificationService.getActivityUpdates().subscribe(() => {
            mutate();
        });

        return () => subscription.unsubscribe();

    }, [mutate]);
    return (<Card title={t("overview.lastActivitiesCard.title")} contentClassName={styles.lastActivitiesCardContent}>
        <ActivitiesDisplay events={domainEvents} />

    </Card>);
}





function getBreadcrumbs(t: TFunction): IBreadcrumbItem[] {
    return [
        {
            text: t("common.dashboard"),
            key: "1"
        }
    ];
}