import { Breadcrumb, CommandBar, IBreadcrumbItem, ICommandBarItemProps, MessageBar, MessageBarType, Stack } from "@fluentui/react";
import { Formik, FormikHelpers, FormikProps, getIn } from "formik";
import React, { useMemo } from "react";
import { useNavigate, useLocation, NavigateFunction } from "react-router-dom";
import { Card } from "../components/Card";
import { FormCustomPicker, FormTextField, FormToggle } from "../components/FormComponents";
import querystring from "query-string";
import { TFunction } from "i18next";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import useSWR from "swr";
import { userService } from "./userServices";
import { ICreateUserDataVm, IUsersNewVm, createEmptyAllowedGroups, toCreateUserRequest } from "../shared/models";
import { ProgressNotification } from "../shared/ProgressNotification";
import { activityService } from "../activities/activityService";
import _ from "lodash";
import { cardTokens, ErrorLabel, getCustomerTags } from "../shared/CommonUi";
import { ActivitiesDisplay } from "../shared/ActivitiesDisplay";
import styles from "./CreateUser.module.css";
import commonStyles from "../shared/common.module.css";
import { GroupSelection } from "./GroupSelection";
import { ServiceDisplay } from "./ServiceDisplay";
import { getPortalPermissions } from "../shared/authService";

export const CreateUser: React.FC = () => {
    let { search } = useLocation();
    let navigate = useNavigate();
    let [t] = useTranslation();
    let parsed = querystring.parse(search);
    let isCustomerAdmin = !!parsed.isCustomerAdmin;
    let permissions = useMemo(() => getPortalPermissions(), []);

    let initialValues = useMemo(() => getInitialValues(isCustomerAdmin), [isCustomerAdmin]);
    let { data: newVm } = useSWR("/users/vms/new", () => userService.getNewUserVm());
    let { data: userCreatedEvents, mutate } = useSWR("/activities/me/lastcreatedusers", () => activityService.getMyLastCreatedUsers());

    let availableCustomers = useMemo(() => getCustomerTags(newVm?.allowedCustomers), [newVm]);
    let mustPickCustomer = permissions.readCustomers && availableCustomers.length > 0;

    let breadcrumbs = useMemo(() => getBreadcrumbs(t, isCustomerAdmin), [isCustomerAdmin, t]);
    let validationScheme = useMemo(() => getValidationScheme(t, mustPickCustomer), [t, mustPickCustomer]);
    let availableGroups = useMemo(() => newVm?.allowedGroups ?? createEmptyAllowedGroups(), [newVm]);

    let saveHandler = async (values: ICreateUserDataVm, props: FormikHelpers<ICreateUserDataVm>) => {
        let notification = ProgressNotification.start(t("createUser.infoMessages.userCreating"));
        let result = await userService.createUser(toCreateUserRequest(values));

        if (result.success) {
            notification.success(t("createUser.infoMessages.userCreatedSuccess"));
        }
        else {
            notification.error(t("createUser.infoMessages.userCreationFailed"));
        }

        mutate();

        props.resetForm();
    }

    return (<div className={styles.content}>
        <Formik initialValues={initialValues} validationSchema={validationScheme} onSubmit={saveHandler} validateOnMount>
            {
                (formik) => {
                    let { values, setFieldValue } = formik;
                    let commands = getCommands(t, navigate, formik);
                    let infoText = getInfoText(newVm, t, formik);
                    return (
                        <>
                            <Breadcrumb className={commonStyles.breadcrumb} style={{ marginBottom: 20 }} items={breadcrumbs} />
                            <Card className={styles.commandCard}>
                                <CommandBar items={commands}></CommandBar>
                            </Card>
                            <div className={styles.formContent}>
                                <Stack tokens={cardTokens}>
                                    <Card title={t("createUser.basics")}>
                                        <div className={styles.formWrapper}>
                                            <div>
                                                <FormTextField {...formik} name="givenName" label={t("createUser.givenName")} />
                                                <FormTextField {...formik} name="surname" label={t("createUser.surname")} />
                                                <FormTextField {...formik} name="email" label={t("createUser.mail")} />
                                                {permissions.readUserAbbreviation && (<FormTextField {...formik} name="userAbbreviation" label={t("createUser.userAbbreviation")} />)}
                                                {mustPickCustomer && (
                                                    <FormCustomPicker {...formik} name="customers" label={t("createUser.customer")} tags={availableCustomers} itemLimit={1} />
                                                )}
                                            </div>

                                        </div>
                                    </Card>
                                    {permissions.confirmUser && (<Card title={t("common.confirmations")}>
                                        <div style={{ marginTop: "1rem" }}>
                                            <MessageBar messageBarType={MessageBarType.warning} >{infoText}</MessageBar>
                                        </div>
                                        <div>
                                            {newVm?.canAutoConfirm && (
                                                <>
                                                    <FormToggle {...formik} name="confirm" label={t("createUser.confirm")} />
                                                    <FormTextField {...formik} name="confirmComment" rows={3} label={t("createUser.confirmComment")} disabled={!values.confirm} />
                                                </>)}
                                        </div>
                                    </Card>
                                    )}
                                    <Card title={t("common.groups")}>
                                        <GroupError formik={formik} />
                                        <GroupSelection availableGroups={availableGroups} selectedGroups={values.groups} onSelectGroups={g => {
                                            setFieldValue("groups", g, true);
                                        }} />
                                    </Card>
                                    <Card title={t("common.services")}>
                                        <ServiceDisplay groups={values.groups} />
                                    </Card>
                                    <Card title={t("createUser.lastCreated")}>
                                        <ActivitiesDisplay events={userCreatedEvents} />
                                    </Card>
                                </Stack>
                            </div>
                        </>
                    );
                }
            }
        </Formik>
    </div>);
};

const GroupError: React.FC<{ formik: FormikProps<any> }> = ({ formik }) => {
    let { errors, touched } = formik;
    let errorMessage = getIn(touched, "groups") && !!getIn(errors, "groups") && getIn(errors, "groups");
    return (<ErrorLabel message={errorMessage} />);
}


function getInfoText(vm: IUsersNewVm, t: TFunction, formik: FormikProps<ICreateUserDataVm>): string {
    if (!vm) {
        return "";
    }

    if (!vm.canAutoConfirm) {
        return t("createUser.infoMessages.needsConfirmation");
    }

    let isConfirmed = getIn(formik.values, "isConfirmed") as boolean;
    if (isConfirmed) {
        return t("createUser.infoMessages.isConfirmed");
    }

    return t("createUser.infoMessages.needsConfirmation");
}

function getBreadcrumbs(t: TFunction, isCustomerAdmin: boolean): IBreadcrumbItem[] {
    return [
        {
            text: t(`createUser.breadcrumb.${isCustomerAdmin ? "1_admin" : "1_user"}`),
            key: "1"
        }, {
            text: t(`createUser.breadcrumb.2_new`),
            key: "2"
        }
    ];
}

function getCommands(t: TFunction, navigate: NavigateFunction, formik: FormikProps<ICreateUserDataVm>): ICommandBarItemProps[] {
    let { submitForm } = formik;
    return [
        {
            key: "back",
            text: t("common.cancel"),
            iconProps: { iconName: "Cancel" },
            onClick: () => navigate(-1),
        },
        {
            key: "save",
            text: t("common.save"),
            iconProps: { iconName: "Save" },
            onClick: () => { submitForm() },
        },
    ];
}

function getValidationScheme(t: TFunction, hasToPickCustomer: boolean) {

    let checkMailMemo = _.memoize(async (value: string) => {
        if (!value) {
            return false;
        }
        return userService.isEmailAvailable(value);
    })


    return yup.object().shape({
        surname: yup.string().required(t("validation.required")),
        givenName: yup.string().required(t("validation.required")),
        email: yup.string().email(t("validation.email")).required(t("validation.required")).test("checkEmailUnique", t("validation.emailNotAvailable"), checkMailMemo),
        groups: yup.mixed().when("isCustomerAdmin", { is: false, then: (e) => yup.array().min(1, t("validation.groupsRequiredööö")).required(t("validation.groupsRequiredööö")) }),
        customers: hasToPickCustomer && yup.array().min(1, t("validation.required")).required(t("validation.required"))
    })
}

function getInitialValues(isCustomerAdmin: boolean): ICreateUserDataVm {
    return {
        surname: "",
        givenName: "",
        email: "",
        groups: [],
        customers: [] as any[],
        confirm: false,
        isCustomerAdmin: isCustomerAdmin,
        isFederatedUser: false,
        userAbbreviation: null,
        confirmComment: null,
    };
}


