import { httpClient } from "../shared/httpClient";
import { emptyCustomerQuery, ICustomer2InfoResult, ICustomerQuery, ICustomerSearchVm, ICustomersResponse } from "../shared/models";


class CustomerService {
    
    async getAll(query?: ICustomerQuery) {
        query = query ?? emptyCustomerQuery();
        let result = await httpClient.post<ICustomersResponse>(`/api/v2/customers/queries`, query);
        return result.data;
    }
    async getById(customerId?: string) {
        let result = await httpClient.get<ICustomer2InfoResult>(`/api/v2/customers/${customerId}`);
        return result.data;
    }
    async getMyCustomer() {
        let result = await httpClient.get<ICustomer2InfoResult>(`/api/v2/customers/me`);
        return result.data;
    }
    async getCustomerSearchVm() {
        let response = await httpClient.get<ICustomerSearchVm>(`/api/customers/vms/search`);
        return response.data;
    }
}

export const customerService = new CustomerService();