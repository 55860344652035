import { httpClient } from "../shared/httpClient";
import { IBulkAssignVm, IFunctionalPlaceUserInfoVm, IPermissionInfoVm, IPermissionsInfoQuery } from "../shared/models";

class PermissionsService {
  async getPermissionsInfo(query: IPermissionsInfoQuery): Promise<IPermissionInfoVm> {
    let response = await httpClient.post<IPermissionInfoVm>(`/api/v2/permissions/query`,query);
    return response.data;
  }
  async getBulkAssignOptions(): Promise<IBulkAssignVm> {
    let response = await httpClient.get(`/api/v2/permissions/bulk`);
    return response.data;
  }
  async getPermissionsInfoByFpId(fpId:string): Promise<{functionalPlaceInfo: IFunctionalPlaceUserInfoVm,success:boolean}> {
    let response = await httpClient.get(`/api/v2/permissions/fp/${fpId}`);
    return response.data;
  }
}
export const permissionsService = new PermissionsService();


