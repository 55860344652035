import { CommandBar, ICommandBarItemProps, ITag, MessageBar, MessageBarType, ProgressIndicator, Shimmer, ShimmerLine, ShimmeredDetailsList, Stack, StackItem } from "@fluentui/react";
import { debounce } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import useSWR from "swr";
import { Card } from "../components/Card";
import { customerService } from "../customers/customerService";
import { getCustomerTags } from "../shared/CommonUi";
import { ContentWrapper } from "../shared/ContentWrapper";
import { CustomPicker } from "../shared/CustomPicker";
import { getPortalPermissions } from "../shared/authService";
import { IPermissionInfoVm, IPermissionsInfoQuery, createEmptyPermissionsInfoQuery } from "../shared/models";
import { PermissionsFunctionalPlaceCard } from "./PermissionsFunctionalPlaceCard";
import { permissionsService } from "./permissionsService";

const executeSearch = debounce(async (query: IPermissionsInfoQuery, callback: (response: IPermissionInfoVm) => void) => {
    let result = await permissionsService.getPermissionsInfo(query);
    callback(result);
}, 500);

export const Permissions: React.FC<{}> = () => {
    let navigate = useNavigate();
    let { data: searchVm, isLoading } = useSWR("customers/vms/search", () => customerService.getCustomerSearchVm());

    let [query, setQuery] = useState(createEmptyPermissionsInfoQuery());
    let [selectedCustomers, setSelectedCustomers] = useState<ITag[]>();
    let [vm, setVm] = useState<IPermissionInfoVm>(null);
    let [loading, setLoading] = useState<boolean>(false);

    let allowedCustomerTags = useMemo(() => getCustomerTags(searchVm?.allowedCustomers), [searchVm]);

    let queryWith = useCallback((queryPart?: Partial<IPermissionsInfoQuery>): void => {
        let newQuery = Object.assign({}, query, queryPart ?? {});
        setQuery(newQuery);
    }, [query])

    useEffect(() => {
        if (searchVm?.defaultCustomer) {
            setSelectedCustomers(getCustomerTags([searchVm.defaultCustomer]))
            queryWith({ customerId: searchVm.defaultCustomer?.customerIdentifier })
        }
    }, [searchVm]);

    useEffect(() => {
        if (searchVm && (query.customerId.length > 0)) {
            setLoading(true);
            executeSearch(query, response => { setVm(response); setLoading(false) });
        }
    }, [query, searchVm]);

    let permissions = useMemo(() => getPortalPermissions(), []);
    const [t] = useTranslation();
    let commands: ICommandBarItemProps[] = useMemo(() => {
        let commandsTmp = [{
            key: "back",
            text: t("common.back"),
            iconProps: { iconName: "Back" },
            onClick: () => navigate(-1)
        }];
        if (permissions.editUser) {
            commandsTmp.push({
                key: "bulkassign",
                text: t("Bulk Assign"),
                iconProps: { iconName: "Group" },
                onClick: () => navigate(`/permissions/assign`),

            })
        }
        return commandsTmp;
    }, [permissions]);

    return <ContentWrapper>
        <Stack>
            <Stack.Item style={{ marginBottom: "1rem" }}>
                <Card >
                    <CommandBar items={commands} />
                </Card>
            </Stack.Item>
            <Stack.Item>
                <CustomPicker
                    label={t("users.filters.customers")}
                    tags={allowedCustomerTags}
                    value={selectedCustomers}
                    onSelectItems={items => {
                        setSelectedCustomers(items);
                        queryWith({ customerId: items.length === 0 ? "" : items[0].key as string });
                    }}
                    limit={1}
                    disabled={isLoading}
                />
            </Stack.Item>
            {isLoading || loading ?
                <Stack.Item style={{ marginTop: "2rem" }}><ProgressIndicator /></Stack.Item>
                :
                vm?.functionalPlaces && vm.functionalPlaces.length > 0 ?
                    vm.functionalPlaces.map(fpInfo => <>
                        <Stack.Item><PermissionsFunctionalPlaceCard fpInfo={fpInfo} /></Stack.Item>
                    </>)
                    :
                    <Stack.Item><MessageBar messageBarType={MessageBarType.info}>{t("editUser.statusMessages.permission.noResults")}</MessageBar></Stack.Item>

            }
        </Stack>

    </ContentWrapper >


}






