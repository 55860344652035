
// const createStatusMonitorReport = async (filter: IRiskAssessmentListSearchConfig, reportType: string, language: ILanguage) => {
//     const searchFilter = filter ?? createRiskAssessmentStatusMonitorSearchConfig();
//     const result = await httpClient.getInstance().post<Blob>(`riskAssessments/statusReport/${reportType}/${language.key}`, searchFilter, { responseType: "blob" });
//     return result;
//   };

import { httpClient } from "../shared/httpClient";


// generateReport = async (reportValue: string) => {
//     const report:any = await riskAssessmentService.createStatusMonitorReport(
//       this.state.searchConfig,
//       reportValue,
//       this.context.languageSettings.getActiveLanguage()
//     );
//     const head = report.headers["content-type"];
//     const blob = new Blob([report.data], { type: head });

//     downloadFileBlob(blob, `Statusreport_${formatter.formatDateWithTimeIsoString(moment().toISOString())}.xlsx`);
//   };

class ReportingService {
    async getUserReport(): Promise<Blob> {
        let result = await httpClient.post<Blob>("/api/reports/users", null, { responseType: "blob" });
        return result.data;
    }
}


export const reportingService = new ReportingService();