import { ITag } from "@fluentui/react";
import _ from "lodash";

export interface IConfig {
  app: {
    environment: string;
    featureFlags: IFeatureFlags;
  };
  client: {
    clientId: string;
    scopes: string[];
  };
  b2cPolicies: {
    names: {
      signUpSignIn: string;
      editProfile: string;
    };
    authorities: {
      signUpSignIn: {
        authority: string;
      };
      editProfile: {
        authority: string;
      };
    };
    authorityDomain: string;
  };
}
interface IFeatureFlags {
  ticketIdDisplay: boolean;
  enableExtendedPermissionModel: boolean;
  customerListShowContractValidDate: boolean;
}
export interface IPortalPermissions {
  readDashboard: boolean;
  readApprovals: boolean;
  readLongtermDeactivated: boolean;
  readUsers: boolean;
  readCustomers: boolean;
  readActivities: boolean;
  createCustomer: boolean;
  createUser: boolean;
  createInternalUser: boolean;
  resetUserPassword: boolean;
  editUser: boolean;
  readUserDetails: boolean;
  deleteUser: boolean;
  deactivateUser: boolean;
  confirmUser: boolean;
  createAdminUser: boolean;
  createGroupAssignment: boolean;
  createUserReport: boolean;
  readUserAbbreviation: boolean;
  editUserAbbreviation: boolean;
  readOwnCustomer: boolean;
  hasNoPermissions: boolean;
}

const emptyPortalPermissions: IPortalPermissions = {
  readDashboard: false,
  readApprovals: false,
  readLongtermDeactivated: false,
  readUsers: false,
  resetUserPassword: false,
  readCustomers: false,
  readActivities: false,
  createCustomer: false,
  confirmUser: false,
  createUser: false,
  createInternalUser: false,
  editUser: false,
  readUserDetails: false,
  deleteUser: false,
  deactivateUser: false,
  createAdminUser: false,
  createGroupAssignment: false,
  createUserReport: false,
  readUserAbbreviation: false,
  editUserAbbreviation: false,
  readOwnCustomer: false,
  hasNoPermissions: true,
};

export function createPortalPermissions(
  extendWith?: Partial<IPortalPermissions>
): IPortalPermissions {
  return Object.assign({}, emptyPortalPermissions, extendWith ?? {});
}

export interface IUsersResponse {
  users: IUser[];
}

export interface IUsersSearchVm {
  allowedGroups: IGroup[];
  allowedCustomers: ICustomerRef[];
  allowedUserTypes: UserType[];
  canFilterByGroups: boolean;
  canFilterByCustomers: boolean;
  canFilterByUserTypes: boolean;
}

export interface IUsersNewVm {
  canAutoConfirm: boolean;
  allowedGroups: IAllowedGroups;
  allowedCustomers: ICustomerRef[];
}

export interface IUsersNewVmOptions {
  internalUser?: boolean;
}

export interface IEditUserVmResponse {
  user: IUser;
  allowedGroups: IAllowedGroups;
  activities: DomainEvents[];
  errors: DomainErrors[];
  confirmationEvents: DomainEvents[];
  functionalPlaces: IFunctionalPlaceVm[];
  restrictView: boolean;
}
export interface IFunctionalPlaceVm {
  functionalPlace: IFunctionalLocation;
  allowedGroups: IAllowedGroups;
  allowedGroupsByContract: { [contractKey: string]: IGroup[] };
  permissions: IGroup[];
}
export interface ICustomerSearchVm {
  allowedCustomers: ICustomerRef[];
  defaultCustomer?: ICustomerRef;
}

export interface IPermissionInfoVm {
  functionalPlaces: IFunctionalPlaceUserInfoVm[];
}
export interface IPermissionsInfoQuery {
  customerId: string;
}
export function createEmptyPermissionsInfoQuery(): IPermissionsInfoQuery {
  return {
    customerId: "",
  };
}
export interface IFunctionalPlaceUserInfoVm {
  functionalPlace: IFunctionalLocation;
  usersByGroup: IUserByPermissionVm[];
}
export interface IUserByPermissionVm {
  group: IGroup;
  users: IIdentityRef[];
}
export enum BulkAssignModes {
  None = "None",
  Assign = "Assign",
  Remove = "Remove",
}
export interface IBulkAssignVm {
  users: IUser[];
  options: IBulkAssignOption[];
}

export interface IBulkAssignOption {
  functionalPlaces: IFunctionalLocation[];
  contractType: ContractTypes;
  allowedGroups: IAllowedGroups;
}

interface IUserConfirmationStatus {
  isConfirmed: boolean;
  missingConfirmationGrants: number;
  allowedForConfirmationGrant: IIdentityRef[];
}

export enum ContractTypes {
  Silver = "Silver",
  Gold = "Gold",
  Platinum = "Platinum",
  Bas = "BasicRemoteService",
  Karo = "Karo",
  NoActiveContract = "NoActiveContract",
  ActiveContract = "ActiveContract",
  Error = "Error",
  Locked = "Locked",
  Default = "Default",
}
export enum CustomerRelationTypes {
  Agency = "Agency",
  Holding = "Holding",
  None = "None",
}
export interface IUserQuery {
  groupIds: string[];
  customerIds: string[];
  userType: UserType;
  text: string;
  status?: string;
  sorting: IQuerySortingOptions<UserSortableProperties>;
}

export interface IActivityQuery {
  text?: string;
  customerId?: string;
  performedById?: string;
  userId?: string;
  type?: string;
}

export function emptyActivityQuery(): IActivityQuery {
  return {
    text: "",
    customerId: null,
    performedById: null,
    userId: null,
    type: null,
  };
}

export interface IActivityResponse {
  activities: DomainEvents[];
}

export interface IActivitySearchVmResponse {
  activityTypes: string[];
  allowedCustomers: ICustomerRef[];
  allowedUsers: IIdentityRef[];
  allowedPerformedBy: IIdentityRef[];
  canFilterByCustomers: boolean;
}

export interface ICustomerQuery {
  text: string;
  sorting: IQuerySortingOptions<CustomerSortableProperties>;
}

export interface IQuerySortingOptions<T> {
  property: T | undefined;
  isDescending: boolean;
}

export interface ICustomersResponse {
  customers: ICustomerInfo[];
}
export interface ICustomer2InfoResult {
  success: boolean;
  customer?: ICustomerInfo;
}
export interface ICustomerInfo {
  usersCount: number;
  id: string;
  debitorNo: string;
  debitorName: string;
  functionalPlaces: IFunctionalPlaceInfo[];
  customerRelations: ICustomerRelationInfo[];
}
export interface ICustomerRelationInfo {
  id: string;
  debitorNo: string;
  debitorName: string;
  relationType: CustomerRelationTypes;
}
export interface IFunctionalPlaceInfo {
  functionalPlace: IFunctionalLocation;
  contracts: IContract[];
}
export interface IContract {
  id: string;
  contractName: string;
  contractPosition: number;
  description: string;
  beginDate: string;
  endDate?: string;
  rejectionReason: string;
  contractType: ContractTypes;
}
export type CustomerSortableProperties =
  | "DebitorNo"
  | "DebitorName"
  | "UsersCount";

export function emptyCustomerQuery(): ICustomerQuery {
  return {
    text: "",
    sorting: {
      property: "DebitorNo",
      isDescending: false,
    },
  };
}

export type UserSortableProperties = "DisplayName" | "CustomerName" | "Mail";

export function emptyUserQuery(): IUserQuery {
  return {
    userType: null,
    groupIds: [],
    customerIds: [],
    text: "",
    status: "all",
    sorting: {
      property: "DisplayName",
      isDescending: false,
    },
  };
}

export interface ICustomer {
  id: string;
  debitorNo: string;
  debitorName: string;
  displayName: string;
  functionalLocations: IFunctionalLocation[];
}

export interface IFunctionalLocation {
  id: string;
  name: string;
}

export interface ICustomerRef {
  id: string;
  customerIdentifier: string;
  displayName: string;
}

export interface IUser {
  id: string;
  displayName: string;
  givenName: string;
  surname: string;
  mail: string;
  customerId?: string;
  customerName?: string;
  groups: IGroup[];
  isConfirmed: boolean;
  isDeactivated: boolean;
  isAccountEnabled: boolean;
  isLocalAccount: boolean;
  isFederatedAccount: boolean;
  status: UserStatus;
  userAbbreviation?: string;
  approvalInfo: IApprovalInfo;
  lastPasswordChangeTimestamp?: string;
  lastLoginTimestamp?: string;
  hasInvitationAccepted: boolean;
  needsInvitation: boolean;
  invitationAccepted?: string;
  isPasswordResetAvailable: boolean;
  isSendInvitationAvailable: boolean;
  userType: UserType;
  externalTicketId?: string;
  externalTicketUrl?: string;
}

export interface IApprovalInfo {
  missingConfirmations: number;
  alreadyConfirmedGrants: number;
  requiredConfirmationGrants: number;
  canBeConfirmedByUserIds: IIdentityRef[];
  alreadyConfirmedBy: IUserApprovalInfo[];
}

export interface IUserApprovalInfo {
  id: string;
  displayName: string;
  department?: string;
  timestamp: string;
}

export enum UserStatus {
  Unknown = "Unknown",
  Confirmed = "Confirmed",
  Unconfirmed = "Unconfirmed",
  Deactivated = "Deactivated",
}

export enum UserType {
  User = "User",
  CustomerAdmin = "CustomerAdmin",
  SubsidiaryAdmin = "SubsidiaryAdmin",
  InternalUser = "InternalUser",
  Agencies = "Agencies",
}
export interface IAllowedGroups {
  administration: IGroup[];
  viewer: IGroup[];
  brueckner: IGroup[];
  customer: IGroup[];
  ticket: IGroup[];
  other: IGroup[];
}
export const getForGroupDisplay = (
  allowedGroups: IAllowedGroups,
  ids: string[]
): IGroup[] => {
  if (allowedGroups == null || ids == null || ids.length == 0) return [];
  return [
    ...allowedGroups.administration.filter((g) => ids?.includes(g.id)),
    ...allowedGroups.brueckner.filter((g) => ids?.includes(g.id)),
    ...allowedGroups.viewer.filter((g) => ids?.includes(g.id)),
    ...allowedGroups.customer.filter((g) => ids?.includes(g.id)),
    ...allowedGroups.other.filter((g) => ids?.includes(g.id)),
    ...allowedGroups.ticket.filter((g) => ids?.includes(g.id)),
  ];
};
export function getAllowedGroupsFlat(allowedGroups?: IAllowedGroups) {
  return allowedGroups
    ? [
        ...allowedGroups.administration,
        ...allowedGroups.brueckner,
        ...allowedGroups.viewer,
        ...allowedGroups.customer,
        ...allowedGroups.other,
        ...allowedGroups.ticket,
      ]
    : [];
}
export const createEmptyAllowedGroups = (): IAllowedGroups => {
  return {
    administration: [],
    viewer: [],
    brueckner: [],
    customer: [],
    ticket: [],
    other: [],
  };
};
export interface IGroup {
  id: string;
  displayName: string;
  description: string;
  isAvailable: boolean;
  notAvailable: boolean;
}

export interface IDashboardResponse {
  unconfirmedUsers: IUser[];
  longInactiveUsers: IUser[];
  usersToRevalidate: IUser[];
}

export interface IConfirmUserResponse {
  success: boolean;
}

export interface IDeleteUserResponse {
  success: boolean;
}

export interface IDeactivateUserResponse {
  success: boolean;
}

export interface IActivateUserResponse {
  success: boolean;
}

export interface IApprovableQueryResultData {
  approvables: IUser[];
  toRevalidate: IUser[];
}
export interface ICreateUserDataVm {
  surname: string;
  givenName: string;
  email: string;
  groups: IGroup[];
  customers: ITag[];
  confirm: boolean;
  confirmComment?: string;
  isCustomerAdmin: boolean;
  isFederatedUser: boolean;
  userAbbreviation: string;
}

export interface ICreateUserRequest {
  displayName: string;
  surname: string;
  givenName: string;
  email: string;
  groupIds: string[];
  customerId: string;
  confirm: boolean;
  confirmComment?: string;
  isCustomerAdmin: boolean;
  isFederatedUser: boolean;
  userAbbreviation: string;
}

export interface ICreateUserResponse {
  user?: IUser;
  errors?: IError[];
  success: boolean;
}

export interface IInternalUserResponse {
  user?: IUser;
  errors?: IError[];
  success: boolean;
}

export interface IError {
  message: string;
}

export function toCreateUserRequest(vm: ICreateUserDataVm): ICreateUserRequest {
  return Object.assign({}, vm, {
    groupIds: _.map(vm.groups, (g) => g.id),
    customerId: (vm.customers ?? [])[0]?.key.toString(),
    displayName: `${vm.givenName} ${vm.surname}`,
  });
}

export interface ILastCreatedUsersByMeResponse {
  data: IUserCreated[];
}

export interface ILastActivitiesResponse {
  data: DomainEvents[];
}

export type DomainEventTypes =
  | "UserCreated"
  | "UserConfirmed"
  | "UserActivated"
  | "UserDeactivated"
  | "UserDeleted"
  | "UserGroupAssignmentsChanged"
  | "UserPasswordReset"
  | "UserConfirmGranted"
  | "UserAccountActivated"
  | "UserAbbreviationChanged"
  | "UserInvitationCompleted"
  | "UserPasswordResetTriggered"
  | "UserPasswordResetCompleted"
  | "UserInvitationStarted";
export type DomainEvents =
  | IUserCreated
  | IUserConfirmed
  | IUserActivated
  | IUserDeactivated
  | IUserDeleted
  | IUserGroupAssignmentsChanged
  | IUserConfirmGranted
  | IUserAccountActivated
  | IUserAbbreviationChanged
  | IUserInvitationCompleted
  | IUserPasswordResetTriggered
  | IUserPasswordResetCompleted
  | IUserInvitationStarted;

interface IDomainEvent {
  id: string;
  aggregateId: string;
  type: DomainEventTypes;
  timestamp: string;
  identity: IIdentityRef;
  identityId: string;
  customerId?: string;
}

export interface IUserDomainEvent extends IDomainEvent {
  user: IIdentityRef;
}

interface IUserCreatedData {
  isConfirmed: boolean;
  groups: IIdentityRef[];
}

export interface IUserCreated extends IUserDomainEvent {
  type: "UserCreated";
  data: IUserCreatedData;
}

export interface IUserConfirmed extends IUserDomainEvent {
  type: "UserConfirmed";
}

export interface IUserDeactivated extends IUserDomainEvent {
  type: "UserDeactivated";
}

export interface IUserActivated extends IUserDomainEvent {
  type: "UserActivated";
}

export interface IUserDeleted extends IUserDomainEvent {
  type: "UserDeleted";
}

export interface IUserConfirmGranted extends IUserDomainEvent {
  type: "UserConfirmGranted";
  comment: string;
}

export interface IUserAccountActivated extends IUserDomainEvent {
  type: "UserAccountActivated";
}

interface IUserGroupAssignmentsChangedData {
  addedTo: IGroup[];
  removedFrom: IGroup[];
  functionalLocation?: IFunctionalLocation;
}

export interface IUserGroupAssignmentsChanged extends IUserDomainEvent {
  type: "UserGroupAssignmentsChanged";
  data: IUserGroupAssignmentsChangedData;
}

export interface IUserPasswordChanged extends IUserDomainEvent {
  type: "UserPasswordReset";
}

interface IUserAbbreviationChangedData {
  oldAbbreviation: string;
  newAbbreviation: string;
}

export interface IUserAbbreviationChanged extends IUserDomainEvent {
  type: "UserAbbreviationChanged";
  data: IUserAbbreviationChangedData;
}

export interface IUserInvitationStarted extends IUserDomainEvent {
  type: "UserInvitationStarted";
}

export interface IUserInvitationCompleted extends IUserDomainEvent {
  type: "UserInvitationCompleted";
}

export interface IUserPasswordResetTriggered extends IUserDomainEvent {
  type: "UserPasswordResetTriggered";
}

export interface IUserPasswordResetCompleted extends IUserDomainEvent {
  type: "UserPasswordResetCompleted";
}

export interface IIdentityRef {
  id: string;
  displayName: string;
}
export interface IIdentityRefWithCustomerRef extends IIdentityRef {
  customer?: ICustomerRef;
}

export type DomainErrors =
  | IErrorMessage
  | IDeletedError
  | INotFoundError
  | INotAuthorizedError;

interface IDomainError {
  type: string;
}

export interface IResultBase {
  success: boolean;
  errors?: DomainErrors[];
}

export interface IResultWithPayloadBase<T> extends IResultBase {
  data: T;
}

export interface IPasswordResetResult extends IResultBase {}

interface IErrorMessage extends IDomainError {
  type: "ErrorMessage";
  message: string;
}

interface IDeletedError extends IDomainError {
  type: "Deleted";
}

interface INotFoundError extends IDomainError {
  type: "NotFound";
}

interface INotAuthorizedError extends IDomainError {
  type: "NotAuthorized";
}
