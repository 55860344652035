import { CommandBar, ICommandBarItemProps, Stack } from "@fluentui/react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import useSWR from "swr";
import { Card } from "../components/Card";
import { ContentWrapper } from "../shared/ContentWrapper";
import { getPortalPermissions } from "../shared/authService";
import { PermissionsFunctionalPlaceCard } from "./PermissionsFunctionalPlaceCard";
import { permissionsService } from "./permissionsService";
export const PermissionsFunctionalPlace: React.FC<{}> = () => {

    const [t] = useTranslation();
    let { fpId } = useParams<{ fpId: string }>();
    let navigate = useNavigate();
    let { data: vm, mutate } = useSWR(`/api/v2/permissions/fp/${fpId}`, () => permissionsService.getPermissionsInfoByFpId(fpId));
    let permissions = useMemo(() => getPortalPermissions(), []);
    let commands: ICommandBarItemProps[] = useMemo(() => {
        let commandsTmp = [{
            key: "back",
            text: t("common.back"),
            iconProps: { iconName: "Back" },
            onClick: () => navigate(-1)
        }];
        if (permissions.editUser) {
            commandsTmp.push({
                key: "bulkassign",
                text: t("Bulk Assign"),
                iconProps: { iconName: "Group" },
                onClick: () => navigate(`/permissions/assign`),

            })
        }
        return commandsTmp;
    }, [permissions]);

    return <ContentWrapper>
        <Stack>
            <Stack.Item style={{ marginBottom: "1rem" }}>
                <Card >
                    <CommandBar items={commands}>
                    </CommandBar>
                </Card>
            </Stack.Item>
            <Stack.Item>
                {vm?.functionalPlaceInfo &&
                    <Stack.Item><PermissionsFunctionalPlaceCard fpInfo={vm.functionalPlaceInfo} /></Stack.Item>
                }
            </Stack.Item>
        </Stack>

    </ContentWrapper >


}






