import React from "react";
import { ConstrainMode, DetailsList, DetailsListLayoutMode, IColumn, IDetailsListStyles, SelectionMode, Spinner, SpinnerSize } from "@fluentui/react";

const gridStyles: Partial<IDetailsListStyles> = {
    root: {
        overflowX: 'auto',
        height: "100%",
        width: "100%",
        selectors: {
            '& [role=grid]': {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'start',
                height: '100%',
                width: "100%",
                minHeight: 0
            },
        },
    },
    headerWrapper: {
        flex: '0 0 auto',
    },
    contentWrapper: {
        flex: '1 1 auto',
        overflowY: 'auto',
        overflowX: 'hidden',
    },
};

export const DataList: React.FC<{ items: any[], columns: IColumn[], disabled?: boolean }> = ({ items, columns, disabled = false }) => {

    return disabled ?
        <div>
            <DetailsList styles={gridStyles} selectionMode={SelectionMode.none} items={[]} columns={columns} layoutMode={DetailsListLayoutMode.fixedColumns} constrainMode={ConstrainMode.unconstrained} />
            <Spinner size={SpinnerSize.large} />
        </div>
        :
        <DetailsList styles={gridStyles} selectionMode={SelectionMode.none} items={items ?? []} columns={columns} layoutMode={DetailsListLayoutMode.fixedColumns} constrainMode={ConstrainMode.unconstrained} />
        
}
