import {
  ActionButton,
  Breadcrumb,
  CommandBar,
  IBreadcrumbItem,
  IColumn,
  ICommandBarItemProps,
  TextField,
} from "@fluentui/react";
import _, { debounce } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { History } from "history";
import { Card } from "../components/Card";
import { DataList } from "../shared/DataList";
import {
  emptyCustomerQuery,
  IContract,
  ICustomerInfo,
  ICustomerQuery,
  ICustomersResponse,
  IConfig,
} from "../shared/models";
import { customerService } from "./customerService";
import { NavigateFunction, useNavigate } from "react-router-dom";
import styles from "./Customers.module.css";
import commonStyles from "../shared/common.module.css";
import {
  formatDate,
  formatFunctionalPlace,
  getNewSorting,
  getTagColorClassName,
  getTagContent,
} from "../shared/CommonUi";
import { TFunction } from "i18next";
import { SpacerTag, Tag } from "../shared/Tag";
import { getConfig } from "../shared/authService";

const executeSearch = debounce(
  async (
    query: ICustomerQuery,
    callback: (response: ICustomersResponse) => void
  ) => {
    let result = await customerService.getAll(query);
    callback(result);
  },
  500
);

export const Customers: React.FC = () => {
  let [t] = useTranslation();
  let navigate = useNavigate();
  let [query, setQuery] = useState(emptyCustomerQuery());
  const config = getConfig();
  let [customersResponse, setCustomersResponse] =
    useState<ICustomersResponse>(null);
  let commands = useMemo(
    () =>
      getCommands(t, navigate, () => {
        setQuery(emptyCustomerQuery());
      }),
    [t, navigate, setQuery]
  );
  let breadcrumbs = useMemo(() => getBreadcrumbs(t), [t]);

  let queryWith = useCallback(
    (queryPart: Partial<ICustomerQuery>): void => {
      let newQuery = Object.assign({}, query, queryPart);
      setQuery(newQuery);
    },
    [query]
  );

  let customersColumns = useMemo(
    () => getCustomersColumns(t, query, queryWith, navigate,config),
    [t, query, queryWith]
  );
  useEffect(() => {
    executeSearch(query, (response) => setCustomersResponse(response));
  }, [query]);

  return (
    <div className={styles.content}>
      <Breadcrumb
        className={commonStyles.breadcrumb}
        style={{ marginBottom: 20 }}
        items={breadcrumbs}
      />
      <Card className={styles.commandCard}>
        <CommandBar items={commands}></CommandBar>
      </Card>
      <Card
        className={styles.filterCard}
        contentClassName={styles.filterCard_content}
      >
        <TextField
          label={t("customers.filters.text")}
          onChange={(ev, text) => queryWith({ text })}
          value={query.text}
        />
      </Card>
      <Card title={t("common.customers")} className={styles.resultCard}>
        <DataList
          columns={customersColumns}
          items={customersResponse?.customers}
        />
      </Card>
    </div>
  );
};
const renderCustomerInfo = (item: ICustomerInfo) => {
  const renderContracts = (contracts: IContract[]) => {
    return contracts.length == 0 ? (
      <SpacerTag />
    ) : (
      <Tag colorClassName={getTagColorClassName(contracts[0])}>
        {getTagContent(contracts[0])}
      </Tag>
    );
  };

  return (
    <div className={styles.fpListWrapper}>
      {_.map(item.functionalPlaces, (fc, idx) => (
        <div className={styles.fpListItem}>
          {formatFunctionalPlace(fc.functionalPlace)}
          {renderContracts(fc.contracts)}
        </div>
      ))}
    </div>
  );
};
function getCustomersColumns(
  t: TFunction,
  query: ICustomerQuery,
  queryWith: (queryPart: Partial<ICustomerQuery>) => void,
  navigate: NavigateFunction,
  config: IConfig
): IColumn[] {
  const columns : IColumn[]= [
    {
      key: "DebitorNo",
      name: t("customers.tableHeaders.debitorNo"),
      minWidth: 150,
      maxWidth: 220,
      onRender: (item: ICustomerInfo) => item.debitorNo,
      isSorted: query.sorting.property === "DebitorNo",
      isSortedDescending: query.sorting.isDescending,
      onColumnClick: (_, c) =>
        queryWith({ sorting: getNewSorting(query.sorting, c) }),
    },
    {
      key: "DebitorName",
      name: t("customers.tableHeaders.debitorName"),
      minWidth: 300,
      maxWidth: 400,
      onRender: (item: ICustomerInfo) => item.debitorName,
      isSorted: query.sorting.property === "DebitorName",
      isSortedDescending: query.sorting.isDescending,
      onColumnClick: (_, c) =>
        queryWith({ sorting: getNewSorting(query.sorting, c) }),
    },
    {
      key: "FuncLocations",
      name: t("customers.tableHeaders.functionalPlaces"),
      minWidth: 100,
      maxWidth: 300,
      onRender: renderCustomerInfo,
    },
  ];

  const columns2 : IColumn[] = [
    {
      key: "UsersCount",
      name: t("customers.tableHeaders.usersCount"),
      minWidth: 150,
      maxWidth: 150,
      onRender: (item: ICustomerInfo) => item.usersCount,
      isSorted: query.sorting.property === "UsersCount",
      isSortedDescending: query.sorting.isDescending,
      onColumnClick: (_, c) =>
        queryWith({ sorting: getNewSorting(query.sorting, c) }),
    },
    {
      key: "Action",
      name: "",
      minWidth: 150,
      maxWidth: 150,
      onRender: (item: ICustomerInfo) => {
        return (
          <ActionButton
            iconProps={{ iconName: "NavigateExternalInline" }}
            onClick={() => {
              navigate(`/customers/${item.id}`);
            }}
          />
        );
      },
    },
  ];
  const validColumn : IColumn[]= [
    {
      key: "Valid",
      name: t("customers.tableHeaders.valid"),
      minWidth: 150,
      maxWidth: 150,
      onRender: (item: ICustomerInfo) =>
        _.map(item.functionalPlaces, (fp) => (
          <>
            {fp.contracts.length > 0 ? (
              <div className={styles.fpListItem}>
                {`${formatDate(fp.contracts[0].beginDate, "")} - ${formatDate(
                  fp.contracts[0].endDate
                )}`}
                <SpacerTag />
              </div>
            ) : (
              <div className={styles.fpListItem}>
                -
                <SpacerTag />
              </div>
            )}
          </>
        )),
    },
  ];
  return config.app.featureFlags.customerListShowContractValidDate
    ? [...columns, ...validColumn, ...columns2]
    : [...columns, ...columns2];
}

function getBreadcrumbs(t: TFunction): IBreadcrumbItem[] {
  return [
    {
      text: t("common.customers"),
      key: "1",
    },
  ];
}

function getCommands(
  t: TFunction,
  navigate: NavigateFunction,
  onResetFilter: () => void
): ICommandBarItemProps[] {
  let result = [];
  result.push({
    key: "reset",
    text: t("users.commands.resetSearch"),
    iconProps: { iconName: "ClearFilter" },
    onClick: onResetFilter,
  });

  return result;
}
