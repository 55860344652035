import { Icon, MessageBar, MessageBarType } from "@fluentui/react";
import React, { } from "react";
import _ from "lodash";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { IEditUserVmResponse } from "../shared/models";

export const StatusMessages: React.FC<{ vm: IEditUserVmResponse }> = ({ vm }) => {
    let [t] = useTranslation();

    if (!vm) {
        return null;
    }

    let notAuthorized = false;
    let notFound = false;
    let isDeleted = false;

    _.forEach(vm.errors, error => {
        switch (error.type) {
            case "Deleted":
                isDeleted = true;
                break;
            case "NotFound":
                notFound = true;
                break;
            case "NotAuthorized":
                notAuthorized = true;
                break;
        }
    });

    if (isDeleted) {
        return <MessageBar messageBarType={MessageBarType.error} >{t("editUser.statusMessages.deleted")}</MessageBar>
    }
    if (notAuthorized) {
        return <MessageBar messageBarType={MessageBarType.error} >{t("editUser.statusMessages.notAuthorized")}</MessageBar>
    }
    if (notFound) {
        return <MessageBar messageBarType={MessageBarType.error} >{t("editUser.statusMessages.notFound")}</MessageBar>
    }

    let user = vm.user;

    if (user.isDeactivated) {
        return <MessageBar messageBarType={MessageBarType.severeWarning} >{t("editUser.statusMessages.deactivated")}</MessageBar>
    }

    if (!user.isConfirmed) {
        return <MessageBar messageBarType={MessageBarType.severeWarning} >{t("editUser.statusMessages.notConfirmed")}</MessageBar>
    }

    if ((!user.groups || user.groups.length === 0) && vm.functionalPlaces.every(fp => fp.permissions.length === 0)) {
        return <MessageBar messageBarType={MessageBarType.severeWarning} >{t("editUser.statusMessages.noGroups")}</MessageBar>
    }

    return <MessageBar messageBarType={MessageBarType.success} >{t("editUser.statusMessages.noProblems")}</MessageBar>

}