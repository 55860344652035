import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./UserInfo.module.css";
import {IUser} from "./models";
import {formatUserGroups} from "./CommonUi";

export const UserInfo: React.FC<{ user: IUser, style?: React.CSSProperties }> = ({ user, style }) => {
    let [t] = useTranslation();

    if (user == null) {
        return null;
    }
    return (
        <table className={styles.table} style={style} cellPadding="0" cellSpacing="0">
            <tbody>
                <tr><td className={styles.description}>{t("userInfo.name")}:</td><td className="value">{user.displayName}</td></tr>
                <tr><td className={styles.description}>{t("userInfo.customerNo")}:</td><td className="value">{user.customerId ?? "-"}</td></tr>
                <tr><td className={styles.description}>{t("userInfo.mail")}:</td><td className="value">{user.mail ?? "-"}</td></tr>
                <tr><td className={styles.description}>{t("userInfo.groups")}:</td><td className="value">{formatUserGroups(user)}</td></tr>
                <tr><td className={styles.description}>{t("userInfo.userAbbreviation")}:</td><td className="value">{user.userAbbreviation ?? ""}</td></tr>
            </tbody>
        </table>)
}