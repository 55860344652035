import React, { useMemo } from "react";
import { useId } from "@fluentui/react-hooks";
import { IPickerItemProps, ITag, Label, TagPicker } from "@fluentui/react";
import _ from "lodash";

export const CustomPicker: React.FC<ICustomerPickerProps> = ({ label, tags, onSelectItems, value, onRenderItem, onFilterSuggestion, limit, disabled }) => {
    tags = tags ?? [];
    let pickerId = useId("pickerId");
    let filterFunc = useMemo(() => onFilterSuggestion ?? ((filter: string, o: ITag) => o.name.toLowerCase().includes(filter?.toLocaleLowerCase())), [onFilterSuggestion]);
    return (
        <>
            <Label htmlFor={pickerId}>{label}</Label>
            <TagPicker
                itemLimit={limit}
                selectedItems={value}
                inputProps={{ id: pickerId }}
                onEmptyResolveSuggestions={(selectedItems) => {
                    return _.difference(tags, selectedItems);
                }}
                onResolveSuggestions={(filter, selectedItems) => {
                    let available = _.difference(tags, selectedItems);
                    let filtered = _.filter(available, o => filterFunc(filter, o));
                    return filtered;
                }}
                onChange={items => onSelectItems(_.filter(tags, g => _.some(items, i => i.key === g.key)))}
                onRenderItem={onRenderItem}
                disabled={disabled}
            />
        </>)
}
interface ICustomerPickerProps {
    label: string,
    tags: ITag[],
    onSelectItems: (tags: ITag[]) => void,
    value?: ITag[],
    onRenderItem?: (props: IPickerItemProps<ITag>) => JSX.Element,
    onFilterSuggestion?: (filter: string, item: ITag) => boolean,
    limit?: number,
    disabled?: boolean
}