import { httpClient } from "../shared/httpClient";
import { DomainEvents, IActivityQuery, IActivityResponse, IActivitySearchVmResponse, ILastActivitiesResponse, ILastCreatedUsersByMeResponse, IUserCreated } from "../shared/models";


class ActivityService {
    async getMyLastCreatedUsers(): Promise<IUserCreated[]> {
        let result = await httpClient.get<ILastCreatedUsersByMeResponse>("/api/activities/me/lastcreatedusers");
        return result.data.data;
    }
    async getLatest(): Promise<DomainEvents[]> {

        let result = await httpClient.get<ILastActivitiesResponse>("/api/activities/latest");
        return result.data.data;
    }

    async getAll(query: IActivityQuery): Promise<IActivityResponse> {
        let result = await httpClient.post<IActivityResponse>("/api/activities/queries", query);
        return result.data;
    }

    async getSearchVm(): Promise<IActivitySearchVmResponse> {
        let result = await httpClient.post<IActivitySearchVmResponse>("/api/activities/vms/search");
        return result.data;
    }
}

export const activityService = new ActivityService();
