import React from "react";
import {
    IColumn,
    Icon,
    IStackTokens,
    ITag,
} from "@fluentui/react";
import {
    ContractTypes,
    DomainEvents,
    DomainEventTypes,
    IContract,
    ICustomer,
    ICustomerRef,
    IFunctionalLocation,
    IGroup,
    IIdentityRef,
    IPortalPermissions,
    IQuerySortingOptions,
    IUser,
    IUserDomainEvent,
    UserStatus,
    UserType
} from "./models";
import { TFunction } from "i18next";
import moment from "moment";
import _ from "lodash";
import styles from "./common.module.css";
import contractColors from "./contracttypescolors.module.css";
export const defaultTokens: IStackTokens = { childrenGap: 20 };
export const cardTokens: IStackTokens = { childrenGap: 25 };

export const addIcon = { iconName: "AddFriend" };
export const groupIcon = { iconName: "AddGroup" };
export const reportIcon = { iconName: "ReportDocument" };
export const confirmIcon = { iconName: "CheckMark" };
export const deleteIcon = { iconName: "Delete" };
export const deactivateIcon = { iconName: "BlockContact" };
export const resetPasswordIcon = { iconName: "PasswordField" };
export const backIcon = { iconName: "Back" };
export const resetFilter = { iconName: "ClearFilter" };

export function dateForFileName() {
    return moment().format("yyyyMMDD_HHmm");
}

export function getDefaultUserColumns(t: TFunction, permissions: IPortalPermissions): IColumn[] {
    let items: IColumn[] = [
        {
            key: "DisplayName",
            name: t("defaultUserTable.tableHeaders.name"),
            minWidth: 150,
            maxWidth: 160,
            onRender: (item: IUser) => item.displayName,
        },
        {
            key: "CustomerName",
            name: t("defaultUserTable.tableHeaders.customer"),
            minWidth: 130,
            maxWidth: 160,
            onRender: (item: IUser) => getCustomerDisplay(item),
        },
        {
            key: "status",
            name: t("defaultUserTable.tableHeaders.status"),
            minWidth: 140,
            maxWidth: 140,
            isMultiline: true,
            onRender: (item: IUser) => renderUserStatus(item, t),
        },
        {
            key: "Mail",
            name: t("defaultUserTable.tableHeaders.mail"),
            minWidth: 200,
            maxWidth: 200,
            onRender: (item: IUser) => item.mail,
        },
        {
            key: "groups",
            name: t("defaultUserTable.tableHeaders.groups"),
            minWidth: 160,
            isMultiline: true,
            onRender: (item: IUser) => formatUserGroups(item),
        },
        {
            key: "UserType",
            name: t("defaultUserTable.tableHeaders.userType"),
            minWidth: 100,
            maxWidth: 100,
            onRender: (item: IUser) => t(`userTypes.${item.userType}`),
        }
    ];

    if (permissions.confirmUser) {
        items.push({
            key: "confirmations",
            name: t("defaultUserTable.tableHeaders.confirmations"),
            minWidth: 140,
            isMultiline: true,
            onRender: (item: IUser) => formatConfirmations(item, t)
        });
    }

    return items;
}

function formatConfirmations(user: IUser, t: TFunction) {
    let ai = user.approvalInfo;
    return (<ul className={styles.ul}>
        {user.status !== UserStatus.Confirmed && user.status !== UserStatus.Deactivated && ai.missingConfirmations > 0 && (<li> <Icon style={{ color: "orange" }} iconName="Warning" /> {t("defaultUserTable.formats.confirmationInfo", { approved: ai.alreadyConfirmedGrants, required: ai.requiredConfirmationGrants })}</li>)}
        {ai.alreadyConfirmedBy?.length > 0 && ai.alreadyConfirmedBy.map(info => {
            return <li key={`${info.id}${info.timestamp}`}><Icon
                iconName="CheckMark" style={{ marginRight: "0.25rem" }} />{info.displayName} {info.department != null && `(${info.department})`}
            </li>;
        })}
    </ul>);

}


export const StatusIconOptions = {
    deactivated: { name: "WarningSolid", color: "red" },
    confirmed: { name: "BoxCheckmarkSolid", color: "#00bb00" },
    unconfirmed: { name: "WarningSolid", color: "orange" },
};


function renderUserStatus(item: IUser, t: TFunction) {
    switch (item.status) {
        case UserStatus.Deactivated:
            return <IconWithText iconName={StatusIconOptions.deactivated.name}
                iconColor={StatusIconOptions.deactivated.color} text={t("common.deactivated")} />;
        case UserStatus.Confirmed:
            return (<><IconWithText iconName={StatusIconOptions.confirmed.name}
                iconColor={StatusIconOptions.confirmed.color} text={t("common.confirmed")} />
                {item.isLocalAccount && (<table className={styles.infoTable} cellSpacing={1}>
                    <tbody>
                        <tr>
                            <td>{t("common.lastLogin")}:</td>
                            <td>{formatDateShort(item.lastLoginTimestamp, t("common.lastLoginNever"))}</td>
                        </tr>
                        <tr>
                            <td>{t("common.lastPasswordChange")}:</td>
                            <td>{formatDateShort(item.lastPasswordChangeTimestamp)}</td>
                        </tr>

                    </tbody>
                </table>)}
            </>);
        case UserStatus.Unconfirmed:
            return <IconWithText iconName={StatusIconOptions.unconfirmed.name}
                iconColor={StatusIconOptions.unconfirmed.color} text={t("common.unconfirmed")} />;
    }
}

export const IconWithText: React.FC<{ text?: string, iconName: string, iconColor?: string }> = ({
    text,
    iconName,
    iconColor
}) => {
    return (<div style={{ display: "flex", alignItems: "center" }}>
        <Icon style={{ color: iconColor, marginRight: "0.25rem" }} iconName={iconName} />
        <span style={{ flex: 1 }}>{text}</span>
    </div>);
};

export function formatDateWithTime(text: string): string {
    return moment(text).format("YYYY-MM-DD HH:mm");
}

export function formatDateShort(text: string, defaultText: string = ""): string {
    if (!text) {
        return defaultText;
    }
    let d = moment(text);
    if (d.isValid()) {
        return d.format("YY-MM-DD");
    }

    return "INVALID";
}
export function formatDate(text: string, defaultText: string = ""): string {
    if (!text) {
        return defaultText;
    }
    let d = moment(text);
    if (d.isValid()) {
        return d.format("YYYY-MM-DD");
    }

    return "INVALID";
}

export function formatDomainEventType(t: TFunction, type: DomainEventTypes): string {
    return t(`common.domainEventTypes.${type}`);
}

export function formatDomainEventUserName(ev: IUserDomainEvent): string {
    return ev.user?.displayName;
}

export function formatDomainEventCustomer(ev: DomainEvents): string {
    return ev.customerId;
}

export function formatDomainEventPerformedBy(ev: DomainEvents): string {
    return ev.identity.displayName;
}
export function formatFunctionalPlace(fp: IFunctionalLocation): string {
    return `${fp.name} (${fp.id})`;
}

export function getUserTypeTags(userTypes: UserType[], t: TFunction): ITag[] {

    return _.map(userTypes, ut => ({ key: ut, name: t(`userTypes.${ut}`) }));
}
export function getTagColorClassName(contract: IContract) :string{
    switch (contract.contractType) {
        case ContractTypes.Silver:
            return contractColors.silver;
        case ContractTypes.Gold:
            return contractColors.gold;
        case ContractTypes.Platinum:
            return contractColors.platinum;
        case ContractTypes.Error:
            break;
        default:
            return contractColors.default;
    }
}
export function getTagContent(contract: IContract) :string{
    switch (contract.contractType) {
        case ContractTypes.Silver:
            return "Silver";
        case ContractTypes.Gold:
            return "Gold";
        case ContractTypes.Platinum:
            return "Platinum";
        case ContractTypes.Karo:
            return "Karo";
        case ContractTypes.Bas:
            return "BAS";
        case ContractTypes.ActiveContract:
            return "Active";
        case ContractTypes.NoActiveContract:
            return "No Contract";
        case ContractTypes.Error:
        default:
            return "Default";
    }
}

export function getCustomerTags(customers: ICustomerRef[]): ITag[] {
    return _.map(customers, g => Object.assign(g, { key: g.customerIdentifier, name: `${g.displayName} (${g.customerIdentifier})` }));
}
export function getIdentityTags(idRefs: IIdentityRef[]): ITag[] {
    return _.map(idRefs, r => ({ key: r.id, name: `${r.displayName}` }));
}
export function getUserTags(users: IUser[]): ITag[] {
    return _.map(users, r => ({ key: r.id, name: `${r.displayName}` }));
}
export function getFunctionalPlaceTags(users: IFunctionalLocation[]): ITag[] {
    return _.map(users, r => ({ key: r.id, name: `${formatFunctionalPlace(r)}` }));
}

export function getGroupTags(groups: IGroup[]): ITag[] {
    return _.map(groups, g => Object.assign(g, { key: g.id, name: g.displayName }));
}

export function getCustomerDisplay(item: IUser): string {
    if (!item.customerId && !item.customerName) {
        return null;
    }

    let parts = [];
    if (item.customerName) {
        parts.push(item.customerName);
    }
    if (item.customerId) {
        parts.push(`(${item.customerId})`);
    }

    return _.join(parts, " ");
}


export function formatUserGroups(user: IUser): string {
    if (user?.groups == null) {
        return "-";
    }

    return user.groups.map((g) => g.displayName).join(", ");
}

export const ErrorLabel: React.FC<{ message: string }> = ({ message }) => {
    return <div style={{ height: "1rem", color: "rgb(164, 38, 44)", fontSize: "12px" }}><label>{message}</label>
    </div>;

};

export function groupDescription(t: TFunction, group: IGroup) {
    if (group.notAvailable) {
        return t(`adGroups.notYetAvailable`);
    }
    return t(`adGroups.descriptions.${group.displayName}`, { defaultValue: group.description ?? t("adGroups.noDescription") });
}

export function getNewSorting<T>(old: IQuerySortingOptions<T>, column: IColumn): IQuerySortingOptions<T> {
    let isDescending = (old.property as any) === column.key ? !old.isDescending : false;
    return { property: column.key as any, isDescending: isDescending };
}

export function downloadFileBlob(content: Blob, fileName: string): void {
    if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
        // for IE
        (window.navigator as any).msSaveOrOpenBlob(content, fileName);
    } else {
        const href = window.URL.createObjectURL(content);
        if (isiOS()) {
            window.open(href, "_self");
            return;
        }
        const a = document.createElement("a");
        document.body.appendChild(a);
        a.style.display = "none";
        a.download = `${fileName}`;
        a.href = href;
        a.click();
        window.URL.revokeObjectURL(href);
        a.remove();
    }
}

export function downloadFileUrl(sUrl: string): boolean {
    if (!sUrl) {
        return;
    }
    const isChrome = navigator.userAgent.toLowerCase().indexOf("chrome") > -1;
    const isSafari = navigator.userAgent.toLowerCase().indexOf("safari") > -1;
    // iOS devices do not support downloading. We have to inform user about this.
    if (isiOS()) {
        // alert('Your device does not support files downloading. Please try again in desktop browser.');
        window.open(sUrl, "_blank");
        return false;
    }

    // If in Chrome or Safari - download via virtual link click
    if (isChrome || isSafari) {
        // Creating new link node.
        const link = document.createElement("a");
        link.href = sUrl;
        link.setAttribute("target", "_blank");

        if (link.download !== undefined) {
            // Set HTML5 download attribute. This will prevent file from opening if supported.
            const fileName = sUrl.substring(sUrl.lastIndexOf("/") + 1, sUrl.length);
            link.download = fileName;
        }

        // Dispatching click event.
        if (document.createEvent) {
            const e = document.createEvent("MouseEvents");
            e.initEvent("click", true, true);
            link.dispatchEvent(e);
            return true;
        }
    }

    // Force file download (whether supported by server).
    if (sUrl.indexOf("?") === -1) {
        sUrl += "?download";
    }

    window.open(sUrl, "_blank");
    return true;
}

function isiOS() {
    const ua = window.navigator.userAgent;
    if (/(iP)/g.test(ua)) {
        return true;
    }
    if (ua.indexOf("iPad") > -1) {
        return true;
    }

    if (ua.indexOf("Macintosh") > -1) {
        // WTF APPLE?! Seit iPadOS 13 werden alle iPads mit einem Standard Mac UserAgent zurück gemeldet. Weil "desktop class browsing" sales bullshit und so.
        // es bleiben also nur noch Workarounds um ein Touch Gerät zu erkennen
        // Achtung: Wenn doch mal Touch Macs erscheinen können hier false positives zurück kommen!
        try {
            document.createEvent("TouchEvent");
            return true;
        } catch (e) {
        }
    }

    return false;
}