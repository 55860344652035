import { ActivityItem, Icon, MessageBar, Stack } from "@fluentui/react";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { DomainEvents, IUserAbbreviationChanged, IUserAccountActivated, IUserActivated, IUserConfirmed, IUserConfirmGranted, IUserCreated, IUserDeactivated, IUserDeleted, IUserGroupAssignmentsChanged, IUserInvitationCompleted, IUserInvitationStarted, IUserPasswordResetCompleted, IUserPasswordResetTriggered } from "../shared/models";
import _ from "lodash";
import { Link } from "react-router-dom";
import moment from "moment";
import { addIcon, defaultTokens, formatFunctionalPlace } from "./CommonUi";
import { useInterval } from "react-use";
import styles from "./ActivitiesDisplay.module.css";
import { TFunction } from "i18next";

export const ActivitiesDisplay: React.FC<{ events: DomainEvents[], noActivitiesText?: string }> = ({ events, noActivitiesText }) => {
    let [t] = useTranslation();

    const forceUpdate = React.useReducer(() => ({}), {})[1] as () => void
    useInterval(() => {
        // Update timestamp ...seconds ago
        forceUpdate();
    }, 60000);

    if (!events || events.length === 0) {
        return <MessageBar>{noActivitiesText ?? t("activitiesDisplay.noActivities")}</MessageBar>;
    }

    return (
        <Stack tokens={defaultTokens}>
            {events.map(ev => {
                let timestampComponent = getTimestampDisplay(ev, t);
                switch (ev.type) {
                    case "UserCreated":
                        return getDisplayForUserCreated(ev, timestampComponent, t);
                    case "UserConfirmed":
                        return getDisplayForUserConfirmed(ev, timestampComponent, t);
                    case "UserActivated":
                        return getDisplayForUserActivated(ev, timestampComponent, t);
                    case "UserDeactivated":
                        return getDisplayForUserDeactivated(ev, timestampComponent, t);
                    case "UserDeleted":
                        return getDisplayForUserDeleted(ev, timestampComponent, t);
                    case "UserGroupAssignmentsChanged":
                        return getDisplayForUserGroupAssignmentsChanged(ev, timestampComponent, t);
                    case "UserAbbreviationChanged":
                        return getDisplayForUserAbbreviationChanged(ev, timestampComponent, t);
                    case "UserInvitationCompleted":
                        return getDisplayForUserInvitationCompleted(ev, timestampComponent, t);
                    case "UserConfirmGranted":
                        return getDisplayForUserConfirmGranted(ev, timestampComponent, t);
                    case "UserAccountActivated":
                        return getDisplayForUserAccountActivated(ev, timestampComponent, t);
                    case "UserPasswordResetTriggered":
                        return getDisplayForUserPasswordResetTriggered(ev, timestampComponent, t);
                    case "UserPasswordResetCompleted":
                        return getDisplayForUserPasswordResetCompleted(ev, timestampComponent, t);
                    case "UserInvitationStarted":
                        return getDisplayForUserInvitationStarted(ev, timestampComponent, t);
                    default:
                        console.log("Unknown Event", (ev as any).type);
                        return null;
                }
            })}
        </Stack>)
}

function getTimestampDisplay(ev: DomainEvents, t: TFunction) {
    let now = moment();
    let timestamp = moment(ev.timestamp);
    let dayDiff = now.diff(timestamp, "days");
    let diff = now.diff(timestamp, "minutes");

    let timeStr = dayDiff < 1 ? timestamp.fromNow() : timestamp.format("yyyy-MM-DD");

    return <div className={styles.timestampDisplay}><span className={styles.timestampDisplay_time}>{timeStr}</span> {diff < 15 && (<span className={styles.timestampDisplay_indicator}>{t("common.new")}</span>)}</div>
}

function getDisplayForUserCreated(ev: IUserCreated, timestamp: JSX.Element, t: TFunction) {
    return (<ActivityItem key={ev.id}
        activityDescription={[<Link to={`/users/${ev.user.id}`}>{ev.user.displayName}</Link>,
        <span> {t("activitiesDisplay.userCreatedBy", { name: ev.identity.displayName })}</span>]}
        comments={ev.data.groups?.length > 0 ? [t("activitiesDisplay.andAddedToGroups", { groups: _.join(ev.data.groups.map(g => g.displayName), ",") })] : [t("activitiesDisplay.andAddedToNoGroups")]}
        timeStamp={timestamp}
        activityIcon={<Icon iconName={addIcon.iconName} style={{ color: "blue" }} />}
    />);
}

function getDisplayForUserConfirmed(ev: IUserConfirmed, timestamp: JSX.Element, t: TFunction) {
    return (<ActivityItem key={ev.id}
        activityDescription={[<Link to={`/users/${ev.user.id}`}>{ev.user.displayName}</Link>,
        <span> {t("activitiesDisplay.userConfirmedBy", { name: ev.identity.displayName })}</span>]}
        timeStamp={timestamp}
        activityIcon={<Icon iconName={"UserFollowed"} style={{ color: "blue" }} />}
    />);
}


function getDisplayForUserDeactivated(ev: IUserDeactivated, timestamp: JSX.Element, t: TFunction) {
    return (<ActivityItem key={ev.id}
        activityDescription={[<Link to={`/users/${ev.user.id}`}>{ev.user.displayName}</Link>,
        <span> {t("activitiesDisplay.userDeactivatedBy", { name: ev.identity.displayName })}</span>]}
        timeStamp={timestamp}
        activityIcon={<Icon iconName={"UserWarning"} style={{ color: "orange" }} />}
    />);
}

function getDisplayForUserActivated(ev: IUserActivated, timestamp: JSX.Element, t: TFunction) {
    return (<ActivityItem key={ev.id}
        activityDescription={[<Link to={`/users/${ev.user.id}`}>{ev.user.displayName}</Link>,
        <span> {t("activitiesDisplay.userActivatedBy", { name: ev.identity.displayName })}</span>]}
        timeStamp={timestamp}
        activityIcon={<Icon iconName={"UserWarning"} style={{ color: "orange" }} />}
    />);
}


function getDisplayForUserDeleted(ev: IUserDeleted, timestamp: JSX.Element, t: TFunction) {
    return (<ActivityItem key={ev.id}
        activityDescription={[<Link to={`/users/${ev.user.id}`}>{ev.user.displayName}</Link>,
        <span> {t("activitiesDisplay.userDeletedBy", { name: ev.identity.displayName })}</span>]}
        timeStamp={timestamp}
        activityIcon={<Icon iconName={"UserRemove"} style={{ color: "red" }} />}
    />);
}

function getDisplayForUserGroupAssignmentsChanged(ev: IUserGroupAssignmentsChanged, timestamp: JSX.Element, t: TFunction) {

    let addedTo = _.join(_.map(ev.data.addedTo, g => g.displayName), ", ")
    let removedFrom = _.join(_.map(ev.data.removedFrom, g => g.displayName), ", ")

    let comments = [];

    if (addedTo) {
        comments.push(t("activitiesDisplay.addedToGroups", { groups: addedTo }));
    }

    if (removedFrom) {
        if (addedTo) {
            comments.push(<br />);
        }
        comments.push(t("activitiesDisplay.removedFromGroups", { groups: removedFrom }));
    }

    let icon = <Icon iconName={"UserSync"} style={{ color: "blue" }} />
    if (addedTo !== "" && removedFrom !== "") {
        icon = <Icon iconName={"UserSync"} style={{ color: "blue" }} />
    } else if (addedTo !== "") {
        icon = <Icon iconName={"AddFriend"} style={{ color: "green" }} />
    } else if (removedFrom !== "") {
        icon = <Icon iconName={"UserRemove"} style={{ color: "red" }} />
    }
    if(ev.data.functionalLocation){
        comments.unshift(`${formatFunctionalPlace(ev.data.functionalLocation)}: `)
    }
    return (<ActivityItem key={ev.id}
        activityDescription={[<Link to={`/users/${ev.user.id}`}>{ev.user.displayName}</Link>,
        <span> {t("activitiesDisplay.groupAssignmentsChangedBy", { name: ev.identity.displayName })}</span>]}
        comments={comments}
        timeStamp={timestamp}
        activityIcon={icon}
    />);
}

function getDisplayForUserAbbreviationChanged(ev: IUserAbbreviationChanged, timestamp: JSX.Element, t: TFunction) {
    let from = ev.data.oldAbbreviation ?? "";
    let to = ev.data.newAbbreviation ?? "";
    return (<ActivityItem key={ev.id}
        activityDescription={[<Link to={`/users/${ev.user.id}`}>{ev.user.displayName}</Link>,
        <span> {t("activitiesDisplay.userAbbreviationChanged", { name: ev.identity.displayName, from, to })}</span>]}
        timeStamp={timestamp}
        activityIcon={<Icon iconName={"FieldChanged"} />}
    />);
}

function getDisplayForUserInvitationStarted(ev: IUserInvitationStarted, timestamp: JSX.Element, t: TFunction) {
    return (<ActivityItem key={ev.id}
        activityDescription={[<Link to={`/users/${ev.user.id}`}>{ev.user.displayName}</Link>,
        <span> {t("activitiesDisplay.userInvitationStarted")}</span>]}
        timeStamp={timestamp}
        activityIcon={<Icon iconName={"FieldChanged"} />}
    />);
}

function getDisplayForUserInvitationCompleted(ev: IUserInvitationCompleted, timestamp: JSX.Element, t: TFunction) {
    return (<ActivityItem key={ev.id}
        activityDescription={[<Link to={`/users/${ev.user.id}`}>{ev.user.displayName}</Link>,
        <span> {t("activitiesDisplay.userInvitationCompleted")}</span>]}
        timeStamp={timestamp}
        activityIcon={<Icon iconName={"FieldChanged"} />}
    />);
}

function getDisplayForUserConfirmGranted(ev: IUserConfirmGranted, timestamp: JSX.Element, t: TFunction) {
    let comments = (ev.comment ?? "").split("\n").map(c => <>{c}<br /></>);
    return (<ActivityItem key={ev.id}
        activityDescription={[<Link to={`/users/${ev.user.id}`}>{ev.user.displayName}</Link>,
        <span> {t("activitiesDisplay.userConfirmGranted", { name: ev.identity.displayName })}</span>]}
        timeStamp={timestamp}
        activityIcon={<Icon iconName={"FieldChanged"} />}
        comments={comments}
    />);
}

function getDisplayForUserAccountActivated(ev: IUserAccountActivated, timestamp: JSX.Element, t: TFunction): any {
    return (<ActivityItem key={ev.id}
        activityDescription={[<Link to={`/users/${ev.user.id}`}>{ev.user.displayName}</Link>,
        <span> {t("activitiesDisplay.userAccountActivated")}</span>]}
        timeStamp={timestamp}
        activityIcon={<Icon iconName={"FieldChanged"} />}
    />);

}

function getDisplayForUserPasswordResetTriggered(ev: IUserPasswordResetTriggered, timestamp: JSX.Element, t: TFunction): any {
    return (<ActivityItem key={ev.id}
        activityDescription={[<Link to={`/users/${ev.user.id}`}>{ev.user.displayName}</Link>,
        <span> {t("activitiesDisplay.userPasswordResetTriggered", { name: ev.identity.displayName })}</span>]}
        timeStamp={timestamp}
        activityIcon={<Icon iconName={"FieldChanged"} />}
    />);

}

function getDisplayForUserPasswordResetCompleted(ev: IUserPasswordResetCompleted, timestamp: JSX.Element, t: TFunction): any {
    return (<ActivityItem key={ev.id}
        activityDescription={[<Link to={`/users/${ev.user.id}`}>{ev.user.displayName}</Link>,
        <span> {t("activitiesDisplay.userPasswordResetCompleted")}</span>]}
        timeStamp={timestamp}
        activityIcon={<Icon iconName={"FieldChanged"} />}
    />);

}