import {ReactText} from "react";
import {toast} from "react-toastify";

export class ProgressNotification {
    private static resetParams = {
        isLoading: null as any,
        autoClose: null as any,
        closeOnClick: null as any,
        closeButton: null as any,
        draggable: null as any
    };

    private constructor(private toastId: ReactText) {
    }

    static start(text: any): ProgressNotification {
        return new ProgressNotification(toast.loading(text));
    }

    success(text: any) {
        toast.update(this.toastId, {...ProgressNotification.resetParams, render: text, type: "success"});
    }

    error(text: any) {
        toast.update(this.toastId, {...ProgressNotification.resetParams, render: text, type: "error"});
    }
}
