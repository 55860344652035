import React, { useEffect, useState } from "react";
import { IUser } from "../shared/models";
import { useTranslation } from "react-i18next";
import { UserInfo } from "../shared/UserInfo";
import { DefaultButton, IStackTokens, MessageBar, Panel, PanelType, PrimaryButton, Stack, TextField } from "@fluentui/react";
import { ProgressNotification } from "./ProgressNotification";
import { userService } from "../users/userServices";
import useSWR from "swr";
import { ActivitiesDisplay } from "./ActivitiesDisplay";

const defaultTokens: IStackTokens = { childrenGap: 20 };

export const ConfirmUserPanel: React.FC<{ isVisible: boolean, confirmUser: IUser, onDismiss: () => void, onActionStarting: () => void, onActionCompleted?: () => void }> = ({ isVisible, confirmUser, onDismiss, onActionStarting, onActionCompleted }) => {
    let [t] = useTranslation();
    let [comment, setComment] = useState("");
    let { data: confirmationGrants } = useSWR(isVisible && confirmUser ? `users/${confirmUser.id}` : null, () => userService.getConfirmationGrants(confirmUser.id));
    useEffect(() => setComment(""), [confirmUser]);
    let onConfirmUser = async (user: IUser) => {
        let formatData = { name: user.displayName };
        let notification = ProgressNotification.start(t("confirmUserPanel.notifications.inProgress", formatData));
        onActionStarting();
        let result = await userService.confirmUser(user.id, comment);
        if (result.success) {
            notification.success(t("confirmUserPanel.notifications.success", formatData));
        }
        else {
            notification.error(t("confirmUserPanel.notifications.error", formatData));
        }

        if (onActionCompleted) {
            onActionCompleted();
        }
    };

    return (<Panel
        type={PanelType.medium}
        isLightDismiss
        isOpen={isVisible}
        onDismiss={onDismiss}
        closeButtonAriaLabel={t("common.close")}
        headerText={t("confirmUserPanel.headerText")}
    >
        <Stack tokens={defaultTokens}>
            <p>{t("confirmUserPanel.confirmText")}</p>
            <UserInfo style={{ margin: "1rem 0" }} user={confirmUser} />
            <MessageBar style={{ marginBottom: "1rem" }}>{t("confirmUserPanel.confirmDescription")}</MessageBar>
            <ActivitiesDisplay events={confirmationGrants} noActivitiesText={t("confirmUserPanel.noConfirmationEvents")} />
            <TextField label={t("confirmUserPanel.comment")} multiline rows={4} onChange={(_, text) => setComment(text)} value={comment} />
        </Stack>

        <Stack style={{ marginTop: "1rem", marginBottom: "1rem" }} horizontal tokens={defaultTokens}>
            <PrimaryButton onClick={() => onConfirmUser(confirmUser)} >{t("confirmUserPanel.confirm")}</PrimaryButton>
            <DefaultButton onClick={onDismiss}>{t("confirmUserPanel.cancel")}</DefaultButton>
        </Stack>
    </Panel>);
}