import { useMemo, useState } from "react";
import { IPortalPermissions, IUser, createPortalPermissions } from "../shared/models";
import { confirmIcon, deactivateIcon, defaultTokens, deleteIcon, getDefaultUserColumns } from "../shared/CommonUi";
import { IColumn, Stack, DefaultButton, IContextualMenuItem, IContextualMenuProps, MessageBar, MessageBarType } from "@fluentui/react";
import { canBeConfirmedByCurrentUser, getPortalPermissions } from "../shared/authService";
import { TFunction, t } from "i18next";
import { DataList } from "../shared/DataList";
import { Card } from "../components/Card";
import { DeleteUserPanel } from "../shared/DeleteUserPanel";
import { DeactivateUserPanel } from "../shared/DeactivateUserPanel";
import { RevalidateUserPanel } from "../shared/RevalidateUserPanel";

export const UserRevalidateCard: React.FC<{ usersToRevalidate: IUser[] }> = ({ usersToRevalidate }) => {

    let [deleteUser, setDeleteUser] = useState<IUser>();
    let [deactivateUser, setDeactivateUser] = useState<IUser>();
    let [revalidateUser, setRevalidateUser] = useState<IUser>();
    let permissions = useMemo(() => getPortalPermissions(), []);


    let nothingToRevalidate = !(usersToRevalidate.length > 0);

    let usersToRevalidateColumns = useMemo(() => getToValidateUserColumns(t, permissions, (action, user) => {
        switch (action) {
            case "revalidate":
                setRevalidateUser(user);
                break;
            case "delete":
                setDeleteUser(user);
                break;
            case "deactivate":
                setDeactivateUser(user);
                break;

            default:
                console.log(`${action} on ${user.displayName} has no handler.`)
                break;
        }
    }), [permissions]);


    return nothingToRevalidate ?
        <MessageBar messageBarType={MessageBarType.info}>{t("overview.revalidateUsersCard.noValidations")}</MessageBar>
        : (
            <div>
                <Card title={t("overview.revalidateUsersCard.title")} >
                    {nothingToRevalidate && (<MessageBar messageBarType={MessageBarType.info}>{t("userApprovals.noApprovalsMessage")}</MessageBar>)}
                    {!nothingToRevalidate && (<DataList columns={usersToRevalidateColumns} items={usersToRevalidate ?? []} />)}
                    <DeleteUserPanel isVisible={deleteUser != null} deleteUser={deleteUser} onDismiss={() => setDeleteUser(null)}
                        onActionStarting={() => setDeleteUser(null)} onActionCompleted={() => { }} />
                    <DeactivateUserPanel isVisible={deactivateUser != null} deactivateUser={deactivateUser}
                        onDismiss={() => setDeactivateUser(null)} onActionStarting={() => setDeactivateUser(null)}
                        onActionCompleted={() => { }} />
                    <RevalidateUserPanel isVisible={revalidateUser != null} revalidateUser={revalidateUser}
                        onDismiss={() => setRevalidateUser(null)} onActionStarting={() => setRevalidateUser(null)}
                        onActionCompleted={() => { }} />
                </Card>


            </div>
        );
    function getToValidateUserColumns(t: TFunction, permissions: IPortalPermissions, onClick: (action: string, user: IUser) => void): IColumn[] {
        let userColumns = getDefaultUserColumns(t, permissions);
        return [
            ...userColumns,
            {
                key: "actions",
                name: t("defaultUserTable.tableHeaders.actions"),
                minWidth: 220,
                onRender: (item: IUser) => (
                    <Stack horizontal tokens={defaultTokens}>
                        <DefaultButton iconProps={confirmIcon} split onClick={() => { onClick("revalidate", item) }} menuProps={getUserMenuProps(t, item, permissions, action => onClick(action, item))}>{t("common.ok")}</DefaultButton>
                    </Stack>
                ),
            },
        ];
    }
    function getUserMenuProps(t: TFunction, user: IUser, permissions: IPortalPermissions, onClick: (action: string) => void) {

        let items: IContextualMenuItem[] = [];

        if (canBeConfirmedByCurrentUser(user, permissions)) {
            items.push({
                key: "revalidate",
                text: "test",
                iconProps: confirmIcon,
                onClick: () => onClick("revalidate")
            })
        }

        if (!user.isDeactivated && permissions.deactivateUser) {
            items.push({
                key: "deactivate",
                text: t("common.deactivate"),
                iconProps: deactivateIcon,
                onClick: () => onClick("deactivate")
            })
        }

        if (permissions.deleteUser) {
            items.push({
                key: "delete",
                text: t("common.delete"),
                iconProps: deleteIcon,
                onClick: () => onClick("delete")
            });
        }

        let menuProps: IContextualMenuProps = {
            items: items,
        };

        return menuProps;
    }
}

