import { DefaultButton, IColumn, IContextualMenuItem, IContextualMenuProps, MessageBar } from "@fluentui/react";
import { TFunction } from "i18next";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Card } from "../components/Card";
import { ActivateUserPanel } from "../shared/ActivateUserPanel";
import { confirmIcon, deleteIcon, getDefaultUserColumns } from "../shared/CommonUi";
import { DataList } from "../shared/DataList";
import { DeleteUserPanel } from "../shared/DeleteUserPanel";
import { IDashboardResponse, IPortalPermissions, IUser } from "../shared/models";
import overviewStyles from './Overview.module.css';
import { getPortalPermissions } from "../shared/authService";

export const LongtermDeactivedUsersCard: React.FC<{ data: IDashboardResponse }> = ({ data }) => {
    let [t] = useTranslation();
    let permissions = useMemo(() => getPortalPermissions(), []);
    let [activateUser, setActivateUser] = useState<IUser>();
    let [deleteUser, setDeleteUser] = useState<IUser>();
    let longinactiveUsersColumns = useMemo(() => getLongDeactivatedUsersColumns(t, permissions, (action, user) => {
        switch (action) {
            case "activate":
                setActivateUser(user);
                break;
            case "delete":
                setDeleteUser(user);
                break;
        }
    }), [t, permissions]);

    if (data?.longInactiveUsers?.length === 0) {
        return (
            <Card title={t("overview.longInactiveUsersCard.title")} >
                <MessageBar>
                    {t("overview.longInactiveUsersCard.noInactiveUsers")}
                </MessageBar>
            </Card>);
    }

    let longInactiveUsers = data?.longInactiveUsers ?? [];

    return (<Card title={t("overview.longInactiveUsersCard.title")}>
        <DataList items={longInactiveUsers} columns={longinactiveUsersColumns} />
        <ActivateUserPanel isVisible={activateUser != null} activateUser={activateUser} onDismiss={() => setActivateUser(null)} onActionStarting={() => setActivateUser(null)} />
        <DeleteUserPanel isVisible={deleteUser != null} deleteUser={deleteUser} onDismiss={() => setDeleteUser(null)} onActionStarting={() => setDeleteUser(null)} />
    </Card>);
}
function getLongDeactivatedUsersColumns(t: TFunction, permissions: IPortalPermissions, onClick: (action: string, user: IUser) => void): IColumn[] {
    return [
        ...getDefaultUserColumns(t, permissions),
        {
            key: "actions",
            name: t("common.activate"),
            minWidth: 220,
            onRender: (item: IUser) => (
                <DefaultButton
                    iconProps={confirmIcon}
                    split
                    primaryDisabled={false}
                    menuProps={getLongDeactivateMenuProps(t, permissions, key => onClick(key, item))}
                    disabled={false}
                    onClick={() => onClick("activate", item)}
                >
                    {t("common.activate")}
                </DefaultButton>),
        }
    ];
};
function getLongDeactivateMenuProps(t: TFunction, permissions: IPortalPermissions, onClick: (action: string) => void) {

    let items: IContextualMenuItem[] = [];

    if (permissions.deleteUser) {
        items.push({
            key: "activate",
            text: t("common.activate"),
            iconProps: confirmIcon,
            onClick: () => onClick("activate")
        });
        items.push({
            key: "delete",
            text: t("common.delete"),
            iconProps: deleteIcon,
            onClick: () => onClick("delete")
        });

    }
    let menuProps: IContextualMenuProps = {
        items: items
    };

    return menuProps;
}