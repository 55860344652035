import React, { useEffect, useMemo, useState } from "react";

import { Breadcrumb, DefaultButton, IBreadcrumbItem, IColumn, MessageBar, MessageBarType, Stack } from "@fluentui/react";
import { TFunction } from "i18next";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import useSWR from "swr";
import { Card } from "../components/Card";
import { confirmIcon, defaultTokens, getDefaultUserColumns } from "../shared/CommonUi";
import { ConfirmUserPanel } from "../shared/ConfirmUserPanel";
import { DataList } from "../shared/DataList";
import { getPortalPermissions } from "../shared/authService";
import commonStyles from "../shared/common.module.css";
import { IPortalPermissions, IUser } from "../shared/models";
import { notificationService } from "../shared/notificationService";
import { userService } from "../users/userServices";
import styles from "./UserApprovals.module.css";

export const UserApprovals: React.FC = () => {
    let [t] = useTranslation();
    let breadcrumbs = useMemo(() => getBreadcrumbs(t), [t]);
    let [confirmUser, setConfirmUser] = useState<IUser>();
    let permissions = useMemo(() => getPortalPermissions(), []);

    let usersToConfirmColumns = useMemo(() => getToConfirmUsersColumns(t, permissions, (action, user) => {
        switch (action) {
            case "confirm":
                setConfirmUser(user);
                break;
            default:
                console.log(`${action} on ${user.displayName} has no handler.`)
                break;
        }
    }), [t, permissions]);
    


    let { data, mutate, isLoading } = useSWR("/api/me/getApprovableUsersByMe", () => userService.getApprovableUsersByMe());
    let reload = React.useMemo(() => _.debounce(mutate, 250), [mutate]);

    useEffect(() => {
        let subscription = notificationService.getUserUpdates().subscribe(() => { reload(); });
        return () => subscription.unsubscribe();
    }, []);

    let nothingToConfirm = !(data?.approvables?.length > 0);
    let usersToConfirm = !nothingToConfirm;

    return isLoading ? <></> : (
        <div className={styles.content}>
            <Breadcrumb className={commonStyles.breadcrumb} style={{ marginBottom: 20 }} items={breadcrumbs} />
            <Card title={t("common.users")} className={styles.resultCard}>
                {nothingToConfirm && (<MessageBar messageBarType={MessageBarType.info}>{t("userApprovals.noApprovalsMessage")}</MessageBar>)}
                {usersToConfirm && (<DataList columns={usersToConfirmColumns} items={data?.approvables ?? []} />)}
                <ConfirmUserPanel isVisible={confirmUser != null} confirmUser={confirmUser} onDismiss={() => setConfirmUser(null)} onActionStarting={() => setConfirmUser(null)} onActionCompleted={reload} />
            </Card>
        </div>
    );
}

function getBreadcrumbs(t: TFunction): IBreadcrumbItem[] {
    return [
        {
            text: t("userApprovals.breadcrumb.1"),
            key: "1"
        }
    ];
}

function getToConfirmUsersColumns(t: TFunction, permissions: IPortalPermissions, onClick: (action: string, user: IUser) => void): IColumn[] {
    let userColumns = getDefaultUserColumns(t, permissions);
    return [
        ...userColumns,
        {
            key: "actions",
            name: t("defaultUserTable.tableHeaders.actions"),
            minWidth: 220,
            onRender: (item: IUser) => (
                <Stack horizontal tokens={defaultTokens}>
                    <DefaultButton iconProps={confirmIcon} onClick={() => { onClick("confirm", item) }}>{t("common.confirm")}</DefaultButton>
                </Stack>
            ),
        },
    ];
}
