import { PropsWithChildren, memo } from "react";
import { IConfig } from "./models";

export const FeatureLocked = memo((props: IFeatureLockedProps) => {
    if(!props.children) return <></>
    return (props.config?.app?.featureFlags as any)[props.feature] as boolean ? <>{props.children}</> : <></>
})
export interface IFeatureLockedProps extends PropsWithChildren {
    config: IConfig,
    feature: string
}