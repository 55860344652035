import React from "react";
import { InteractionStatus } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import styles from "./Unauthorized.module.css";

const inProgressStatus = [
    InteractionStatus.AcquireToken,
    InteractionStatus.Login,
    InteractionStatus.Logout,
    InteractionStatus.SsoSilent,
    InteractionStatus.Startup,
    InteractionStatus.HandleRedirect,
    InteractionStatus.None,
  ];

export const Unauthorized: React.FC = () => {

    let msal = useMsal();
    let isInProgress = inProgressStatus.includes(msal.inProgress);
    console.log(msal.inProgress, isInProgress)
    if (isInProgress) {
        return <InProgressInfo />
    }

    return <UnauthorizedInfo />
}

const InProgressInfo: React.FC = () => {
    return (<div className={styles.content}>
        <div>
            <div className={styles.hero}>
                Logging you in....
            </div>
            <div className={styles.message}>
                Hang in there, this should not take long!
            </div>
            <div className={styles.contact}>Please contact B-ONE-Support for support if it does.</div>
        </div>
    </div>);
}

const UnauthorizedInfo: React.FC = () => {
    return (<div className={styles.content}>
        <div>
            <div className={styles.hero}>
                Upps, something is wrong...😒
            </div>
            <div className={styles.message}>
                You´re missing permissions to use the user portal.
            </div>
            <div className={styles.contact}>
                <a href={"https://one.brueckner.com"}>👉 See which Brückner ONE offerings you have access to</a>
            </div>
        </div>
    </div>);
}