import React, { useEffect, useState } from "react";
import logo from "./b-one-logo.svg";

import { Route, NavLink, Navigate, Routes } from "react-router-dom";
import "./App.css";
import { getPortalPermissions, signOut, isAuthenticated as checkAuthentication, getCurrentUserInfo, getConfig, getInternalCustomerId } from "./shared/authService";
import { useTranslation } from "react-i18next";
import { Overview } from "./dashboard/Overview";
import { CreateUser } from "./users/CreateUser";
import { ActionButton, DefaultButton, Dropdown, Icon, IDropdownOption, IDropdownStyles } from "@fluentui/react";
import { Customers } from "./customers/Customers";
import { Users } from "./users/Users";
import { Activities } from "./activities/Activities";
import i18n from "i18next";
import { Unauthorized } from "./shared/Unauthorized";
import { EditUser } from "./users/EditUser";
import { UserApprovals } from "./approvals/UserApprovals";
import { notificationService } from "./shared/notificationService";
import { NotificationBadge } from "./shared/NotificationBadge";
import { CreateInternalUser } from "./users/CreateInternalUser";
import classNames from "classnames";
import { Permissions } from "./permissions/Permissions";
import { PermissionsBulkAssign } from "./permissions/PermissionsBulkAssign";
import { PermissionsFunctionalPlace } from "./permissions/PermissionsFunctionalPlace";
import { Customer } from "./customers/Customer";
import { FeatureLocked } from "./shared/FeatureLocked";

const App: React.FC = () => {
    let permissions = getPortalPermissions();
    let config = getConfig();
    let [t] = useTranslation();
    let [openApprovals, setOpenApprovals] = useState(0);
    const isAuthenticated = checkAuthentication();
    let internalCustomerId = getInternalCustomerId();
    console.log("isauthenticated", isAuthenticated);

    useEffect(() => {
        if (isAuthenticated) {
            let subscription = notificationService.getOpenApprovalUpdates().subscribe(setOpenApprovals);
            return () => subscription.unsubscribe();
        }
    }, [isAuthenticated]);

    return (
        <>
            <div className="maincontainer">
                <header className="appHeader">
                    <img className="headerImage" alt="" src={logo} />
                    <span className="environment">{t("app.title")}</span>
                    <div className="nav">
                        {permissions.readDashboard && (
                            <NavLink className={navData => classNames("nav_link", navData.isActive && "active")}
                                to="/overview"><Icon className="nav_link_icon"
                                    iconName="HomeGroup" />{t("nav.overview")}</NavLink>)}
                        {permissions.readUsers && (
                            <NavLink className={navData => classNames("nav_link", navData.isActive && "active")}
                                to="/users"><Icon className="nav_link_icon" iconName="Group" />{t("nav.users")}
                            </NavLink>)}
                        {permissions.readCustomers && (
                            <NavLink end className={navData => classNames("nav_link", navData.isActive && "active")}
                                to="/customers"><Icon className="nav_link_icon"
                                    iconName="ReminderGroup" />{t("nav.customers")}</NavLink>)}
                        <FeatureLocked config={config} feature="enableExtendedPermissionModel">
                            {permissions.readOwnCustomer && internalCustomerId !== null && (
                                <NavLink className={navData => classNames("nav_link", navData.isActive && "active")}
                                    to={`/customers/${internalCustomerId}`}><Icon className="nav_link_icon"
                                        iconName="ReminderGroup" />{t("nav.customer")}</NavLink>)}
                        </FeatureLocked>
                        {permissions.readActivities && (
                            <NavLink className={navData => classNames("nav_link", navData.isActive && "active")}
                                to="/activities"><Icon className="nav_link_icon"
                                    iconName="Timeline" />{t("nav.activities")}</NavLink>)}
                        {permissions.confirmUser && (
                            <NavLink className={navData => classNames("nav_link", navData.isActive && "active")}
                                to="/userapprovals"><Icon className="nav_link_icon"
                                    iconName="Group" />{t("nav.userapprovals")}
                                <NotificationBadge count={openApprovals} /></NavLink>)}
                        <FeatureLocked config={config} feature="enableExtendedPermissionModel">
                            {permissions.editUser && (
                                <NavLink className={navData => classNames("nav_link", navData.isActive && "active")}
                                    to="/permissions"><Icon className="nav_link_icon"
                                        iconName="Group" />{t("nav.permissions")}
                                </NavLink>)}
                        </FeatureLocked>
                    </div>
                    <div className="nav_footer">
                        {config.app.environment === "Halvotec-Dev" && <>
                            <p>{getCurrentUserInfo().debugInfo.name}</p>
                            <p>{getCurrentUserInfo().debugInfo.customerDisplay}</p>
                        </>
                        }
                        <DefaultButton onClick={() => signOut()}>{t("common.logout")}</DefaultButton>
                        <div className="nav_language">
                            <Icon style={{ marginRight: "0.25rem" }} iconName="Globe" />
                            <LanguagePicker />
                            <ActionButton>{process.env.REACT_APP_VERSION}</ActionButton>
                        </div>
                    </div>
                </header>
                <div className="maincontent">
                    <Routes>
                        {isAuthenticated && permissions.createUser && (
                            <Route path="/users/new" element={<CreateUser />} />)}
                        {isAuthenticated && permissions.createInternalUser && (
                            <Route path="/users/newinternal" element={<CreateInternalUser />} />)}
                        {isAuthenticated && permissions.readUserDetails && (
                            <Route path="/users/:userId" element={<EditUser />} />)}
                        {isAuthenticated && permissions.readUsers && (<Route path="/users" element={<Users />} />)}
                        {isAuthenticated && permissions.readActivities && (
                            <Route path="/activities" element={<Activities />} />)}
                        {isAuthenticated && permissions.readOwnCustomer && (
                            <Route path="/customers/:customerId" element={
                                    <Customer />
                            } />)
                        }
                        {isAuthenticated && permissions.readCustomers && (
                            <Route path="/customers" element={<Customers />} />)}
                        {isAuthenticated && permissions.readDashboard && (
                            <Route path="/overview" element={<Overview />} />)}
                        {isAuthenticated && permissions.confirmUser && (
                            <Route path="/userapprovals" element={<UserApprovals />} />)}
                        {isAuthenticated && permissions.editUser && (
                            <Route path="/permissions/assign" element={
                                <FeatureLocked config={config} feature="enableExtendedPermissionModel">
                                    <PermissionsBulkAssign />
                                </FeatureLocked>} />)}
                        {isAuthenticated && permissions.readUsers && (
                            <Route path="/permissions/:fpId" element={
                                <FeatureLocked config={config} feature="enableExtendedPermissionModel">
                                    <PermissionsFunctionalPlace />
                                </FeatureLocked>} />)}
                        {isAuthenticated && permissions.editUser && (
                            <Route path="/permissions" element={
                                <FeatureLocked config={config} feature="enableExtendedPermissionModel">
                                    <Permissions />
                                </FeatureLocked>
                            } />)}
                        {isAuthenticated && !permissions.hasNoPermissions && <Route path="/" element={<Navigate replace to="/overview" />} />}
                        <Route path="*" element={<Unauthorized />} />
                    </Routes>
                </div>
            </div>
        </>
    );

};

export default App;

const languageOptions: IDropdownOption[] = [
    { key: 'de_DE', text: 'de' },
    { key: 'en_EN', text: 'en' },
    { key: 'bg_BG', text: 'bg' },
    { key: 'es_ES', text: 'es' },
    { key: 'hu_HU', text: 'hu' },
    { key: 'ja_JP', text: 'ja' },
    { key: 'ko_KR', text: 'ko' },
    { key: 'pl_PL', text: 'pl' },
    { key: 'pt_PT', text: 'pt' },
    { key: 'ru_RU', text: 'ru' },
    { key: 'tr_TR', text: 'tr' },
    { key: 'it_IT', text: 'it' },
    { key: 'zh_CN', text: 'zh-CN' },
    { key: 'zh_TW', text: 'zh-TW' },
];
const dropdownStyles: Partial<IDropdownStyles> = {
    dropdown: { width: 80 },
};

const LanguagePicker: React.FC = () => {
    let [lang, setLang] = useState(i18n.language);

    useEffect(() => {
        if (lang !== i18n.language) {
            console.log("Change Langugage to ", lang);
            i18n.changeLanguage(lang)
        }
    }, [lang]);


    return (<div className="nav_language_wrapper">
        <Dropdown styles={dropdownStyles} options={languageOptions} selectedKey={lang}
            onChange={(_, item) => setLang(item.key as string)} />
        {/* <ActionButton onClick={() => i18n.changeLanguage("de_DE")}>DE</ActionButton>
        <ActionButton onClick={() => i18n.changeLanguage("en_EN")}>EN</ActionButton> */}
    </div>);
}