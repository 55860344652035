import React from "react";
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from "react-router-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { MsalProvider } from "@azure/msal-react";
import { getMsalInstance, initialize as initializeAuth, initializePermissions } from "./shared/authService";
import { ThemeProvider } from "@fluentui/react/lib/Theme";
import i18n from "i18next";
import Backend from "i18next-xhr-backend";
import { initReactI18next } from "react-i18next";
import { myTheme } from "./theme";
import { initializeIcons } from "@fluentui/react";
import { SWRConfig } from "swr";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import { notificationService } from "./shared/notificationService";

const swrConfig = {
  revalidateOnFocus: false,
};

async function initialize() {
  console.log("init");
  await notificationService.initialize();
  initializeIcons();
  await initI18n(getStartLanguage());
  await initializeAuth(true);
  console.log("init complete");
}

function renderApp() {
  const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
  );
  let msalInstance = getMsalInstance();
  root.render(
    <SWRConfig value={swrConfig}>
      <Router>
        <MsalProvider instance={msalInstance}>
          <ThemeProvider className="themeContainer" theme={myTheme}>
            <App />
            <ToastContainer theme="dark" />
          </ThemeProvider>
        </MsalProvider>
      </Router>
    </SWRConfig>);

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals();
}

initialize()
  .then(() => initializePermissions())
  .then(renderApp);

function getStartLanguage(): string {
  let lng = navigator.language;
  if (lng.startsWith("de")) {
    return "de_DE";
  }
  if (lng.startsWith("en")) {
    return "en_EN";
  }
  if (lng.startsWith("bg")) {
    return "bg_BG";
  }
  if (lng.startsWith("hu")) {
    return "hu_HU";
  }
  if (lng.startsWith("pt")) {
    return "pt_PT";
  }
  if (lng.startsWith("zh-TW")) {
    return "zh_TW";
  }
  if (lng.startsWith("zh-CN")) {
    return "zh_CN";
  }
  if (lng.startsWith("zh")) {
    return "zh_TW";
  }
  if (lng.startsWith("ja")) {
    return "ja_JP";
  }
  if (lng.startsWith("ko")) {
    return "ko_KR";
  }
  if (lng.startsWith("pl")) {
    return "pl_PL";
  }
  if (lng.startsWith("ru")) {
    return "ru_RU";
  }
  if (lng.startsWith("es")) {
    return "es_ES";
  }
  if (lng.startsWith("tr")) {
    return "tr_TR";
  }
  if (lng.startsWith("it")) {
    return "it_IT";
  }

  return "en_EN";
}

async function initI18n(language: string) {
  await i18n
    .use(Backend)
    .use(initReactI18next)
    .init({
      lng: language,
      fallbackLng: "en_EN",
      interpolation: {
        escapeValue: false,
      },
      backend: {
        queryStringParams: { v: process.env.REACT_APP_VERSION },
      },
      react: {
        wait: true,
        useSuspense: true,
      },
      debug: false,
    } as any);
}
