import { useTranslation } from "react-i18next";
import { IDashboardResponse, IPortalPermissions, IUser } from "../shared/models";
import { useMemo, useState } from "react";
import { confirmIcon, deleteIcon, getDefaultUserColumns } from "../shared/CommonUi";
import { TFunction } from "i18next";
import { DefaultButton, IColumn, IContextualMenuItem, IContextualMenuProps, MessageBar } from "@fluentui/react";
import { Card } from "../components/Card";
import { ConfirmUserPanel } from "../shared/ConfirmUserPanel";
import { DataList } from "../shared/DataList";
import { DeleteUserPanel } from "../shared/DeleteUserPanel";
import { canBeConfirmedByCurrentUser, getPortalPermissions } from "../shared/authService";
import overviewStyles from './Overview.module.css';

export const UnconfirmedUsersCard: React.FC<{ data: IDashboardResponse }> = ({ data }) => {
    let [t] = useTranslation();
    let permissions = useMemo(() => getPortalPermissions(), []);
    let [confirmUser, setConfirmUser] = useState<IUser>();
    let [deleteUser, setDeleteUser] = useState<IUser>();
    let unconfirmedUsersColumns = useMemo(() => getUnconfirmedUsersColumns(t, permissions, (action, user) => {
        switch (action) {
            case "confirm":
                setConfirmUser(user);
                break;
            case "delete":
                setDeleteUser(user);
                break;
        }
    }), [t, permissions]);



    if (data?.unconfirmedUsers?.length === 0) {
        return (
            <Card title={t("overview.unconfirmedUsersCard.title")} contentClassName={overviewStyles.unconfirmedCardContent}>
                <MessageBar>
                    {t("overview.unconfirmedUsersCard.noUnconfirmedUsers")}
                </MessageBar>
            </Card>);
    }

    let unconfirmedUsers = data?.unconfirmedUsers ?? [];

    return (<Card title={t("overview.unconfirmedUsersCard.title")}>
        <DataList items={unconfirmedUsers} columns={unconfirmedUsersColumns} />
        <ConfirmUserPanel isVisible={confirmUser != null} confirmUser={confirmUser} onDismiss={() => setConfirmUser(null)} onActionStarting={() => setConfirmUser(null)} />
        <DeleteUserPanel isVisible={deleteUser != null} deleteUser={deleteUser} onDismiss={() => setDeleteUser(null)} onActionStarting={() => setDeleteUser(null)} />
    </Card>);
}
function getUnconfirmedUsersColumns(t: TFunction, permissions: IPortalPermissions, onClick: (action: string, user: IUser) => void): IColumn[] {
    return [
        ...getDefaultUserColumns(t, permissions),
        {
            key: "actions",
            name: t("defaultUserTable.tableHeaders.actions"),
            minWidth: 220,
            onRender: (item: IUser) => (<DefaultButton iconProps={confirmIcon} split primaryDisabled={!permissions.confirmUser} menuProps={getUnconfirmedMenuProps(t, permissions, key => onClick(key, item))} disabled={!canBeConfirmedByCurrentUser(item, permissions)} onClick={() => onClick("confirm", item)}>{t("common.confirm")}</DefaultButton>),
        }
    ];
};
function getUnconfirmedMenuProps(t: TFunction, permissions: IPortalPermissions, onClick: (action: string) => void) {

    let items: IContextualMenuItem[] = [];

    if (permissions.deleteUser) {
        items.push({
            key: "delete",
            text: t("common.delete"),
            iconProps: deleteIcon,
            onClick: () => onClick("delete")
        });
    }
    let menuProps: IContextualMenuProps = {
        items: items
    };

    return menuProps;
}