import React, { useCallback } from "react";
import { IUser } from "../shared/models";
import { useTranslation } from "react-i18next";
import { UserInfo } from "../shared/UserInfo";
import { DefaultButton, IStackTokens, MessageBar, Panel, PanelType, PrimaryButton, Stack } from "@fluentui/react";
import { ProgressNotification } from "./ProgressNotification";
import { userService } from "../users/userServices";

const defaultTokens: IStackTokens = { childrenGap: 20 };

export const ResetPasswordPanel: React.FC<{ isVisible: boolean, user: IUser, onDismiss: () => void, onActionStarting: () => void, onActionCompleted?: () => void }> = ({ isVisible, user, onDismiss, onActionStarting, onActionCompleted }) => {
    let [t] = useTranslation();
    let onConfirmUser = async (user: IUser) => {
        let formatData = { name: user.displayName };
        let notification = ProgressNotification.start(t("resetPasswordPanel.notifications.inProgress", formatData));
        onActionStarting();
        let result = await userService.resetPassword(user.id);
        if (result.success) {
            notification.success(t("resetPasswordPanel.notifications.success", formatData));
        }
        else {
            notification.error(t("resetPasswordPanel.notifications.error", formatData));
        }
        if (onActionCompleted) {
            onActionCompleted();
        }
    };

    return (<Panel
        type={PanelType.medium}
        isLightDismiss
        isOpen={isVisible}
        onDismiss={onDismiss}
        closeButtonAriaLabel={t("common.close")}
        headerText={t("resetPasswordPanel.headerText")}
    >
        <p>{t("resetPasswordPanel.confirmText")}</p>
        <UserInfo style={{ margin: "1rem 0" }} user={user} />
        <MessageBar>{t("resetPasswordPanel.confirmDescription")}</MessageBar>
        <Stack style={{ marginTop: "1rem", marginBottom: "1rem" }} horizontal tokens={defaultTokens}>
            <PrimaryButton onClick={() => onConfirmUser(user)} >{t("resetPasswordPanel.confirm")}</PrimaryButton>
            <DefaultButton onClick={onDismiss}>{t("resetPasswordPanel.cancel")}</DefaultButton>
        </Stack>
    </Panel>);
}