import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { getApiLoginRequest } from "./authConfig";
import { getConfig, getIsLoggingIn, getMsalInstance } from "./authService";

class HttpClient {
  private isRefreshing: boolean;
  private failedRequests: any[];
  private token: string = "";
  public client: AxiosInstance;

  constructor() {
    this.isRefreshing = false;
    this.failedRequests = [];
    this.client = axios.create({
      validateStatus: status => status >= 200 && status < 500 && status !== 401 && status !== 403
    });
    this.beforeRequest = this.beforeRequest.bind(this);
    this.onRequestFailure = this.onRequestFailure.bind(this);
    this.processQueue = this.processQueue.bind(this);
    this.client.interceptors.request.use(this.beforeRequest);
    this.client.interceptors.response.use(this.onRequestSuccess, this.onRequestFailure);
  }

  beforeRequest(request: AxiosRequestConfig) {
    request.headers.Authorization = `Bearer ${this.token}`;
    request.headers["Content-Type"] = request.headers["Content-Type"] ?? "application/json";
    request.headers.Accept = "application/json";
    return request;
  }

  onRequestSuccess(response: AxiosResponse<any>) {
    return response;
  }

  async onRequestFailure(err: any) {
    const { response } = err;
    if (response.status === 401 && err && err.config && !err.config.__isRetryRequest) {
      if (this.isRefreshing) {
        try {
          const token = await new Promise((resolve, reject) => {
            this.failedRequests.push({ resolve, reject });
          });
          err.config.headers.Authorization = `Bearer ${token}`;
          return this.client(err.config);
        } catch (e) {
          return e;
        }
      }
      this.isRefreshing = true;
      err.config.__isRetryRequest = true;
      return new Promise((resolve, reject) => {
        let msal = getMsalInstance();
        let config = getConfig();

        if (getIsLoggingIn()) {
          console.log("Login running, can not acquire silent token");
          this.processQueue(null, null);
          reject(err.response);
        }
        else {
          msal
            .acquireTokenSilent({
              scopes: getApiLoginRequest(config).scopes,
              account: msal.getAllAccounts()[0],
            })
            .then((authResult) => {
              this.token = authResult.accessToken;
              err.config.headers.Authorization = `Bearer ${this.token}`;
              this.isRefreshing = false;
              this.processQueue(null, this.token);
              resolve(this.client(err.config));
            })
            .catch((e: any) => {
              this.processQueue(e, null);
              reject(err.response);
            });
        }
      });
    }
    throw response;
  }

  processQueue(error: any, token: string | null = null) {
    this.failedRequests.forEach((prom) => {
      if (error) {
        prom.reject(error);
      } else {
        prom.resolve(token);
      }
    });
    this.failedRequests = [];
  }
}

export const httpClient = new HttpClient().client;
