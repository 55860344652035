import {
    AuthenticationResult,
    Configuration,
    EventMessage,
    EventType,
    PublicClientApplication
} from "@azure/msal-browser";
import _ from "lodash";
import { getMsalConfig, getLoginRequest } from "./authConfig";
import { httpClient } from "./httpClient";
import { createPortalPermissions, IConfig, IPortalPermissions, IUser } from "./models";

let msalInstance: PublicClientApplication;
let msalConfig: Configuration;
let config: IConfig;
let portalPermissions: IPortalPermissions = createPortalPermissions();
let isLoggingIn = false;
let internalCustomerId : (string | null)  = null;

export function getIsLoggingIn() {
    return isLoggingIn;
}

export function isAuthenticated() {
    return msalInstance.getActiveAccount() != null;
}
export function getInternalCustomerId() : string |null {
    return internalCustomerId;
}

export const initialize = async (autoLogin: boolean) => {
    let response = await fetch(`/api/config/client?${+new Date()}`);
    config = await response.json();
    msalConfig = getMsalConfig(config);
    msalInstance = new PublicClientApplication(msalConfig);
    await msalInstance.initialize();

    // Account selection logic is app dependent. Adjust as needed for different use cases.
    const accounts = msalInstance.getAllAccounts();
    if (accounts.length > 0) {
        msalInstance.setActiveAccount(accounts[0]);
    }

    msalInstance.addEventCallback((event: EventMessage) => {
        if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
            const payload = event.payload as AuthenticationResult;
            const account = payload.account;
            msalInstance.setActiveAccount(account);
        }
    });

    return await msalInstance.handleRedirectPromise().then(async authResult => {
        const account = msalInstance.getActiveAccount();
        if (!account && isLoggingIn === false) {
            console.log("loginredirect")
            isLoggingIn = true;
            await msalInstance.loginRedirect(getLoginRequest(config));
        }
    }).catch(err => console.log(err));
};

export const initializePermissions = async () => {

    try {
        if (msalInstance.getActiveAccount()) {
            let result = await httpClient.get<IPortalPermissions>("/api/me/permissions");
            portalPermissions = createPortalPermissions(result.data);
            let customerId = await httpClient.get<string|null>("/api/me/customer");
            internalCustomerId = customerId.data;
        }
        else {
            console.log("get empty permissions")
            portalPermissions = createPortalPermissions();
        }
    }
    catch {
        portalPermissions = createPortalPermissions();
    }
}

export const signOut = () => {
    const logoutRequest = {
        postLogoutRedirectUri: msalConfig.auth.redirectUri,
        mainWindowRedirectUri: msalConfig.auth.redirectUri
    };

    msalInstance.logoutPopup(logoutRequest);
};

export const editProfile = () => {
    const editProfileRequest = { ...config.b2cPolicies.authorities.editProfile, scopes: ["openid"] };
    msalInstance.loginRedirect(editProfileRequest)
        .catch(error => {
            console.log(error);
        });
};

export const getMsalInstance = () => msalInstance;
export const getEnvironment = () => config.app.environment;
export const getConfig = () => config;
export const getPortalPermissions = () => portalPermissions;
export const getCurrentUserInfo = () => {
    let account = msalInstance.getActiveAccount();
    return {
        id: account.localAccountId, name: account.name ?? account.username,
        debugInfo: {
            customerDisplay: `${account.idTokenClaims["customerCompanyName"]}[${account.idTokenClaims["customerId"]}]`,
            name: account.username
        }
    }
}


export function canBeConfirmedByCurrentUser(user: IUser, permissions: IPortalPermissions) {
    if (user.isDeactivated || user.isConfirmed || !permissions.confirmUser) {
        return false;
    }

    let userInfo = getCurrentUserInfo();
    return _.some(user.approvalInfo.canBeConfirmedByUserIds, i => i.id === userInfo.id);
}

export function canBeActivated(user: IUser, permissions: IPortalPermissions) {
    if (!user.isDeactivated) {
        return false;
    }
    return true;
}
