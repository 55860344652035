import { Icon, MessageBar, Toggle } from "@fluentui/react";
import React, { useMemo } from "react";
import _ from "lodash";
import classNames from "classnames";
import styles from "./GroupSelection.module.css";
import { IAllowedGroups, IGroup } from "../shared/models";
import { useTranslation } from "react-i18next";
import { groupDescription } from "../shared/CommonUi";

export const GroupSelection: React.FC<{ availableGroups: IAllowedGroups, selectedGroups: IGroup[], onSelectGroups: (groups: IGroup[]) => void }> = ({ availableGroups, selectedGroups, onSelectGroups }) => {
    let [t] = useTranslation();
    

    selectedGroups = selectedGroups ?? [];

    return (<div>
        <MessageBar>{t("groupSelection.hint")}</MessageBar>
        <GroupSelectItemList groupCategoryKey="admin" groups={availableGroups.administration} selectedGroups={selectedGroups} onSelectGroups={onSelectGroups} />
        <GroupSelectItemList groupCategoryKey="viewer" groups={availableGroups.viewer} selectedGroups={selectedGroups} onSelectGroups={onSelectGroups} />
        <GroupSelectItemList groupCategoryKey="orga" groups={availableGroups.brueckner} selectedGroups={selectedGroups} onSelectGroups={onSelectGroups} />
        <GroupSelectItemList groupCategoryKey="orgaTicket" groups={availableGroups.ticket} selectedGroups={selectedGroups} onSelectGroups={onSelectGroups} />
        <GroupSelectItemList groupCategoryKey="customer" groups={availableGroups.customer} selectedGroups={selectedGroups} onSelectGroups={onSelectGroups} />
        <GroupSelectItemList groupCategoryKey="other" groups={availableGroups.other} selectedGroups={selectedGroups} onSelectGroups={onSelectGroups} />
    </div>);
}

const GroupSelectItemList: React.FC<{ groupCategoryKey: string, groups: IGroup[], selectedGroups: IGroup[], onSelectGroups: (groups: IGroup[]) => void }> = ({ groupCategoryKey, groups, selectedGroups, onSelectGroups }) => {
    let [t] = useTranslation();
    if (!groups || groups.length === 0) {
        return null;
    }
    return (<div className={styles.wrapperStyles}>
        <div className={styles.groupKey}>{t("groupSelection.categories." + groupCategoryKey)}</div>
        <div className={styles.container}>
            {groups.map(g => {
                return (
                    <GroupSelectItem group={g} selected={selectedGroups.some(sg => sg.id === g.id)} onChecked={checked => {
                        if (checked) {
                            onSelectGroups([...selectedGroups, g]);
                        } else {
                            onSelectGroups(selectedGroups.filter(s => s.id !== g.id));
                        }
                    }} />)
            })}
        </div>
    </div>);
}

const GroupSelectItem: React.FC<{ group: IGroup, selected: boolean, onChecked: (checked: boolean) => void }> = ({ group, selected, onChecked }) => {

    let [t] = useTranslation();
    return (<div className={classNames(styles.itemContainer, selected && styles.selected)}>
        <div className={styles.icon}>
            <Icon iconName="Group" />
        </div>
        <div className={styles.nameContainer}>
            <div className={styles.name}>
                {group.displayName}
            </div>
            <div className={styles.description}>
                {groupDescription(t, group)}
            </div>
        </div>
        <div className={styles.toggle}>
            <Toggle disabled={group.notAvailable} checked={selected} onChange={(_, checked) => onChecked(checked)} />
        </div>
    </div>);
}