import _ from "lodash";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Card } from "../components/Card";
import {
    formatFunctionalPlace,
    getTagColorClassName,
    getTagContent
} from "./CommonUi";
import styles from "./FunctionalPlaceInfo.module.css";
import { LabeledContent } from "./LabeledContent";
import { Tag } from "./Tag";
import { IContract, IFunctionalPlaceInfo } from "./models";

export const FunctionalPlaceInfoCard: React.FC<{
  functionalPlaceInfo: IFunctionalPlaceInfo;
}> = ({ functionalPlaceInfo }) => {
  const navigate = useNavigate();
  const [t] = useTranslation();
  return (
    <Card
      className={styles.flexItem}
      margin={"0.5rem"}
      actionProps={{
        iconName: "NavigateExternalInline",
        onClick: () =>
          navigate(`/permissions/${functionalPlaceInfo.functionalPlace.id}`),
      }}
      title={formatFunctionalPlace(functionalPlaceInfo.functionalPlace)}
    >
      <h3>{t("customers.contract.contracts")}</h3>
      {_.map(functionalPlaceInfo.contracts, (contract) => <ContractDisplay contract={contract}/>)}
    </Card>
  );
};

export const ContractDisplay: React.FC<{ contract: IContract }> = ({
  contract,
}) => {
    const [t] = useTranslation();
  return (
    <div className={styles.contractContainer} key={contract.id}>
      <LabeledContent label={t("customers.contract.contract")}>
        {contract.contractName}
      </LabeledContent>
      <LabeledContent label={t("customers.contract.contractPosition")}>
        <Tag colorClassName={getTagColorClassName(contract)}>
          {getTagContent(contract)}
        </Tag>
      </LabeledContent>
    </div>
  );
};

export const FunctionalPlaceInfoList: React.FC<{
  functionalPlaces: IFunctionalPlaceInfo[];
}> = ({ functionalPlaces }) => {
  const [t] = useTranslation();
  return (
    <Card title={t("customers.tableHeaders.functionalPlaces")}>
      <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
        {_.map(functionalPlaces, (info) => (
          <FunctionalPlaceInfoCard
            functionalPlaceInfo={info}
            key={info.functionalPlace.id}
          />
        ))}
      </div>
    </Card>
  );
};
