import { DetailsList, IColumn, Icon, SelectionMode, Stack } from "@fluentui/react";
import { Card } from "../components/Card"
import { IFunctionalPlaceUserInfoVm, IUserByPermissionVm } from "../shared/models"
import styles from './Permissions.module.css'
import { NavLink, useNavigate } from "react-router-dom";
import { GroupDisplay } from "../users/GroupDisplay";
import { formatFunctionalPlace } from "../shared/CommonUi";

export const PermissionsFunctionalPlaceCard: React.FC<IPermissionsFunctionalPlaceCardProps> = ({ fpInfo }) => {

    let navigate = useNavigate();

    const columns: IColumn[] = [
        {
            key: 'column1',
            name: 'Group',
            fieldName: 'group.displayName',
            minWidth: 200,
            maxWidth: 300,
            isResizable: true,
            onRender(item: IUserByPermissionVm) {
                return <GroupDisplay groups={[item.group]} />
            }
        },
        {
            key: 'column2',
            name: 'Users',
            fieldName: 'users',
            minWidth: 300,
            maxWidth: 500,
            isResizable: true,
            onRender(item: IUserByPermissionVm) {
                return <Stack>
                    {item.users.map(user =>
                        <NavLink to={`/users/${user.id}`}>
                            {user.displayName}
                        </NavLink>
                    )}
                </Stack>
            }
        }
    ];

    return <Card
        title={formatFunctionalPlace(fpInfo.functionalPlace)}
        className={styles.permissionsCard}
        actionProps={{ iconName: "Edit", onClick: () => navigate(`/permissions/${fpInfo.functionalPlace.id}`) }}
    >
        <DetailsList
            items={fpInfo.usersByGroup}
            columns={columns}
            selectionMode={SelectionMode.none}
            isHeaderVisible={true}
            ariaLabelForSelectAllCheckbox="Toggle selection for all items"
            ariaLabelForSelectionColumn="Toggle selection"
            checkButtonAriaLabel="Row checkbox"
        />
    </Card>
}

export interface IPermissionsFunctionalPlaceCardProps {
    fpInfo: IFunctionalPlaceUserInfoVm
}