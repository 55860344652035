import { ActionButton, Breadcrumb, IBreadcrumbItem, IColumn, Stack, StackItem, TextField } from "@fluentui/react";
import { TFunction } from "i18next";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import useSWR from "swr";
import { Card } from "../components/Card";
import { ContentWrapper } from "../shared/ContentWrapper";
import { DataList } from "../shared/DataList";
import { FunctionalPlaceInfoList } from "../shared/FunctionalPlaceInfo";
import { getInternalCustomerId } from "../shared/authService";
import commonStyles from '../shared/common.module.css';
import { ICustomer2InfoResult, ICustomerRelationInfo } from "../shared/models";
import { customerService } from "./customerService";
import { cardTokens } from "../shared/CommonUi";

export const Customer: React.FC = () => {
    const [t] = useTranslation();
    let internalCustomerId = getInternalCustomerId();
    let { customerId } = useParams<{ customerId: string }>();
    let { data: vm, mutate } = useSWR(`/api/v2/customers/${customerId}`, internalCustomerId === customerId ? () => customerService.getMyCustomer() : () => customerService.getById(customerId));
    let breadcrumbs = useMemo(() => getBreadcrumbs(t, vm), [t, vm]);


    if (vm && !vm.success) {
        return <div>Error loading customer with id {customerId}</div>
    }

    function getBreadcrumbs(t: TFunction, vm?: ICustomer2InfoResult): IBreadcrumbItem[] {
        return [
            {
                text: t("common.customers"),
                key: "1"
            }, {
                text: vm?.customer ? `${vm.customer.debitorName} (${vm.customer.debitorNo})` : "",
                key: "2"
            }
        ];
    }

    return vm?.customer ?
        <ContentWrapper>
            <Breadcrumb className={commonStyles.breadcrumb} style={{ marginBottom: 20 }} items={breadcrumbs} />

            <Stack tokens={cardTokens}>
            
            <Card title={`${vm.customer.debitorName} (${vm.customer.debitorNo})`}>
                <Stack >
                    <StackItem>
                        <TextField label={t("customers.tableHeaders.debitorName")} readOnly value={vm.customer.debitorName} />
                    </StackItem>
                    <StackItem>
                        <TextField label={t("customers.tableHeaders.debitorNo")} readOnly value={vm.customer.debitorNo} />
                    </StackItem>
                    <StackItem>
                        <TextField label={t("customers.tableHeaders.usersCount")} readOnly value={vm.customer.usersCount.toString()} />
                    </StackItem>
                </Stack>
            </Card>
            <FunctionalPlaceInfoList functionalPlaces={vm.customer.functionalPlaces}/>
            <Card title={t("customers.customerRelations.title")}>
                <CustomerRelationList relations={vm.customer.customerRelations} />
            </Card>
            </Stack>
        </ContentWrapper>


        : <>Loading</>
}

const CustomerRelationList: React.FC<{ relations: ICustomerRelationInfo[] }> = ({ relations }) => {
    const navigate = useNavigate();
    const [t] = useTranslation();
    const columns: IColumn[] = [
        {
            key: 'column1',
            name: 'Debitor No',
            fieldName: 'debitorNo',
            minWidth: 100,
            maxWidth: 200,
            isResizable: true,
        },
        {
            key: 'column2',
            name: 'Debitor Name',
            fieldName: 'debitorName',
            minWidth: 100,
            maxWidth: 200,
            isResizable: true,
        },
        {
            key: 'column3',
            name: t("customers.customerRelations.tableHeaders.relationType"),
            fieldName: 'relationType',
            minWidth: 100,
            maxWidth: 200,
            isResizable: true,
        },
        {
            key: 'action',
            name: t("customers.customerRelations.tableHeaders.open"),
            fieldName: '',
            minWidth: 100,
            maxWidth: 200,
            isResizable: true,
            onRender(item: ICustomerRelationInfo) {
                return <ActionButton
                    onClick={() => {
                        navigate(`/customers/${item.id}`)
                    }}
                    iconProps={{ iconName: "NavigateExternalInline" }}
                />
            }
        },
    ];
    return <DataList items={relations} columns={columns} />;
}