import React, { useMemo } from "react";
import { IUser } from "./models";
import { useTranslation } from "react-i18next";
import { UserInfo } from "./UserInfo";
import { DefaultButton, IStackTokens, MessageBar, Panel, PanelType, PrimaryButton, Stack } from "@fluentui/react";
import { ProgressNotification } from "./ProgressNotification";
import { userService } from "../users/userServices";
import { Formik, FormikHelpers } from "formik";
import { FormTextField } from "../components/FormComponents";

const defaultTokens: IStackTokens = { childrenGap: 20 };

export const EditUserAbbreviationPanel: React.FC<{ isVisible: boolean, user: IUser, onDismiss: () => void, onActionStarting: () => void, onActionCompleted?: () => void }> = ({ isVisible, user, onDismiss, onActionStarting, onActionCompleted }) => {
    let [t] = useTranslation();
    let editVm: IEditVm = useMemo(() => ({ abbreviation: user?.userAbbreviation }), [user]);

    let saveHandler = async (values: IEditVm, props: FormikHelpers<IEditVm>) => {
        let formatData = { name: user.displayName };
        let notification = ProgressNotification.start(t("editUserAbbreviationPanel.notifications.inProgress", formatData));
        onActionStarting();
        let result = await userService.changeAbbreviation(user.id, values.abbreviation);
        if (result.success) {
            notification.success(t("editUserAbbreviationPanel.notifications.success", formatData));
        }
        else {
            notification.error(t("editUserAbbreviationPanel.notifications.error", formatData));
        }
        if (onActionCompleted) {
            onActionCompleted();
        }
    }

    return (<Panel
        type={PanelType.medium}
        isLightDismiss
        isOpen={isVisible}
        onDismiss={onDismiss}
        closeButtonAriaLabel={t("common.close")}
        headerText={t("editUserAbbreviationPanel.headerText")}
    >
        <p>{t("editUserAbbreviationPanel.confirmText")}</p>
        <UserInfo style={{ margin: "1rem 0" }} user={user} />
        <MessageBar>{t("editUserAbbreviationPanel.confirmDescription")}</MessageBar>
        <Formik initialValues={editVm} onSubmit={saveHandler}>
            {(formik) => {
                let { submitForm, values } = formik;

                return (<div>
                    <FormTextField {...formik} name="abbreviation" label={t("editUserAbbreviationPanel.abbreviation")} />
                    <Stack style={{ marginTop: "1rem", marginBottom: "1rem" }} horizontal tokens={defaultTokens}>
                        <PrimaryButton disabled={stringEquals(editVm.abbreviation, values.abbreviation)} onClick={submitForm} >{t("editUserAbbreviationPanel.confirm")}</PrimaryButton>
                        <DefaultButton onClick={onDismiss}>{t("editUserAbbreviationPanel.cancel")}</DefaultButton>
                    </Stack>
                </div>);
            }}
        </Formik>
    </Panel>);
}

interface IEditVm {
    abbreviation: string | null;
}

function stringEquals(t1: string, t2: string) {
    return (t1 ?? "") === (t2 ?? "");
}